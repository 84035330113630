// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.ConnectPracticeResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectPracticeResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	businessName!: string | null;

	@GandalfConstantDecorator(constants.ConnectBusinessType)
	@GandalfProperty({ designType: constants.ConnectBusinessType })
	connectBusinessType!: constants.ConnectBusinessType | null;

	@GandalfConstantDecorator(constants.ConnectCompanyType)
	@GandalfProperty({ designType: constants.ConnectCompanyType })
	connectCompanyType!: constants.ConnectCompanyType | null;

	@GandalfProperty({ designType: String })
	connectFein!: string | null;

	@GandalfProperty({ designType: Number })
	connectPracticeId!: number | null;

	@GandalfProperty({ designType: String })
	connectWebsite!: string | null;

	@GandalfConstantDecorator(constants.StockExchange)
	@GandalfProperty({ designType: constants.StockExchange })
	stockExchange!: constants.StockExchange | null;

	@GandalfProperty({ designType: String })
	stockTicker!: string | null;

}
