// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FeatureFlagEmployeeSummaryResponse } from './feature-flag-employee-summary-response';

/** See com.rev360.pms.api.controller.feature.FeatureFlagDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FeatureFlagDetailResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.FeatureFlagAccess)
	@GandalfProperty({ designType: constants.FeatureFlagAccess })
	access!: constants.FeatureFlagAccess | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	documentationLink!: string | null;

	@GandalfProperty({ designType: FeatureFlagEmployeeSummaryResponse })
	employeeFlag!: FeatureFlagEmployeeSummaryResponse | null;

	@GandalfProperty({ designType: Boolean })
	enabled!: boolean | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	imageFileId!: number | null;

	@GandalfProperty({ designType: String })
	internalName!: string | null;

	@GandalfProperty({ designType: String })
	title!: string | null;

}
