@if (this.isFooterFeatureFlagOn) {
	<footer id="footer" class="navbar-fixed-bottom">
		<ul class="list-inline pull-left">
			<li [attr.data-test-id]="'footerDateTimeSection'">
				<div class="app-date-time">{{ clockDayOfWeek }} <strong>{{ clockMonthDay }}</strong> {{ clockYear }}<span class="divider">|</span><strong
					[attr.data-test-id]="'footerTimeSection'">{{ clockTime | date: clockTimeFormat }}</strong></div>
			</li>
			<li [attr.data-test-id]="'footerRevolutionEhrSection'"><strong>RevolutionEHR</strong><sup>TM</sup></li>
			<li [attr.data-test-id]="'footerApplicationVersionSection'">
				<div class="version">v{{ versionNumber }} - {{ versionDate }}</div>
			</li>
			<li [attr.data-test-id]="'footerApplicationCopyrightSection'">&copy; {{ clockYear }} PracticeTek, Inc.</li>
		</ul>
	</footer>
}
<pms-live-chat></pms-live-chat>
