// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldRequest } from './query-criteria-field-request';

/** See com.rev360.pms.api.controller.query.UpdateQueryTriggerRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateQueryTriggerRequest extends GandalfModelBase {

	@GandalfArray(Number)
	carePlanItemTemplateIds!: number[] | null;

	@GandalfArray(Number)
	carePlanTemplateIds!: number[] | null;

	@GandalfLabel('Category')
	@GandalfProperty({ designType: Number })
	categoryId!: number | null;

	@GandalfValidator({ notNull: { message: 'Search Criteria Field is required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Search Criteria Field is required' } })
	@GandalfArray(QueryCriteriaFieldRequest)
	criteriaFields!: QueryCriteriaFieldRequest[];

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfLabel('Query Trigger')
	@GandalfValidator({ notNull: { message: 'Query Trigger is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfLabel('Bibliographic Citation')
	@GandalfValidator({ sizeString: { message: 'Bibliographic Citation cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	interventionBibliographicCitation!: string | null;

	@GandalfLabel('Developer')
	@GandalfValidator({ sizeString: { message: 'Developer cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	interventionDeveloper!: string | null;

	@GandalfLabel('Developer Funding')
	@GandalfValidator({ sizeString: { message: 'Developer Funding cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	interventionDeveloperFunding!: string | null;

	@GandalfLabel('Release Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interventionReleaseDate!: Date | null;

	@GandalfLabel('Revision Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interventionRevisionDate!: Date | null;

	@GandalfLabel('Name')
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfLabel('Use of Date of Birth')
	@GandalfValidator({ sizeString: { message: 'Use of Date of Birth cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfDateOfBirth!: string | null;

	@GandalfLabel('Use of Ethnicity')
	@GandalfValidator({ sizeString: { message: 'Use of Ethnicity cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfEthnicity!: string | null;

	@GandalfLabel('Use of Gender Identity')
	@GandalfValidator({ sizeString: { message: 'Use of Gender Identity cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfGenderIdentity!: string | null;

	@GandalfLabel('Use of Health Status Assessments')
	@GandalfValidator({ sizeString: { message: 'Use of Health Status Assessments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfHealthStatusAssessments!: string | null;

	@GandalfLabel('Use of Language')
	@GandalfValidator({ sizeString: { message: 'Use of Language cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfLanguage!: string | null;

	@GandalfLabel('Use of Race')
	@GandalfValidator({ sizeString: { message: 'Use of Race cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfRace!: string | null;

	@GandalfLabel('Use of Sex')
	@GandalfValidator({ sizeString: { message: 'Use of Sex cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfSex!: string | null;

	@GandalfLabel('Use of Sexual Orientation')
	@GandalfValidator({ sizeString: { message: 'Use of Sexual Orientation cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfSexualOrientation!: string | null;

	@GandalfLabel('Use of Social Determinants')
	@GandalfValidator({ sizeString: { message: 'Use of Social Determinants cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	useOfSocialDeterminants!: string | null;

}
