// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.IntakeAllergyListResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeAllergyListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	allergyType!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	intakeAllergyId!: number | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	reconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeStatus)
	@GandalfProperty({ designType: constants.IntakeStatus })
	status!: constants.IntakeStatus | null;

}
