// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.UpdateInvoiceItemQuantityRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceItemQuantityRequest extends GandalfModelBase {

	@GandalfLabel('Invoice Item')
	@GandalfValidator({ notNull: { message: 'Invoice Item is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceItemId!: number;

	@GandalfLabel('Quantity')
	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Quantity must be greater than 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	quantity!: number;

}
