// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.PaymentDashboardSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PaymentDashboardSearchRequest extends GandalfModelBase {

	@GandalfLabel('Payment Date End')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfLabel('Payer Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfLabel('Payer Type')
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfLabel('Payment Method')
	@GandalfProperty({ designType: constants.PaymentMethodType })
	paymentMethodType!: constants.PaymentMethodType | null;

	@GandalfConstantDecorator(constants.PaymentSearchStatus)
	@GandalfLabel('Status')
	@GandalfProperty({ designType: constants.PaymentSearchStatus })
	paymentSearchStatus!: constants.PaymentSearchStatus | null;

	@GandalfLabel('Location')
	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfLabel('Reference #')
	@GandalfValidator({ sizeString: { fieldLabel: 'Reference #', maxLength: 1000, minLength: 0, message: 'Reference # must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.PaymentGroupSourceTypeSearch)
	@GandalfLabel('Payment Source')
	@GandalfProperty({ designType: constants.PaymentGroupSourceTypeSearch })
	sourceTypeSearch!: constants.PaymentGroupSourceTypeSearch | null;

	@GandalfLabel('Payment Date Start')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

}
