// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionEyeResponse } from './eyeglass-prescription-eye-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.prescription.EyeglassPrescriptionListResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassPrescriptionListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	arCoating!: boolean | null;

	@GandalfProperty({ designType: String })
	arCoatingComment!: string | null;

	@GandalfProperty({ designType: PersonNameResponse })
	authorizedBy!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	distancePd!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty({ designType: constants.PrescriptionCurrentStatus })
	eyeglassPrescriptionStatus!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	lensType!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	lensTypeComment!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	material!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	materialComment!: string | null;

	@GandalfProperty({ designType: Number })
	nearPd!: number | null;

	@GandalfProperty({ designType: EyeglassPrescriptionEyeResponse })
	od!: EyeglassPrescriptionEyeResponse | null;

	@GandalfProperty({ designType: EyeglassPrescriptionEyeResponse })
	os!: EyeglassPrescriptionEyeResponse | null;

	@GandalfProperty({ designType: Boolean })
	photochromic!: boolean | null;

	@GandalfProperty({ designType: String })
	photochromicComment!: string | null;

	@GandalfProperty({ designType: Boolean })
	phrEnabled!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	polarized!: boolean | null;

	@GandalfProperty({ designType: String })
	polarizedComment!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	prescriptionAuthorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty({ designType: constants.PrescriptionStatus })
	status!: constants.PrescriptionStatus | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	tint!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	tintComment!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	usedFor!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	usedForValue!: string | null;

	@GandalfProperty({ designType: Boolean })
	uvTreatment!: boolean | null;

	@GandalfProperty({ designType: String })
	uvTreatmentComment!: string | null;

}
