// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RecurPatternDailyResponse } from './recur-pattern-daily-response';

// @ts-ignore
import { RecurPatternMonthlyResponse } from './recur-pattern-monthly-response';

// @ts-ignore
import { RecurPatternWeeklyResponse } from './recur-pattern-weekly-response';

// @ts-ignore
import { RecurPatternYearlyResponse } from './recur-pattern-yearly-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.RecurringDefinitionResponse */
/* istanbul ignore next */
@GandalfModel
export class RecurringDefinitionResponse extends GandalfModelBase {

	@GandalfProperty({ designType: RecurPatternDailyResponse })
	dailyRecurPattern!: RecurPatternDailyResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfConstantDecorator(constants.RecurEndType)
	@GandalfProperty({ designType: constants.RecurEndType })
	endType!: constants.RecurEndType | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: RecurPatternMonthlyResponse })
	monthlyRecurPattern!: RecurPatternMonthlyResponse | null;

	@GandalfProperty({ designType: Number })
	numRecurOccurrences!: number | null;

	@GandalfConstantDecorator(constants.RecurPatternType)
	@GandalfProperty({ designType: constants.RecurPatternType })
	recurPatternType!: constants.RecurPatternType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ designType: RecurPatternWeeklyResponse })
	weeklyRecurPattern!: RecurPatternWeeklyResponse | null;

	@GandalfProperty({ designType: RecurPatternYearlyResponse })
	yearlyRecurPattern!: RecurPatternYearlyResponse | null;

}
