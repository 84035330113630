import { Component, Input } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientHealthInformationService } from '@core/patient-health-information/patient-health-information.service';
import { TypedFormGroup } from 'gandalf';
import { EmployeeExternalCredentialsRequest } from '@gandalf/model/employee-external-credentials-request';

@Component({
	selector: 'pms-external-credentials',
	templateUrl: './external-credentials.component.html',
	styles: [],
})
export class ExternalCredentialsComponent {
	@Input()
	formGroup: TypedFormGroup<EmployeeExternalCredentialsRequest>;

	@Input()
	practiceHasRxntEnabled: boolean;

	rxntStatuses = [
		{label: 'Enabled', value: true},
		{label: 'Disabled', value: false},
	];

	constructor(
		private securityManagerService: SecurityManagerService,
		private patientHealthInformationService: PatientHealthInformationService,
	) {
	}

	getRxntCredentialFormGroup() {
		return this.formGroup.controls.rxntCredential;
	}

	showRxntCredential() {
		return this.practiceHasRxntEnabled && this.securityManagerService.userIsProvider();
	}

	canToggleRxnt = (enabling: boolean) => {
		return enabling ? this.patientHealthInformationService.isPhiShareAccepted() : Promise.resolve(true);
	};
}
