// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodOrderResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Date })
	completionDate!: Date | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	currentStatusCode!: constants.OrderStatusCode;

	@GandalfProperty({ designType: Date })
	currentStatusDate!: Date;

	@GandalfProperty({ designType: String })
	externalSupplierName!: string | null;

	@GandalfProperty({ designType: Boolean })
	onHold!: boolean | null;

	@GandalfProperty({ designType: Number })
	orderId!: number;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty({ designType: constants.OrderType })
	orderType!: constants.OrderType;

	@GandalfProperty({ designType: Boolean })
	patientNotified!: boolean | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty({ designType: constants.OrderShipToType })
	shipToType!: constants.OrderShipToType;

	@GandalfProperty({ designType: String })
	vendorName!: string | null;

}
