// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.review.EncounterReviewDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReviewDetailResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	displayOrder!: number | null;

	@GandalfProperty({ designType: Number })
	groupId!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfConstantDecorator(constants.EncounterReviewDetailStatus)
	@GandalfProperty({ designType: constants.EncounterReviewDetailStatus })
	status!: constants.EncounterReviewDetailStatus | null;

}
