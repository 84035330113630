// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryFieldOptionResponse } from './query-field-option-response';

/** See com.rev360.pms.api.controller.query.QueryFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryFieldResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowAll!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	criteria!: boolean | null;

	@GandalfConstantDecorator(constants.QueryFieldDataSource)
	@GandalfProperty({ designType: constants.QueryFieldDataSource })
	dataSource!: constants.QueryFieldDataSource | null;

	@GandalfConstantDecorator(constants.QueryFieldDataSourceList)
	@GandalfProperty({ designType: constants.QueryFieldDataSourceList })
	dataSourceList!: constants.QueryFieldDataSourceList | null;

	@GandalfProperty({ designType: String })
	id!: string | null;

	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfArray(QueryFieldOptionResponse)
	options!: QueryFieldOptionResponse[] | null;

	@GandalfProperty({ designType: Boolean })
	orderable!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	selectable!: boolean | null;

	@GandalfConstantDecorator(constants.QueryFieldType)
	@GandalfProperty({ designType: constants.QueryFieldType })
	type!: constants.QueryFieldType | null;

}
