// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CcdaSectionsPreferenceRequest } from './ccda-sections-preference-request';

// @ts-ignore
import { ScheduleFilterRequest } from './schedule-filter-request';

// @ts-ignore
import { StringAdditionalPreferenceRequest } from './string-additional-preference-request';

/** See com.rev360.pms.api.controller.admin.practicepreferences.AdditionalPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class AdditionalPreferencesRequest extends GandalfModelBase {

	@GandalfArray(CcdaSectionsPreferenceRequest)
	ccdaSectionPreferences!: CcdaSectionsPreferenceRequest[] | null;

	@GandalfProperty({ designType: ScheduleFilterRequest })
	scheduleFilters!: ScheduleFilterRequest | null;

	@GandalfArray(StringAdditionalPreferenceRequest)
	stringAdditionalPreferences!: StringAdditionalPreferenceRequest[] | null;

}
