// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensTrialListResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	commentsOd!: string | null;

	@GandalfProperty({ designType: String })
	commentsOs!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isDispensedOd!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isDispensedOs!: boolean | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	od!: ContactLensEyeResponse | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	os!: ContactLensEyeResponse | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty({ designType: String })
	productOdName!: string | null;

	@GandalfProperty({ designType: String })
	productOsName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	trialDate!: Date | null;

}
