// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.connectsummary.ConnectSummaryConnectMessageListResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectSummaryConnectMessageListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	appointmentId!: number | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	appointmentStatus!: constants.ScheduleItemStatus | null;

	@GandalfConstantDecorator(constants.ConnectMessageCategory)
	@GandalfProperty({ designType: constants.ConnectMessageCategory })
	category!: constants.ConnectMessageCategory | null;

	@GandalfProperty({ designType: Number })
	connectMessageId!: number | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.ConnectMessageStatus)
	@GandalfProperty({ designType: constants.ConnectMessageStatus })
	messageStatus!: constants.ConnectMessageStatus | null;

	@GandalfConstantDecorator(constants.ConnectMessageMethod)
	@GandalfProperty({ designType: constants.ConnectMessageMethod })
	method!: constants.ConnectMessageMethod | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	orderStatus!: constants.OrderStatusCode | null;

	@GandalfProperty({ designType: Number })
	recallAppointmentId!: number | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	recallAppointmentStatus!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ designType: Number })
	recallId!: number | null;

	@GandalfConstantDecorator(constants.RecallStatus)
	@GandalfProperty({ designType: constants.RecallStatus })
	recallStatus!: constants.RecallStatus | null;

	@GandalfProperty({ designType: Date })
	scheduleDate!: Date | null;

	@GandalfConstantDecorator(constants.ConnectMessageSource)
	@GandalfProperty({ designType: constants.ConnectMessageSource })
	source!: constants.ConnectMessageSource | null;

}
