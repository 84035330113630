// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.AdminPracticeInsuranceCompanySummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeInsuranceCompanySummaryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	credit!: number | null;

	@GandalfProperty({ designType: String })
	fax!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: String })
	payerId!: string | null;

	@GandalfProperty({ designType: String })
	phone!: string | null;

	@GandalfConstantDecorator(constants.InsuranceCompanyStatus)
	@GandalfProperty({ designType: constants.InsuranceCompanyStatus })
	status!: constants.InsuranceCompanyStatus | null;

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfProperty({ designType: constants.ClaimSubmitMechanism })
	submitByType!: constants.ClaimSubmitMechanism | null;

}
