// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateOpenEdgeCardReaderRequest } from './create-open-edge-card-reader-request';

// @ts-ignore
import { OpenEdgeSettingsRequest } from './open-edge-settings-request';

/** See com.rev360.pms.api.controller.admin.solutions.openedge.CreateOpenEdgeSettingsRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateOpenEdgeSettingsRequest extends OpenEdgeSettingsRequest {

	@GandalfLabel('MOTO Account AuthKey')
	@GandalfValidator({ alphanumeric: { message: 'MOTO Account AuthKey must be alphanumeric' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account AuthKey is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account AuthKey must be 32 characters in length', minLength: 32, maxLength: 32 } })
	@GandalfProperty({ designType: String, isRequired: true })
	motoAccountAuthKey!: string;

	@GandalfLabel('MOTO Account Terminal ID')
	@GandalfValidator({ digitsOnly: { message: 'MOTO Account Terminal ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account Terminal ID is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account Terminal ID must be 8 characters in length', minLength: 8, maxLength: 8 } })
	@GandalfProperty({ designType: String, isRequired: true })
	motoAccountTerminalId!: string;

	@GandalfLabel('MOTO Account XWeb ID')
	@GandalfValidator({ digitsOnly: { message: 'MOTO Account XWeb ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account XWeb ID is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account XWeb ID must be 12 characters in length', minLength: 12, maxLength: 12 } })
	@GandalfProperty({ designType: String, isRequired: true })
	motoAccountXWebId!: string;

	@GandalfLabel('Card readers')
	@GandalfArray(CreateOpenEdgeCardReaderRequest)
	newCardReaders!: CreateOpenEdgeCardReaderRequest[] | null;

	@GandalfLabel('Pay Online')
	@GandalfValidator({ notNull: { message: 'Pay Online is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	payOnlineEnabled!: boolean;

	@GandalfLabel('Pay Online URL')
	@GandalfValidator({ sizeString: { message: 'Pay Online URL must be between 3 and 30 characters', minLength: 3, maxLength: 30 } })
	@GandalfProperty({ designType: String })
	payOnlineUrlPath!: string | null;

	@GandalfLabel('Practice Location')
	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceLocationId!: number;

	@GandalfLabel('Retail Account AuthKey')
	@GandalfValidator({ alphanumeric: { message: 'Retail Account AuthKey must be alphanumeric' } })
	@GandalfValidator({ notNull: { message: 'Retail Account AuthKey is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account AuthKey must be 32 characters in length', minLength: 32, maxLength: 32 } })
	@GandalfProperty({ designType: String, isRequired: true })
	retailAccountAuthKey!: string;

	@GandalfLabel('Retail Account Terminal ID')
	@GandalfValidator({ digitsOnly: { message: 'Retail Account Terminal ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'Retail Account Terminal ID is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account Terminal ID must be 8 characters in length', minLength: 8, maxLength: 8 } })
	@GandalfProperty({ designType: String, isRequired: true })
	retailAccountTerminalId!: string;

	@GandalfLabel('Retail Account XWeb ID')
	@GandalfValidator({ digitsOnly: { message: 'Retail Account XWeb ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'Retail Account XWeb ID is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account XWeb ID must be 12 characters in length', minLength: 12, maxLength: 12 } })
	@GandalfProperty({ designType: String, isRequired: true })
	retailAccountXWebId!: string;

	@GandalfLabel('Statement Pay Online')
	@GandalfValidator({ notNull: { message: 'Statement Pay Online is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	statementPayOnlineEnabled!: boolean;

}
