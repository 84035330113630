// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.device.MasterDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class MasterDeviceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.MasterDeviceKey)
	@GandalfProperty({ designType: constants.MasterDeviceKey })
	deviceKey!: constants.MasterDeviceKey;

	@GandalfProperty({ designType: String })
	manufacturer!: string;

	@GandalfProperty({ designType: Number })
	masterDeviceId!: number;

	@GandalfProperty({ designType: String })
	model!: string;

}
