// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.StatementInvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class StatementInvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	dueDate!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	invoiceBalance!: number;

	@GandalfProperty({ designType: String })
	message!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	payorBalance!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	printDate!: Date;

}
