// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { StringValueDiffResponse } from './string-value-diff-response';

/** See com.rev360.pms.api.controller.encounter.history.pfsh.SocialDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class SocialDiffResponse extends GandalfModelBase {

	@GandalfProperty({ designType: StringValueDiffResponse })
	alcoholAmount!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: Boolean })
	hasChanges!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasSocialFromIntake!: boolean | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	hobbies!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	isDrinker!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	isSmoker!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	smokingAmount!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	smokingPreference!: StringValueDiffResponse | null;

	@GandalfProperty({ designType: StringValueDiffResponse })
	smokingStatus!: StringValueDiffResponse | null;

}
