// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.smartflow.SmartflowSupplierResponse */
/* istanbul ignore next */
@GandalfModel
export class SmartflowSupplierResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	isOptedIn!: boolean | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: String })
	optInByFirstName!: string | null;

	@GandalfProperty({ designType: String })
	optInByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	optInDate!: Date | null;

	@GandalfProperty({ designType: String })
	phoneNumber!: string | null;

	@GandalfProperty({ designType: Number })
	smartflowMasterSupplierId!: number | null;

	@GandalfProperty({ designType: Number })
	smartflowPracticeSupplierId!: number | null;

	@GandalfConstantDecorator(constants.SmartflowProductPartnerCategory)
	@GandalfProperty({ designType: constants.SmartflowProductPartnerCategory })
	smartflowProductPartnerCategory!: constants.SmartflowProductPartnerCategory | null;

	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
