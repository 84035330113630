// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldResponse } from './query-criteria-field-response';

// @ts-ignore
import { QuerySelectFieldResponse } from './query-select-field-response';

// @ts-ignore
import { QuerySortFieldResponse } from './query-sort-field-response';

/** See com.rev360.pms.api.controller.query.QueryResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allLocked!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	allowAddressLabels!: boolean | null;

	@GandalfProperty({ designType: Number })
	categoryId!: number | null;

	@GandalfArray(QueryCriteriaFieldResponse)
	criteriaFields!: QueryCriteriaFieldResponse[] | null;

	@GandalfProperty({ designType: Boolean })
	criteriaLocked!: boolean | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(Number)
	roleIds!: number[] | null;

	@GandalfArray(QuerySelectFieldResponse)
	selectFields!: QuerySelectFieldResponse[] | null;

	@GandalfArray(QuerySortFieldResponse)
	sortFields!: QuerySortFieldResponse[] | null;

	@GandalfConstantDecorator(constants.QueryTemplateCategory)
	@GandalfProperty({ designType: constants.QueryTemplateCategory })
	templateCategory!: constants.QueryTemplateCategory | null;

	@GandalfConstantDecorator(constants.QueryType)
	@GandalfProperty({ designType: constants.QueryType })
	type!: constants.QueryType | null;

}
