// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.SearchPatientFilesRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchPatientFilesRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PatientFileEntityType)
	@GandalfProperty({ designType: constants.PatientFileEntityType })
	entityType!: constants.PatientFileEntityType | null;

	@GandalfProperty({ designType: Number })
	parentId!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

}
