// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { PreviousAddressRequest } from './previous-address-request';

/** See com.rev360.pms.api.controller.patient.PatientAdditionalInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientAdditionalInformationRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.BloodType)
	@GandalfProperty({ designType: constants.BloodType })
	bloodType!: constants.BloodType | null;

	@GandalfProperty({ designType: Number })
	citizenshipId!: number | null;

	@GandalfProperty({ designType: Number })
	dominantEyeId!: number | null;

	@GandalfProperty({ designType: Number })
	dominantHandId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Driver’s License cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty({ designType: String })
	driversLicense!: string | null;

	@GandalfProperty({ designType: Number })
	maritalStatusId!: number | null;

	@GandalfSubRequestLabel('Previous Address')
	@GandalfLabel('Previous Address')
	@GandalfProperty({ designType: PreviousAddressRequest })
	previousAddress!: PreviousAddressRequest | null;

	@GandalfValidator({ sizeString: { message: 'Previous First Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	previousFirstName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousFirstNameType!: constants.PreviousNameType | null;

	@GandalfValidator({ sizeString: { message: 'Previous Last Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	previousLastName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousLastNameType!: constants.PreviousNameType | null;

	@GandalfValidator({ sizeString: { message: 'Previous Middle Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	previousMiddleName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousMiddleNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	previousNameEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	previousNameStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	religionId!: number | null;

	@GandalfSubRequestLabel('Temporary Address')
	@GandalfLabel('Temporary Address')
	@GandalfProperty({ designType: AddressOptionalRequest })
	temporaryAddress!: AddressOptionalRequest | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	temporaryAddressEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	temporaryAddressStartDate!: Date | null;

}
