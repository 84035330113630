// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.admin.employee.credential.AdminEmployeeExternalCredentialResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeExternalCredentialResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	active!: boolean;

	@GandalfProperty({ designType: Number })
	employeeExternalCredentialId!: number;

	@GandalfProperty({ designType: Number })
	employeeId!: number;

	@GandalfArray(PracticeLocationSummaryResponse)
	locations!: PracticeLocationSummaryResponse[];

	@GandalfProperty({ designType: String })
	password!: string;

	@GandalfProperty({ designType: String })
	userName!: string;

}
