// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.LedgerPostingReportResponse */
/* istanbul ignore next */
@GandalfModel
export class LedgerPostingReportResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number | null;

	@GandalfProperty({ designType: String })
	details!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	invoiceServiceDate!: Date | null;

	@GandalfProperty({ designType: String })
	journalDescription!: string | null;

	@GandalfProperty({ designType: String })
	ledgerName!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	postedOn!: Date | null;

	@GandalfConstantDecorator(constants.LedgerPostingType)
	@GandalfProperty({ designType: constants.LedgerPostingType })
	type!: constants.LedgerPostingType | null;

	@GandalfProperty({ designType: String })
	userName!: string | null;

}
