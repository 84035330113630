// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.incorporate.IncorporatePersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class IncorporatePersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfValidator({ notNull: { message: 'Diagnosis Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	diagnosisDate!: Date;

	@GandalfValidator({ sizeString: { message: 'Long description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	longDescription!: string | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfValidator({ notNull: { message: 'Master Code Set is required' } })
	@GandalfProperty({ designType: constants.CodeSet, isRequired: true })
	masterCodeSet!: constants.CodeSet;

	@GandalfValidator({ sizeString: { message: 'Short description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	shortDescription!: string | null;

}
