// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionLookupResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionLookupResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	addDesignationOd!: string | null;

	@GandalfProperty({ designType: String })
	addDesignationOs!: string | null;

	@GandalfProperty({ designType: Number })
	addPowerOd!: number | null;

	@GandalfProperty({ designType: Number })
	addPowerOs!: number | null;

	@GandalfProperty({ designType: String })
	authorizedBy!: string | null;

	@GandalfProperty({ designType: Number })
	axisOd!: number | null;

	@GandalfProperty({ designType: Number })
	axisOs!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurveOd!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurveOs!: number | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	cylinderOd!: number | null;

	@GandalfProperty({ designType: Number })
	cylinderOs!: number | null;

	@GandalfProperty({ designType: Number })
	diameterOd!: number | null;

	@GandalfProperty({ designType: Number })
	diameterOs!: number | null;

	@GandalfProperty({ designType: Boolean })
	isMonovisionOd!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isMonovisionOs!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isTrial!: boolean | null;

	@GandalfProperty({ designType: String })
	modelOd!: string | null;

	@GandalfProperty({ designType: String })
	modelOs!: string | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfConstantDecorator(constants.RxDisplayStatusLegacy)
	@GandalfProperty({ designType: constants.RxDisplayStatusLegacy })
	rxDisplayStatus!: constants.RxDisplayStatusLegacy | null;

	@GandalfProperty({ designType: Number })
	rxId!: number | null;

	@GandalfProperty({ designType: Number })
	sphereOd!: number | null;

	@GandalfProperty({ designType: Number })
	sphereOs!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

}
