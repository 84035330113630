// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.preferences.UpdateSchedulingPreferenceRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateSchedulingPreferenceRequest extends GandalfModelBase {

	@GandalfLabel('PHR')
	@GandalfValidator({ notNull: { message: 'PHR is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	allowPhrScheduling!: boolean;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Friday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	fridayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	generalNote!: string | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Monday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	mondayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Saturday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	saturdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesScheduleRisk)
	@GandalfLabel('Schedule Risk')
	@GandalfProperty({ designType: constants.SchedulingPreferencesScheduleRisk })
	scheduleRisk!: constants.SchedulingPreferencesScheduleRisk | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Sunday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	sundayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Thursday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	thursdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Tuesday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	tuesdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfLabel('Wednesday Time')
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	wednesdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

}
