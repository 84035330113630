// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PrintStatementsRequest } from './print-statements-request';

/** See com.rev360.pms.api.controller.accounting.SendStatementsRequest */
/* istanbul ignore next */
@GandalfModel
export class SendStatementsRequest extends GandalfModelBase {

	@GandalfLabel('Invoices')
	@GandalfValidator({ notNull: { message: 'Invoices are required' } })
	@GandalfArray(Number)
	invoiceIds!: number[];

	@GandalfLabel('Print Statement')
	@GandalfValidator({ notNull: { message: 'Print Statement is required' } })
	@GandalfProperty({ designType: PrintStatementsRequest, isRequired: true })
	printStatementRequest!: PrintStatementsRequest;

}
