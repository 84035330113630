// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.performedservice.PerformedServiceResponse */
/* istanbul ignore next */
@GandalfModel
export class PerformedServiceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	autoCoded!: boolean | null;

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfArray(String)
	personDiagnosisCodes!: string[] | null;

	@GandalfArray(Number)
	personDiagnosisIds!: number[] | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

	@GandalfConstantDecorator(constants.PerformedServiceTriggerStatus)
	@GandalfProperty({ designType: constants.PerformedServiceTriggerStatus })
	triggerStatus!: constants.PerformedServiceTriggerStatus | null;

	@GandalfProperty({ designType: Boolean })
	triggered!: boolean | null;

}
