// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.EncounterProfessionalTimeCodingResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterProfessionalTimeCodingResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	additionalCptUnits!: number | null;

	@GandalfProperty({ designType: String })
	finalCpt!: string | null;

	@GandalfProperty({ designType: Number })
	totalSeconds!: number | null;

	@GandalfProperty({ designType: Number })
	totalUserSessionTime!: number | null;

}
