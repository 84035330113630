// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.snomed.SnomedCodeResponse */
/* istanbul ignore next */
@GandalfModel
export class SnomedCodeResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	code!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfConstantDecorator(constants.SnomedType)
	@GandalfProperty({ designType: constants.SnomedType })
	type!: constants.SnomedType | null;

}
