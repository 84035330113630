// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AttributeDropdownResponse } from './attribute-dropdown-response';

// @ts-ignore
import { WorkflowStepTemplateResponse } from './workflow-step-template-response';

/** See com.rev360.pms.api.controller.admin.encounter.AdminEncounterTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEncounterTemplateResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	abbreviation!: string | null;

	@GandalfProperty({ designType: Boolean })
	allowPhrScheduling!: boolean | null;

	@GandalfProperty({ designType: Number })
	displayColor!: number | null;

	@GandalfProperty({ designType: Number })
	encounterTemplateId!: number | null;

	@GandalfProperty({ designType: Boolean })
	inClinic!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	includeInMuCalcs!: boolean | null;

	@GandalfProperty({ designType: Number })
	interviewTemplateId!: number | null;

	@GandalfProperty({ designType: Boolean })
	isComprehensive!: boolean | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: String })
	phrName!: string | null;

	@GandalfArray(AttributeDropdownResponse)
	subTypes!: AttributeDropdownResponse[] | null;

	@GandalfProperty({ designType: Number })
	totalDuration!: number | null;

	@GandalfArray(WorkflowStepTemplateResponse)
	workflowStepTemplates!: WorkflowStepTemplateResponse[] | null;

}
