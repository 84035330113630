// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.PatientDocumentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDocumentResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	bottomMargin!: number | null;

	@GandalfProperty({ designType: Number })
	categoryId!: number;

	@GandalfProperty({ designType: String })
	documentContent!: string | null;

	@GandalfProperty({ designType: String })
	documentName!: string;

	@GandalfProperty({ designType: Number })
	leftMargin!: number | null;

	@GandalfProperty({ designType: Number })
	patientDocumentId!: number;

	@GandalfProperty({ designType: Number })
	patientId!: number;

	@GandalfProperty({ designType: Number })
	rightMargin!: number | null;

	@GandalfProperty({ designType: Number })
	topMargin!: number | null;

}
