// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryFieldResponse } from './query-field-response';

/** See com.rev360.pms.api.controller.query.QueryFieldCategoryResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryFieldCategoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	fieldLabelPrefix!: string | null;

	@GandalfArray(QueryFieldResponse)
	fields!: QueryFieldResponse[] | null;

	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfArray(QueryFieldCategoryResponse)
	subCategories!: QueryFieldCategoryResponse[] | null;

}
