// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.immunization.PatientHl7ValueSetSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientHl7ValueSetSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.Hl7ValueSetOid)
	@GandalfLabel('HL7 Value Set OID')
	@GandalfValidator({ notNull: { message: 'HL7 Value Set OID is required' } })
	@GandalfProperty({ designType: constants.Hl7ValueSetOid, isRequired: true })
	valueSetOid!: constants.Hl7ValueSetOid;

}
