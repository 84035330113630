// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.RunQueryRequest */
/* istanbul ignore next */
@GandalfModel
export class RunQueryRequest extends GandalfModelBase {

	@GandalfLabel('Limit')
	@GandalfValidator({ notNull: { message: 'Limit is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Limit must be greater than 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	limit!: number;

	@GandalfLabel('Offset')
	@GandalfValidator({ notNull: { message: 'Offset is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Offset must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	offset!: number;

	@GandalfLabel('Query ID')
	@GandalfValidator({ notNull: { message: 'Query ID is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	queryId!: number;

	@GandalfLabel('Total')
	@GandalfProperty({ designType: Number })
	total!: number | null;

}
