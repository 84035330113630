/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { OptionItem } from '../../constants/items';
import { _isNil } from '../../utils/lodash/lodash';
import { ObjectUtils } from '../../utils/object-utils/object-utils';

@Component({
	selector: 'rev-status-select-button',
	templateUrl: './status-select-button.component.html',
	styles: [],
})
export class StatusSelectButtonComponent implements ControlValueAccessor {

	@Input()
	options: OptionItem[];

	@Input()
	disabled: boolean;

	@Output()
	onChange: EventEmitter<any> = new EventEmitter();

	@Output()
	onOptionClick: EventEmitter<any> = new EventEmitter();

	focusedItem: HTMLDivElement;
	value: any;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@Optional() @Self() public ngControl: NgControl,
	) {
		this.ngControl.valueAccessor = this;
	}

	/**
	 * An optional function to evaluate if the change can be performed.
	 * @param _targetValue The target value being selected
	 * @returns A promise that resolves to a boolean indicating if the change can be performed.
	 */
	@Input()
	canChange: (targetValue) => Promise<boolean> = (_targetValue) => Promise.resolve(true);

	onModelChange: (_: any) => void = (_: any) => {};

	onModelTouched: () => void = () => {};

	writeValue(value: any): void {
		this.value = value;
		this.changeDetectorRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	async onItemClick(event, option: OptionItem, index: number) {
		if (this.disabled || option.disabled || !(await this.canChange(option.value))) {
			return;
		}

		this.onOptionClick.emit({
			originalEvent: event,
			option,
			index,
		});

		if (this.value !== option.value) {
			this.value = option.value;

			this.onModelChange(this.value);
			this.onChange.emit({
				originalEvent: event,
				value: this.value,
			});
		}
	}

	onFocus(event: Event) {
		this.focusedItem = event.target as HTMLDivElement;
	}

	onBlur(_event) {
		this.focusedItem = null;
		this.onModelTouched();
	}

	isSelected(option: OptionItem) {
		return option.value === this.value;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}

	getStyleClass(option: OptionItem) {
		return _isNil(option.styleClass) ? '' : option.styleClass;
	}

	buildNgClass(option: OptionItem, btn) {
		return {
			[this.getStyleClass(option)]: this.isSelected(option),
			'e-active': this.isSelected(option),
			'e-disabled': this.disabled || option.disabled,
			'e-focus': btn === this.focusedItem,
		};
	}

	buildDataTestIdForOption(option: OptionItem) {
		if (ObjectUtils.isNilOrEmpty(this.ngControl.name)) {
			return option.value;
		}
		return `${this.ngControl.name}-${option.value}`;
	}

}
