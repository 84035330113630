// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.FrameSizeResponse */
/* istanbul ignore next */
@GandalfModel
export class FrameSizeResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	a!: number | null;

	@GandalfProperty({ designType: Number })
	b!: number | null;

	@GandalfProperty({ designType: Number })
	bridge!: number | null;

	@GandalfProperty({ designType: Number })
	circumference!: number | null;

	@GandalfProperty({ designType: Number })
	dbl!: number | null;

	@GandalfProperty({ designType: Number })
	ed!: number | null;

	@GandalfProperty({ designType: Number })
	edAngle!: number | null;

	@GandalfProperty({ designType: Number })
	eye!: number | null;

	@GandalfProperty({ designType: Number })
	frameSizeId!: number | null;

	@GandalfProperty({ designType: Number })
	screwToScrew!: number | null;

	@GandalfProperty({ designType: Number })
	temple!: number | null;

	@GandalfProperty({ designType: String })
	templeUnit!: string | null;

}
