// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.SplitPaymentRequest */
/* istanbul ignore next */
@GandalfModel
export class SplitPaymentRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfLabel('Insurance Amount')
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: true, negativeAllowed: false, message: 'Insurance Amount cannot be negative' } })
	@GandalfValidator({ notNull: { message: 'Insurance Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', designType: Number, isRequired: true })
	insurancePortion!: number;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceId!: number;

	@GandalfLabel('Invoice Item')
	@GandalfValidator({ notNull: { message: 'Invoice Item is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceItemId!: number;

	@GandalfLabel('Order')
	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfLabel('Patient')
	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfLabel('Patient Amount')
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: false, negativeAllowed: false, message: 'Patient Amount must be greater than 0' } })
	@GandalfValidator({ notNull: { message: 'Patient Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', designType: Number, isRequired: true })
	patientPortion!: number;

	@GandalfLabel('Transfer to Invoice')
	@GandalfProperty({ designType: Number })
	transferToExistingInvoiceId!: number | null;

}
