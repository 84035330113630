// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PharmacyResponse } from './pharmacy-response';

/** See com.rev360.pms.api.controller.patient.pharmacy.PatientPharmacyResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPharmacyResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	personPharmacyId!: number | null;

	@GandalfProperty({ designType: PharmacyResponse })
	pharmacy!: PharmacyResponse | null;

	@GandalfConstantDecorator(constants.PersonPharmacyStatus)
	@GandalfProperty({ designType: constants.PersonPharmacyStatus })
	status!: constants.PersonPharmacyStatus | null;

}
