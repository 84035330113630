// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleRangeResponse */
/* istanbul ignore next */
@GandalfModel
export class FeeScheduleRangeResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	beginDate!: Date | null;

	@GandalfConstantDecorator(constants.FeeScheduleRangeComputedStatus)
	@GandalfProperty({ designType: constants.FeeScheduleRangeComputedStatus })
	computedStatus!: constants.FeeScheduleRangeComputedStatus | null;

	@GandalfProperty({ designType: Number })
	feeScheduleId!: number | null;

	@GandalfProperty({ designType: String })
	feeScheduleName!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfProperty({ designType: String })
	practiceLocationName!: string | null;

	@GandalfConstantDecorator(constants.FeeScheduleRangeStatus)
	@GandalfProperty({ designType: constants.FeeScheduleRangeStatus })
	status!: constants.FeeScheduleRangeStatus | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	writeoffReason!: ReferenceDataSummaryResponse | null;

}
