// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeLensRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeLensRequest extends GandalfModelBase {

	@GandalfLabel('Fee Lens')
	@GandalfProperty({ designType: Number })
	eyefinityFeeLensId!: number | null;

	@GandalfLabel('Base Lens Code')
	@GandalfValidator({ notNull: { message: 'Base Lens Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Base Lens Code must be between 1 and 5 characters', minLength: 1, maxLength: 5 } })
	@GandalfProperty({ designType: String, isRequired: true })
	hcpcsCode!: string;

	@GandalfLabel('Base Lens Name')
	@GandalfValidator({ notNull: { message: 'Base Lens Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Base Lens Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfLabel('Base Lens Retail Cost')
	@GandalfValidator({ notNull: { message: 'Base Lens Retail Cost is required' } })
	@GandalfProperty({ propertyType: 'Money', designType: Number, isRequired: true })
	retailCost!: number;

	@GandalfLabel('Base Lens Service Fee')
	@GandalfValidator({ notNull: { message: 'Base Lens Service Fee is required' } })
	@GandalfProperty({ propertyType: 'Money', designType: Number, isRequired: true })
	serviceFee!: number;

}
