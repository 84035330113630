// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

// @ts-ignore
import { FieldValueSelectionResponse } from './field-value-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.presentillness.PresentIllnessResponse */
/* istanbul ignore next */
@GandalfModel
export class PresentIllnessResponse extends GandalfModelBase {

	@GandalfProperty({ designType: FieldValueSelectionResponse })
	contextSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty({ designType: String })
	contextText!: string | null;

	@GandalfProperty({ designType: String })
	diagnosisText!: string | null;

	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfProperty({ designType: String })
	durationText!: string | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	factorsSelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: String })
	factorsText!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: FieldValueSelectionResponse })
	locationSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty({ designType: String })
	locationText!: string | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	qualitySelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: String })
	qualityText!: string | null;

	@GandalfProperty({ designType: FieldValueSelectionResponse })
	severitySelection!: FieldValueSelectionResponse | null;

	@GandalfProperty({ designType: String })
	severityText!: string | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	signsSelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: String })
	signsText!: string | null;

	@GandalfProperty({ designType: FieldValueSelectionResponse })
	timingSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty({ designType: String })
	timingText!: string | null;

}
