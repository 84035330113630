// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.UpdateOrderStatusRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateOrderStatusRequest extends GandalfModelBase {

	@GandalfLabel('Order')
	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	orderId!: number;

	@GandalfLabel('Reason')
	@GandalfValidator({ notNull: { message: 'Reason is required' } })
	@GandalfValidator({ sizeString: { message: 'Reason must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	reason!: string;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfLabel('Status')
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ designType: constants.OrderStatusCode, isRequired: true })
	status!: constants.OrderStatusCode;

}
