// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCategoryRequest } from './create-category-request';

/** See com.rev360.pms.api.controller.category.UpdateCategoryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCategoryRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	active!: boolean;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ notNull: { message: 'Category ID must not be null' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfProperty({ designType: Number })
	sortOrder!: number | null;

	@GandalfArray(CreateCategoryRequest)
	subCategoriesCreate!: CreateCategoryRequest[] | null;

	@GandalfArray(UpdateCategoryRequest)
	subCategoriesUpdate!: UpdateCategoryRequest[] | null;

}
