// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QueryCriteriaFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryCriteriaFieldResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	id!: string | null;

	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfProperty({ designType: String })
	operand1!: string | null;

	@GandalfProperty({ designType: String })
	operand1Label!: string | null;

	@GandalfProperty({ designType: String })
	operand2!: string | null;

	@GandalfConstantDecorator(constants.QueryCriteriaOperator)
	@GandalfProperty({ designType: constants.QueryCriteriaOperator })
	operator!: constants.QueryCriteriaOperator | null;

	@GandalfConstantDecorator(constants.QueryCriteriaOuterOperator)
	@GandalfProperty({ designType: constants.QueryCriteriaOuterOperator })
	outerOperator!: constants.QueryCriteriaOuterOperator | null;

	@GandalfConstantDecorator(constants.QueryFieldType)
	@GandalfProperty({ designType: constants.QueryFieldType })
	type!: constants.QueryFieldType | null;

}
