// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.FindCollectionsPaymentInvoicesRequest */
/* istanbul ignore next */
@GandalfModel
export class FindCollectionsPaymentInvoicesRequest extends GandalfModelBase {

	@GandalfLabel('Payment Location')
	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfLabel('Collections Agency')
	@GandalfValidator({ notNull: { message: 'Collections Agency is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	vendorId!: number;

}
