// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.BillableItemProductResponse */
/* istanbul ignore next */
@GandalfModel
export class BillableItemProductResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	billableItemId!: number | null;

	@GandalfConstantDecorator(constants.BillableItemStatus)
	@GandalfProperty({ designType: constants.BillableItemStatus })
	billableItemStatus!: constants.BillableItemStatus | null;

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfConstantDecorator(constants.BillingInvoiceStatus)
	@GandalfProperty({ designType: constants.BillingInvoiceStatus })
	invoiceStatus!: constants.BillingInvoiceStatus | null;

	@GandalfProperty({ designType: Boolean })
	nonBillable!: boolean | null;

	@GandalfProperty({ designType: String })
	nonBillableReason!: string | null;

	@GandalfProperty({ designType: String })
	payerAssigned!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	retailPrice!: number | null;

}
