// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEmployeeExternalSystemsResponse } from './admin-employee-external-systems-response';

// @ts-ignore
import { AdminEmployeeLocationAccessResponse } from './admin-employee-location-access-response';

// @ts-ignore
import { AdminEmployeeLoginDetailsResponse } from './admin-employee-login-details-response';

// @ts-ignore
import { AdminEmployeeLoginRestrictionResponse } from './admin-employee-login-restriction-response';

// @ts-ignore
import { AdminEmployeeProviderDetailsResponse } from './admin-employee-provider-details-response';

// @ts-ignore
import { AdminEmployeeRolesResponse } from './admin-employee-roles-response';

// @ts-ignore
import { EmployeePreferencesResponse } from './employee-preferences-response';

// @ts-ignore
import { PersonDetailsResponse } from './person-details-response';

/** See com.rev360.pms.api.controller.admin.employee.detailed.response.AdminEmployeeDetailedResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeDetailedResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AdminEmployeeExternalSystemsResponse })
	employeeExternalSystems!: AdminEmployeeExternalSystemsResponse | null;

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: AdminEmployeeLocationAccessResponse })
	employeeLocationAccess!: AdminEmployeeLocationAccessResponse | null;

	@GandalfProperty({ designType: AdminEmployeeLoginDetailsResponse })
	employeeLoginDetails!: AdminEmployeeLoginDetailsResponse | null;

	@GandalfProperty({ designType: AdminEmployeeLoginRestrictionResponse })
	employeeLoginRestriction!: AdminEmployeeLoginRestrictionResponse | null;

	@GandalfProperty({ designType: EmployeePreferencesResponse })
	employeePreferences!: EmployeePreferencesResponse | null;

	@GandalfProperty({ designType: AdminEmployeeProviderDetailsResponse })
	employeeProviderDetails!: AdminEmployeeProviderDetailsResponse | null;

	@GandalfProperty({ designType: AdminEmployeeRolesResponse })
	employeeRoles!: AdminEmployeeRolesResponse | null;

	@GandalfConstantDecorator(constants.EmployeeStatus)
	@GandalfProperty({ designType: constants.EmployeeStatus })
	employeeStatus!: constants.EmployeeStatus | null;

	@GandalfProperty({ designType: PersonDetailsResponse })
	personDetails!: PersonDetailsResponse | null;

}
