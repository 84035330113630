// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AllergyReviewHistoryRequest } from './allergy-review-history-request';

/** See com.rev360.pms.api.controller.encounter.history.allergy.CreateAllergyReviewHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateAllergyReviewHistoryRequest extends AllergyReviewHistoryRequest {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Medication Allergy Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	drugAllergyComment!: string | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfValidator({ notNull: { message: 'No Known Drug Allergies is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	noKnownDrugAllergies!: boolean;

	@GandalfValidator({ notNull: { message: 'No Latex Sensitivity is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	noLatexSensitivity!: boolean;

	@GandalfValidator({ notNull: { message: 'No Other Known Allergies is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	noOtherKnownAllergies!: boolean;

	@GandalfValidator({ sizeString: { message: 'Other Allergy Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	otherAllergyComment!: string | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfProperty({ designType: Date })
	reviewDate!: Date | null;

}
