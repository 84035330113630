// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.interview.InterviewResponse */
/* istanbul ignore next */
@GandalfModel
export class InterviewResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	appointmentEncounterTemplateName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	appointmentStartDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: String })
	encounterType!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interviewDate!: Date | null;

	@GandalfProperty({ designType: Number })
	interviewId!: number | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

}
