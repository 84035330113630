// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientNameResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientNameResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty({ designType: constants.PatientStatus })
	activeStatus!: constants.PatientStatus | null;

	@GandalfProperty({ designType: String })
	credentials!: string | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: String })
	middleName!: string | null;

	@GandalfProperty({ designType: String })
	nickname!: string | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: String })
	pronunciation!: string | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	status!: constants.EstablishedStatus | null;

	@GandalfProperty({ designType: String })
	suffix!: string | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	title!: ReferenceDataResponse | null;

}
