// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PagingAndSortingRequest } from './paging-and-sorting-request';

/** See com.rev360.pms.api.controller.connect.campaign.FindPagedConnectCampaignMessagesRequest */
/* istanbul ignore next */
@GandalfModel
export class FindPagedConnectCampaignMessagesRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Campaign is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	campaignId!: number;

	@GandalfProperty({ designType: PagingAndSortingRequest })
	pagingAndSortingRequest!: PagingAndSortingRequest | null;

	@GandalfConstantDecorator(constants.ConnectCampaignMessageStatus) 
	@GandalfValidator({ notNull: { message: 'Statuses are required' } })
	@GandalfArray(constants.ConnectCampaignMessageStatus)
	statuses!: constants.ConnectCampaignMessageStatus[];

}
