import { Type } from '@angular/core';
import { registerMetadataProperty, setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key indicating that a property is required
 */
export const GANDALF_IS_REQUIRED_METADATA_KEY = Symbol('GandalfIsRequired');

/**
 * Metadata key indicating a property has a special type, ie LocalDate or Money
 */
export const GANDALF_TYPE_METADATA_KEY = Symbol('GandalfType');

/**
 * Metadata key indicating the design type of a property
 */
export const GANDALF_DESIGN_TYPE_METADATA_KEY = Symbol('GandalfDesignType');

/**
 * Metadata key used to enumerate all Gandalf properties
 */
export const GANDALF_PROPERTY_METADATA_KEY = Symbol('GandalfProperty');

/**
 * Decorator which identifies a Gandalf Model's property, and additional features of the property
 *
 * The property name will be stored in Gandalf metadata so it can be enumerated by other Gandalf utils.
 *
 * Property type metadata can be accessed via:
 * modelInstance.$isRequired.propertyName
 *
 * @param options.propertyType a Gandalf-specific identifier indicating special handling for this type, for example 'LocalDate' or 'Money'.
 * @param options.isRequired optionally define whether this property is required, defaults to {false}
 */
export function GandalfProperty(options: {propertyType?: string, designType?: Type<any>, isRequired?: boolean} = {}) {
	return (targetObject: object, propertyName: string) => {
		// Register the property name so it can be enumerated
		registerMetadataProperty(targetObject, GANDALF_PROPERTY_METADATA_KEY, propertyName);

		if (options.propertyType) {
			// Store the Gandalf-specific type in metadata
			setMetadataPropertyValue(targetObject, GANDALF_TYPE_METADATA_KEY, propertyName, {name: options.propertyType});
		}

		if (options.designType) {
			// Store the design type in metadata
			setMetadataPropertyValue(targetObject, GANDALF_DESIGN_TYPE_METADATA_KEY, propertyName, options.designType);
		}

		if (options.isRequired) {
			// Store the requirement in metadata
			setMetadataPropertyValue(targetObject, GANDALF_IS_REQUIRED_METADATA_KEY, propertyName, true);
		}
	};
}
