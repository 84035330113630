// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FormFieldResponse } from './form-field-response';

/** See com.rev360.pms.api.controller.admin.encounter.examtestlibrary.AdminWorkflowTestTemplateSvcTriggerDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowTestTemplateSvcTriggerDetailResponse extends GandalfModelBase {

	@GandalfArray(Number)
	formFieldIds!: number[] | null;

	@GandalfArray(FormFieldResponse)
	formFieldResponses!: FormFieldResponse[] | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfProperty({ designType: String })
	serviceCode!: string | null;

	@GandalfProperty({ designType: String })
	serviceDescription!: string | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

	@GandalfConstantDecorator(constants.TriggerRuleType)
	@GandalfProperty({ designType: constants.TriggerRuleType })
	triggerRuleType!: constants.TriggerRuleType | null;

}
