// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryResponse } from './query-response';

// @ts-ignore
import { QueryTriggerCarePlanItemTemplateResponse } from './query-trigger-care-plan-item-template-response';

// @ts-ignore
import { QueryTriggerCarePlanTemplateResponse } from './query-trigger-care-plan-template-response';

/** See com.rev360.pms.api.controller.query.QueryTriggerResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryTriggerResponse extends GandalfModelBase {

	@GandalfArray(QueryTriggerCarePlanItemTemplateResponse)
	carePlanItemTemplates!: QueryTriggerCarePlanItemTemplateResponse[] | null;

	@GandalfArray(QueryTriggerCarePlanTemplateResponse)
	carePlanTemplates!: QueryTriggerCarePlanTemplateResponse[] | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	interventionBibliographicCitation!: string | null;

	@GandalfProperty({ designType: String })
	interventionDeveloper!: string | null;

	@GandalfProperty({ designType: String })
	interventionDeveloperFunding!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interventionReleaseDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interventionRevisionDate!: Date | null;

	@GandalfProperty({ designType: QueryResponse })
	query!: QueryResponse | null;

	@GandalfProperty({ designType: String })
	useOfDateOfBirth!: string | null;

	@GandalfProperty({ designType: String })
	useOfEthnicity!: string | null;

	@GandalfProperty({ designType: String })
	useOfGenderIdentity!: string | null;

	@GandalfProperty({ designType: String })
	useOfHealthStatusAssessments!: string | null;

	@GandalfProperty({ designType: String })
	useOfLanguage!: string | null;

	@GandalfProperty({ designType: String })
	useOfRace!: string | null;

	@GandalfProperty({ designType: String })
	useOfSex!: string | null;

	@GandalfProperty({ designType: String })
	useOfSexualOrientation!: string | null;

	@GandalfProperty({ designType: String })
	useOfSocialDeterminants!: string | null;

}
