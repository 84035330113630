// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientMinerSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientMinerSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.GenderSearch)
	@GandalfProperty({ designType: constants.GenderSearch })
	gender!: constants.GenderSearch | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lastExamEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lastExamStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfValidator({ notNull: { message: 'Maximum Age is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Maximum age must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	maximumAge!: number;

	@GandalfValidator({ notNull: { message: 'Minimum Age is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Minimum age must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	minimumAge!: number;

}
