// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.PracticeInsuranceCompanyResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceCompanyResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	abbr!: string | null;

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: Boolean })
	allowItemDiscounts!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	chargedTax!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimFilingCode)
	@GandalfProperty({ designType: constants.ClaimFilingCode })
	claimFilingCode!: constants.ClaimFilingCode | null;

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfProperty({ designType: constants.ClaimSubmitMechanism })
	claimSubmissionMechanism!: constants.ClaimSubmitMechanism | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	credit!: number | null;

	@GandalfProperty({ designType: Number })
	defaultInsuranceTypeReferenceId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	defaultPaymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty({ designType: Number })
	defaultPriorityReferenceId!: number | null;

	@GandalfProperty({ designType: String })
	fax!: string | null;

	@GandalfProperty({ designType: String })
	groupNumber!: string | null;

	@GandalfConstantDecorator(constants.GroupNumberQualifier)
	@GandalfProperty({ designType: constants.GroupNumberQualifier })
	groupNumberQualifier!: constants.GroupNumberQualifier | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	includePqrsAlert!: boolean | null;

	@GandalfProperty({ designType: Number })
	insuranceClassMasterReferenceId!: number | null;

	@GandalfProperty({ designType: String })
	mainPhone!: string | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: String })
	notes!: string | null;

	@GandalfProperty({ designType: Boolean })
	npiOnly!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	participatingPractice!: boolean | null;

	@GandalfProperty({ designType: String })
	payerId!: string | null;

	@GandalfProperty({ designType: Boolean })
	removeMatchingServiceNpi!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	renderingIsReferringProvider!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	transferTax!: boolean | null;

	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
