// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderTwelve84FrameResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderTwelve84FrameResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	displayName!: string | null;

	@GandalfProperty({ designType: Number })
	frameColorId!: number | null;

	@GandalfProperty({ designType: Number })
	frameSizeId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

}
