// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleItemServiceCptResponse */
/* istanbul ignore next */
@GandalfModel
export class FeeScheduleItemServiceCptResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	maximumAllowable!: number | null;

	@GandalfProperty({ designType: String })
	modifier!: string | null;

	@GandalfProperty({ designType: String })
	name!: string;

	@GandalfProperty({ designType: Number })
	serviceId!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	usualAndCustomaryFee!: number;

}
