// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { TwoWayTextMessageResponse } from './two-way-text-message-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextConversationResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextConversationResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	cellPhone!: string;

	@GandalfConstantDecorator(constants.ConnectConversationClassification)
	@GandalfProperty({ designType: constants.ConnectConversationClassification })
	classification!: constants.ConnectConversationClassification;

	@GandalfProperty({ designType: Number })
	connectConversationId!: number;

	@GandalfProperty({ designType: Boolean })
	hasPatientMatches!: boolean;

	@GandalfProperty({ designType: Boolean })
	isPatientCellPhoneChanged!: boolean;

	@GandalfArray(TwoWayTextMessageResponse)
	messages!: TwoWayTextMessageResponse[];

	@GandalfConstantDecorator(constants.PatientCommunicationEligibility)
	@GandalfProperty({ designType: constants.PatientCommunicationEligibility })
	patientEligibility!: constants.PatientCommunicationEligibility | null;

	@GandalfProperty({ designType: String })
	patientEligibilityMessage!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	patientPhotoId!: number | null;

	@GandalfConstantDecorator(constants.ConnectConversationReadStatus)
	@GandalfProperty({ designType: constants.ConnectConversationReadStatus })
	readStatus!: constants.ConnectConversationReadStatus;

}
