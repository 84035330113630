// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.preferences.ScheduleFilterResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleFilterResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ScheduleItemType) 
	@GandalfArray(constants.ScheduleItemType)
	itemTypes!: constants.ScheduleItemType[] | null;

	@GandalfArray(Number)
	personIds!: number[] | null;

	@GandalfArray(Number)
	roleIds!: number[] | null;

	@GandalfArray(Number)
	sortPersonIds!: number[] | null;

	@GandalfConstantDecorator(constants.CustomFilterScheduleItemStatus) 
	@GandalfArray(constants.CustomFilterScheduleItemStatus)
	statuses!: constants.CustomFilterScheduleItemStatus[] | null;

}
