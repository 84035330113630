// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextFindNewConversationPatientsRequest */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextFindNewConversationPatientsRequest extends GandalfModelBase {

	@GandalfLabel('Cell Phone')
	@GandalfValidator({ phoneNumber: { message: 'Cell Phone must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	cellPhone!: string | null;

	@GandalfLabel('DOB')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateOfBirth!: Date | null;

	@GandalfLabel('First Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'First Name', maxLength: 1000, minLength: 2, message: 'First Name must be between 2 and 1000 characters' } })
	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfLabel('Last Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Last Name', maxLength: 1000, minLength: 2, message: 'Last Name must be between 2 and 1000 characters' } })
	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfLabel('Location')
	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfLabel('ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'ID may not be more than 18 digits' } })
	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfLabel('SSN')
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty({ designType: String })
	socialSecurityNumber!: string | null;

}
