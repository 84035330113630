// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BasicCostReportRequest } from './basic-cost-report-request';

/** See com.rev360.pms.api.controller.reporting.inventory.cost.AdvancedCostReportRequest */
/* istanbul ignore next */
@GandalfModel
export class AdvancedCostReportRequest extends BasicCostReportRequest {

	@GandalfValidator({ sizeString: { fieldLabel: 'Brand', maxLength: 1000, minLength: 0, message: 'Brand must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	brand!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Collection', maxLength: 1000, minLength: 0, message: 'Collection must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	collection!: string | null;

	@GandalfValidator({ notNull: { message: 'Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	createdOn!: Date;

	@GandalfValidator({ sizeString: { fieldLabel: 'Description', maxLength: 1000, minLength: 0, message: 'Description must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Filter content', maxLength: 1000, minLength: 0, message: 'Filter content must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	generalFilter!: string | null;

	@GandalfConstantDecorator(constants.InventoryItemStatus)
	@GandalfProperty({ designType: constants.InventoryItemStatus })
	inventoryItemStatus!: constants.InventoryItemStatus | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductCategoryId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Manufacturer', maxLength: 1000, minLength: 0, message: 'Manufacturer must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	manufacturer!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Model', maxLength: 1000, minLength: 0, message: 'Model must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	model!: string | null;

	@GandalfProperty({ designType: Number })
	quantityInStockMax!: number | null;

	@GandalfProperty({ designType: Number })
	quantityInStockMin!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	retailPriceMax!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	retailPriceMin!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'SKU', maxLength: 1000, minLength: 0, message: 'SKU must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	sku!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'UPC', maxLength: 1000, minLength: 0, message: 'UPC must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	upc!: string | null;

}
