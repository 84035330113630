// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.CreateConnectCampaignScheduleRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateConnectCampaignScheduleRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Subject is required' } })
	@GandalfValidator({ sizeString: { message: 'Subject must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	emailSubject!: string;

	@GandalfValidator({ notNull: { message: 'Message is required' } })
	@GandalfValidator({ sizeString: { message: 'Message must be between 1 and 65535 characters', minLength: 1, maxLength: 65535 } })
	@GandalfProperty({ designType: String, isRequired: true })
	emailTemplate!: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceLocationId!: number;

	@GandalfValidator({ notNull: { message: 'Recipients is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	queryId!: number;

	@GandalfProperty({ designType: Date })
	scheduledDate!: Date | null;

	@GandalfValidator({ notNull: { message: 'Use Patient Primary Location is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	usePatientPrimaryLocation!: boolean;

}
