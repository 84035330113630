// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEyefinityFeeLensResponse } from './admin-eyefinity-fee-lens-response';

// @ts-ignore
import { AdminEyefinityFeeOptionResponse } from './admin-eyefinity-fee-option-response';

// @ts-ignore
import { AdminEyefinityFeeServiceResponse } from './admin-eyefinity-fee-service-response';

/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeScheduleResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeScheduleResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	active!: boolean | null;

	@GandalfArray(AdminEyefinityFeeLensResponse)
	baseLenses!: AdminEyefinityFeeLensResponse[] | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	beginDate!: Date | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	contactLensExamPercentage!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: Number })
	eyefinityFeeScheduleId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameDispensingFee!: number | null;

	@GandalfArray(AdminEyefinityFeeOptionResponse)
	lensAddons!: AdminEyefinityFeeOptionResponse[] | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: Number })
	patientCopayReasonId!: number | null;

	@GandalfProperty({ designType: Number })
	patientTransferReasonId!: number | null;

	@GandalfProperty({ designType: Number })
	recoupmentReasonId!: number | null;

	@GandalfArray(AdminEyefinityFeeServiceResponse)
	services!: AdminEyefinityFeeServiceResponse[] | null;

	@GandalfProperty({ designType: Number })
	writeoffReasonId!: number | null;

}
