// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.laborder.CreateLabOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateLabOrderRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: Number })
	fileCount!: number | null;

	@GandalfProperty({ designType: Number })
	frequencyId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Interpretation Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	interpretationNotes!: string | null;

	@GandalfValidator({ sizeString: { message: 'Interpretation Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	interpretationOtherComments!: string | null;

	@GandalfConstantDecorator(constants.LabOrderInterpretationStatus)
	@GandalfValidator({ notNull: { message: 'Interpretation Status is required' } })
	@GandalfProperty({ designType: constants.LabOrderInterpretationStatus, isRequired: true })
	interpretationStatus!: constants.LabOrderInterpretationStatus;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfValidator({ notNull: { message: 'Lab Type is required' } })
	@GandalfProperty({ designType: constants.LabOrderLabType, isRequired: true })
	labType!: constants.LabOrderLabType;

	@GandalfProperty({ designType: Number })
	labVendorId!: number | null;

	@GandalfProperty({ designType: Boolean })
	nonCpoeEntry!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Normal Range cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	normalRange!: string | null;

	@GandalfValidator({ sizeString: { message: 'Order Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	orderComment!: string | null;

	@GandalfValidator({ notNull: { message: 'Order Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	orderDate!: Date;

	@GandalfValidator({ sizeString: { message: 'Order Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	orderInstructions!: string | null;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfValidator({ sizeString: { message: 'Order must be between 1 and 8 characters', minLength: 1, maxLength: 8 } })
	@GandalfProperty({ designType: String, isRequired: true })
	orderLoincNum!: string;

	@GandalfConstantDecorator(constants.LabOrderType)
	@GandalfValidator({ notNull: { message: 'Order Type is required' } })
	@GandalfProperty({ designType: constants.LabOrderType, isRequired: true })
	orderType!: constants.LabOrderType;

	@GandalfValidator({ notNull: { message: 'Ordered By is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	orderedByProviderId!: number;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	reportDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Result Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	resultComments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Lab Note cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	resultLabNote!: string | null;

	@GandalfConstantDecorator(constants.LabOrderResultStatus)
	@GandalfValidator({ notNull: { message: 'Result Status is required' } })
	@GandalfProperty({ designType: constants.LabOrderResultStatus, isRequired: true })
	resultStatus!: constants.LabOrderResultStatus;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfValidator({ notNull: { message: 'Result Type is required' } })
	@GandalfProperty({ designType: constants.LabOrderResultType, isRequired: true })
	resultType!: constants.LabOrderResultType;

	@GandalfProperty({ designType: Number })
	resultUnitId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Result Value cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	resultValue!: string | null;

	@GandalfValidator({ sizeString: { message: 'Specimen Disposition cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	specimenDisposition!: string | null;

	@GandalfProperty({ designType: Number })
	specimenSourceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Test Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	testName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	testPerformedDate!: Date | null;

	@GandalfProperty({ designType: Number })
	testTypeId!: number | null;

}
