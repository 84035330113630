// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.laborder.SearchLoincCodesRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchLoincCodesRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Search is required' } })
	@GandalfValidator({ sizeString: { fieldLabel: 'Search', maxLength: 1000, minLength: 0, message: 'Search must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String, isRequired: true })
	searchTerm!: string;

}
