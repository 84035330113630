// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { SnomedCodeResponse } from './snomed-code-response';

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyHealthHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	active!: boolean | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: SnomedCodeResponse })
	snomedCode!: SnomedCodeResponse | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusBrother!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusDaughter!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusFather!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusMother!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfProperty({ designType: Boolean })
	statusNoImmediateFamilyMember!: boolean | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusSister!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryHealthCondition })
	statusSon!: constants.FamilyHealthHistoryHealthCondition | null;

	@GandalfProperty({ designType: Date })
	updatedOn!: Date | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
