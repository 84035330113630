// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.pfsh.SocialHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class SocialHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Drinking Amount cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	alcoholAmount!: string | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Hobbies cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	hobbies!: string | null;

	@GandalfConstantDecorator(constants.IsDrinker)
	@GandalfValidator({ notNull: { message: 'Is Drinker is required' } })
	@GandalfProperty({ designType: constants.IsDrinker, isRequired: true })
	isDrinker!: constants.IsDrinker;

	@GandalfConstantDecorator(constants.IsSmoker)
	@GandalfValidator({ notNull: { message: 'Is Smoker is required' } })
	@GandalfProperty({ designType: constants.IsSmoker, isRequired: true })
	isSmoker!: constants.IsSmoker;

	@GandalfValidator({ sizeString: { message: 'Tobacco Amount cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	smokingAmount!: string | null;

	@GandalfConstantDecorator(constants.SmokingPreference)
	@GandalfProperty({ designType: constants.SmokingPreference })
	smokingPreference!: constants.SmokingPreference | null;

	@GandalfConstantDecorator(constants.SmokingStatus)
	@GandalfProperty({ designType: constants.SmokingStatus })
	smokingStatus!: constants.SmokingStatus | null;

}
