// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.IntakeMedicationListResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeMedicationListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ designType: Number })
	intakeMedicationId!: number | null;

	@GandalfProperty({ designType: Number })
	prescriptionId!: number | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	reconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeStatus)
	@GandalfProperty({ designType: constants.IntakeStatus })
	status!: constants.IntakeStatus | null;

	@GandalfConstantDecorator(constants.MedicationPrescriptionType)
	@GandalfProperty({ designType: constants.MedicationPrescriptionType })
	type!: constants.MedicationPrescriptionType | null;

}
