// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.admin.UserSessionLogResponse */
/* istanbul ignore next */
@GandalfModel
export class UserSessionLogResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.UserSessionLogAction)
	@GandalfProperty({ designType: constants.UserSessionLogAction })
	action!: constants.UserSessionLogAction | null;

	@GandalfProperty({ designType: String })
	additionalInfo!: string | null;

	@GandalfProperty({ designType: Number })
	dataLogId!: number | null;

	@GandalfProperty({ designType: Date })
	date!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isCorrupt!: boolean | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	primaryEntityId!: number | null;

	@GandalfConstantDecorator(constants.UserSessionLogEntityType)
	@GandalfProperty({ designType: constants.UserSessionLogEntityType })
	primaryEntityType!: constants.UserSessionLogEntityType | null;

	@GandalfProperty({ designType: Number })
	secondaryEntityId!: number | null;

	@GandalfConstantDecorator(constants.UserSessionLogEntityType)
	@GandalfProperty({ designType: constants.UserSessionLogEntityType })
	secondaryEntityType!: constants.UserSessionLogEntityType | null;

	@GandalfProperty({ designType: Number })
	userId!: number | null;

	@GandalfProperty({ designType: String })
	userName!: string | null;

}
