// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

// @ts-ignore
import { EncounterContactLensTrialRequest } from './encounter-contact-lens-trial-request';

// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.encounter.contactlens.EncounterCreateContactLensTrialRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterCreateContactLensTrialRequest extends EncounterContactLensTrialRequest {

	@GandalfLabel('OD Comments')
	@GandalfValidator({ sizeString: { message: 'OD Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	commentsOd!: string | null;

	@GandalfLabel('OS Comments')
	@GandalfValidator({ sizeString: { message: 'OS Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	commentsOs!: string | null;

	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	encounterId!: number;

	@GandalfLabel('Additional Comments')
	@GandalfValidator({ sizeString: { message: 'Additional Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	evaluationComment!: string | null;

	@GandalfLabel('Evaluation')
	@GandalfValidator({ notNull: { message: 'Evaluation is required' } })
	@GandalfArray(FieldValueRequest)
	evaluationValues!: FieldValueRequest[];

	@GandalfLabel('OD Dispensed')
	@GandalfProperty({ designType: Boolean })
	isDispensedOd!: boolean | null;

	@GandalfLabel('OS Dispensed')
	@GandalfProperty({ designType: Boolean })
	isDispensedOs!: boolean | null;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD details are required' } })
	@GandalfProperty({ designType: ContactLensEyeRequest, isRequired: true })
	od!: ContactLensEyeRequest;

	@GandalfValidator({ notNull: { message: 'OS details are required' } })
	@GandalfProperty({ designType: ContactLensEyeRequest, isRequired: true })
	os!: ContactLensEyeRequest;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfLabel('Lens Type')
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType, isRequired: true })
	overallLensType!: constants.ContactLensPrescriptionType;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	refractionComment!: string | null;

	@GandalfLabel('CL Over-Refraction')
	@GandalfValidator({ notNull: { message: 'CL Over-Refraction is required' } })
	@GandalfArray(FieldValueRequest)
	refractionValues!: FieldValueRequest[];

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	thoroughDocComment!: string | null;

	@GandalfLabel('Thorough Documentation')
	@GandalfProperty({ designType: FieldValueRequest })
	thoroughDocValue!: FieldValueRequest | null;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	vaComment!: string | null;

	@GandalfLabel('CL Aided VA')
	@GandalfValidator({ notNull: { message: 'CL Aided VA is required' } })
	@GandalfArray(FieldValueRequest)
	vaValues!: FieldValueRequest[];

}
