// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientMinerSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientMinerSearchResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	address!: string | null;

	@GandalfProperty({ designType: String })
	city!: string | null;

	@GandalfProperty({ designType: String })
	contactLensOdManufacturer!: string | null;

	@GandalfProperty({ designType: String })
	contactLensOdModel!: string | null;

	@GandalfProperty({ designType: String })
	contactLensOsManufacturer!: string | null;

	@GandalfProperty({ designType: String })
	contactLensOsModel!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dob!: Date | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	eyeglassManufacturer!: string | null;

	@GandalfProperty({ designType: String })
	eyeglassModel!: string | null;

	@GandalfProperty({ designType: Number })
	eyeglassOdCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	eyeglassOdSphere!: number | null;

	@GandalfProperty({ designType: Number })
	eyeglassOsCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	eyeglassOsSphere!: number | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfProperty({ designType: String })
	genderString!: string | null;

	@GandalfProperty({ designType: Date })
	lastExamDate!: Date | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: String })
	middleInitial!: string | null;

	@GandalfProperty({ designType: Date })
	nextApptDate!: Date | null;

	@GandalfProperty({ designType: Date })
	nextRecallDate!: Date | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: String })
	postalCode!: string | null;

	@GandalfProperty({ designType: Number })
	practiceId!: number | null;

	@GandalfProperty({ designType: String })
	preferredPhoneNumber!: string | null;

	@GandalfProperty({ designType: Number })
	primaryLocationId!: number | null;

	@GandalfProperty({ designType: String })
	stateProvince!: string | null;

	@GandalfProperty({ designType: String })
	suffix!: string | null;

	@GandalfProperty({ designType: String })
	title!: string | null;

}
