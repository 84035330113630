// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PaymentInfoResponse } from './payment-info-response';

/** See com.rev360.pms.api.controller.accounting.payment.TransactionResponse */
/* istanbul ignore next */
@GandalfModel
export class TransactionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	checkAmount!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	checkDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateGenerated!: Date | null;

	@GandalfProperty({ designType: String })
	eftNumber!: string;

	@GandalfProperty({ designType: AddressResponse })
	payerAddress!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfArray(PaymentInfoResponse)
	paymentInfo!: PaymentInfoResponse[] | null;

	@GandalfProperty({ designType: AddressResponse })
	providerAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	providerAdjustmentAmount!: number;

	@GandalfProperty({ designType: String })
	providerNPI!: string | null;

	@GandalfProperty({ designType: String })
	providerName!: string | null;

	@GandalfProperty({ designType: String })
	providerTaxNumber!: string | null;

}
