// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { Hl7ValueSetResponse } from './hl7-value-set-response';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

/** See com.rev360.pms.api.controller.laborder.LabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LabOrderResponse extends GandalfModelBase {

	@GandalfArray(Hl7ValueSetResponse)
	abnormalFlags!: Hl7ValueSetResponse[] | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: Number })
	frequencyId!: number | null;

	@GandalfProperty({ designType: Boolean })
	hl7upload!: boolean | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	interpretationNotes!: string | null;

	@GandalfProperty({ designType: String })
	interpretationOtherComments!: string | null;

	@GandalfConstantDecorator(constants.LabOrderInterpretationStatus)
	@GandalfProperty({ designType: constants.LabOrderInterpretationStatus })
	interpretationStatus!: constants.LabOrderInterpretationStatus | null;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfProperty({ designType: constants.LabOrderLabType })
	labType!: constants.LabOrderLabType | null;

	@GandalfProperty({ designType: Number })
	labVendorId!: number | null;

	@GandalfProperty({ designType: Boolean })
	nonCpoeEntry!: boolean | null;

	@GandalfProperty({ designType: String })
	normalRange!: string | null;

	@GandalfProperty({ designType: Hl7ValueSetResponse })
	observationResultStatus!: Hl7ValueSetResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	observationStartedOn!: Date | null;

	@GandalfProperty({ designType: LoincCodeResponse })
	order!: LoincCodeResponse | null;

	@GandalfProperty({ designType: String })
	orderComment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	orderDate!: Date | null;

	@GandalfProperty({ designType: String })
	orderInstructions!: string | null;

	@GandalfConstantDecorator(constants.LabOrderStatus)
	@GandalfProperty({ designType: constants.LabOrderStatus })
	orderStatus!: constants.LabOrderStatus | null;

	@GandalfConstantDecorator(constants.LabOrderType)
	@GandalfProperty({ designType: constants.LabOrderType })
	orderType!: constants.LabOrderType | null;

	@GandalfProperty({ designType: Number })
	orderedByProviderId!: number | null;

	@GandalfProperty({ designType: Number })
	parentId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	reportDate!: Date | null;

	@GandalfProperty({ designType: String })
	resultComments!: string | null;

	@GandalfProperty({ designType: String })
	resultLabNote!: string | null;

	@GandalfConstantDecorator(constants.LabOrderResultStatus)
	@GandalfProperty({ designType: constants.LabOrderResultStatus })
	resultStatus!: constants.LabOrderResultStatus | null;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfProperty({ designType: constants.LabOrderResultType })
	resultType!: constants.LabOrderResultType | null;

	@GandalfProperty({ designType: Number })
	resultUnitId!: number | null;

	@GandalfProperty({ designType: String })
	resultValue!: string | null;

	@GandalfProperty({ designType: Hl7ValueSetResponse })
	specimenAppropriateness!: Hl7ValueSetResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	specimenCollectionEndedOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	specimenCollectionStartedOn!: Date | null;

	@GandalfArray(Hl7ValueSetResponse)
	specimenConditions!: Hl7ValueSetResponse[] | null;

	@GandalfProperty({ designType: String })
	specimenDisposition!: string | null;

	@GandalfProperty({ designType: Hl7ValueSetResponse })
	specimenQuality!: Hl7ValueSetResponse | null;

	@GandalfArray(Hl7ValueSetResponse)
	specimenRejectReasons!: Hl7ValueSetResponse[] | null;

	@GandalfProperty({ designType: Number })
	specimenSourceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ designType: String })
	testName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	testPerformedDate!: Date | null;

	@GandalfProperty({ designType: Number })
	testTypeId!: number | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
