// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemPaymentResponse } from './invoice-item-payment-response';

// @ts-ignore
import { PaymentInvoiceListResponse } from './payment-invoice-list-response';

// @ts-ignore
import { PaymentTransferInvoiceItemsResponse } from './payment-transfer-invoice-items-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number;

	@GandalfProperty({ designType: PaymentInvoiceListResponse })
	invoice!: PaymentInvoiceListResponse;

	@GandalfProperty({ designType: Number })
	invoiceId!: number;

	@GandalfProperty({ designType: Number })
	paymentId!: number;

	@GandalfArray(InvoiceItemPaymentResponse)
	paymentItems!: InvoiceItemPaymentResponse[];

	@GandalfArray(PaymentTransferInvoiceItemsResponse)
	paymentTransfers!: PaymentTransferInvoiceItemsResponse[];

}
