// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportRequest */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.AppointmentConfirmationStatusSearch)
	@GandalfProperty({ designType: constants.AppointmentConfirmationStatusSearch })
	appointmentConfirmationStatus!: constants.AppointmentConfirmationStatusSearch | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	appointmentStatus!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	confirmationEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	confirmationStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	createdByEmployeeId!: number | null;

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: Number })
	encounterTypeId!: number | null;

	@GandalfLabel('Appointment End Date')
	@GandalfValidator({ notNull: { message: 'Appointment End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	endDate!: Date;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfConstantDecorator(constants.InsuranceVerifiedStatusSearch)
	@GandalfProperty({ designType: constants.InsuranceVerifiedStatusSearch })
	insuranceVerifiedStatus!: constants.InsuranceVerifiedStatusSearch | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Patient', maxLength: 1000, minLength: 0, message: 'Patient must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	patientName!: string | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfProperty({ designType: Number })
	roleId!: number | null;

	@GandalfLabel('Appointment Start Date')
	@GandalfValidator({ notNull: { message: 'Appointment Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

	@GandalfProperty({ designType: Number })
	subTypeId!: number | null;

}
