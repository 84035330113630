// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IntakeInsuranceListResponse } from './intake-insurance-list-response';

/** See com.rev360.pms.api.controller.insurance.IntakeInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateReported!: Date | null;

	@GandalfProperty({ designType: Number })
	intakeId!: number | null;

	@GandalfArray(IntakeInsuranceListResponse)
	items!: IntakeInsuranceListResponse[] | null;

}
