// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.UpdateIntakeMedicationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateIntakeMedicationRequest extends GandalfModelBase {

	@GandalfLabel('Intake')
	@GandalfValidator({ notNull: { message: 'Intake is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	intakeId!: number;

	@GandalfArray(Number)
	intakeMedicationIds!: number[] | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	medicationCommentsReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noKnownMedicationsReconciledStatus!: constants.IntakeReconciledStatus | null;

}
