// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.shared.person.GuarantorNameResponse */
/* istanbul ignore next */
@GandalfModel
export class GuarantorNameResponse extends PersonNameResponse {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dob!: Date | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

}
