// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PqriAlertRequest } from './pqri-alert-request';

/** See com.rev360.pms.api.controller.encounter.pqri.AddPqriAlertsToEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class AddPqriAlertsToEncounterRequest extends GandalfModelBase {

	@GandalfLabel('Encounter Id')
	@GandalfValidator({ notNull: { message: 'Encounter Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	encounterId!: number;

	@GandalfValidator({ min: { min: 1, message: 'Pqri alert list must have at least 1 value' } })
	@GandalfArray(PqriAlertRequest)
	pqriAlerts!: PqriAlertRequest[];

}
