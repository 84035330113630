// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEyefinityFeeLensResponse } from './admin-eyefinity-fee-lens-response';

// @ts-ignore
import { AdminEyefinityFeeOptionResponse } from './admin-eyefinity-fee-option-response';

// @ts-ignore
import { AdminEyefinityFeeServiceResponse } from './admin-eyefinity-fee-service-response';

/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeScheduleTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeScheduleTemplateResponse extends GandalfModelBase {

	@GandalfArray(AdminEyefinityFeeLensResponse)
	baseLenses!: AdminEyefinityFeeLensResponse[] | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfArray(AdminEyefinityFeeOptionResponse)
	lensAddons!: AdminEyefinityFeeOptionResponse[] | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(AdminEyefinityFeeServiceResponse)
	services!: AdminEyefinityFeeServiceResponse[] | null;

}
