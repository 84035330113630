// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensPrescriptionDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	authorizationDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ designType: ProviderResponse })
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	contactLensEyeOd!: ContactLensEyeResponse | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	contactLensEyeOs!: ContactLensEyeResponse | null;

	@GandalfConstantDecorator(constants.ContactLensModality)
	@GandalfProperty({ designType: constants.ContactLensModality })
	contactLensModality!: constants.ContactLensModality | null;

	@GandalfProperty({ designType: Number })
	deliveryMethodId!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	dispenseOdAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dispenseOdUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	dispenseOsAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dispenseOsUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	dropsName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: String })
	enzymaticCleanerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	lensCleanerName!: string | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty({ designType: ProductForPrescriptionResponse })
	productOd!: ProductForPrescriptionResponse | null;

	@GandalfProperty({ designType: ProductForPrescriptionResponse })
	productOs!: ProductForPrescriptionResponse | null;

	@GandalfProperty({ designType: String })
	solutionName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty({ designType: constants.PrescriptionStatus })
	status!: constants.PrescriptionStatus | null;

}
