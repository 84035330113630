// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.preferences.StringAdditionalPreferenceRequest */
/* istanbul ignore next */
@GandalfModel
export class StringAdditionalPreferenceRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PreferenceName)
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfProperty({ designType: constants.PreferenceName, isRequired: true })
	preferenceName!: constants.PreferenceName;

	@GandalfValidator({ sizeString: { message: 'Value cannot be longer than 65535 characters', minLength: 0, maxLength: 65535 } })
	@GandalfProperty({ designType: String })
	value!: string | null;

}
