// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.schedule.sidebardetails.PersonInsuranceDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	coInsurancePct!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyDeductible!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	groupContactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	groupGeneralCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	groupMaterials!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	groupMedicalExamCoPay!: number | null;

	@GandalfProperty({ designType: String })
	groupNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	groupRoutineExamCoPay!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	individualContactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	individualGeneralCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	individualMaterials!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	individualMedicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	individualRoutineExamCoPay!: number | null;

	@GandalfProperty({ designType: AddressResponse })
	insuranceCompanyAddress!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	insuranceCompanyName!: string | null;

	@GandalfProperty({ designType: BusinessPhonesResponse })
	insuranceCompanyPhones!: BusinessPhonesResponse | null;

	@GandalfProperty({ designType: String })
	insuranceCompanyWebSite!: string | null;

	@GandalfProperty({ designType: String })
	payerId!: string | null;

	@GandalfProperty({ designType: String })
	planName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	policyHolderDob!: Date | null;

	@GandalfProperty({ designType: PersonNameResponse })
	policyHolderName!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	policyNumber!: string | null;

	@GandalfConstantDecorator(constants.InsuranceCompanyStatus)
	@GandalfProperty({ designType: constants.InsuranceCompanyStatus })
	practiceInsuranceCompanyStatus!: constants.InsuranceCompanyStatus | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	priority!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	rxBinNumber!: string | null;

	@GandalfProperty({ designType: String })
	rxPcnNumber!: string | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty({ designType: constants.PersonInsuranceStatus })
	status!: constants.PersonInsuranceStatus | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	type!: ReferenceDataResponse | null;

}
