// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.reporting.accounting.RefundsReportResponse */
/* istanbul ignore next */
@GandalfModel
export class RefundsReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	amount!: number | null;

	@GandalfProperty({ designType: Number })
	entityId!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfProperty({ designType: PracticeLocationSummaryResponse })
	practiceLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty({ designType: PersonNameResponse })
	provider!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	quantity!: number | null;

	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.RefundFrom)
	@GandalfProperty({ designType: constants.RefundFrom })
	refundFrom!: constants.RefundFrom | null;

	@GandalfProperty({ designType: Number })
	refundId!: number | null;

}
