// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.manufacturer.ManufacturerResponse */
/* istanbul ignore next */
@GandalfModel
export class ManufacturerResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Boolean })
	editable!: boolean | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	externalId!: string | null;

	@GandalfProperty({ designType: String })
	fax!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	mainPhone!: string | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(Number)
	productBrandIds!: number[] | null;

	@GandalfArray(Number)
	productCategoryIds!: number[] | null;

	@GandalfProperty({ designType: String })
	smartFlowCode!: string | null;

	@GandalfProperty({ designType: String })
	tollFree!: string | null;

	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
