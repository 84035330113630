// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.practiceinsuranceremittancecodeadjustment.PracticeInsuranceRemittanceCodeAdjustmentResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceRemittanceCodeAdjustmentResponse extends GandalfModelBase {

	@GandalfProperty({ designType: ReferenceDataResponse })
	accountingAdjustmentReason!: ReferenceDataResponse;

	@GandalfConstantDecorator(constants.PaymentTransferMethodType)
	@GandalfProperty({ designType: constants.PaymentTransferMethodType })
	autoPostingAdjustmentType!: constants.PaymentTransferMethodType;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.RemittanceAdjustmentGroupCode)
	@GandalfProperty({ designType: constants.RemittanceAdjustmentGroupCode })
	groupCode!: constants.RemittanceAdjustmentGroupCode | null;

	@GandalfProperty({ designType: Boolean })
	isActive!: boolean;

	@GandalfProperty({ designType: Number })
	practiceInsuranceRemittanceCodeAdjustmentId!: number;

	@GandalfProperty({ designType: String })
	x12Code!: string;

}
