// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateGeneralMedicationPrescriptionRequest } from './create-general-medication-prescription-request';

/** See com.rev360.pms.api.controller.prescription.UpdateGeneralMedicationPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateGeneralMedicationPrescriptionRequest extends CreateGeneralMedicationPrescriptionRequest {

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfValidator({ notNull: { message: 'General Medication is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	generalMedicationId!: number;

	@GandalfValidator({ notNull: { message: 'ID is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	stopDate!: Date | null;

}
