// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.smartflow.CustomerSmartflowAgreementsResponse */
/* istanbul ignore next */
@GandalfModel
export class CustomerSmartflowAgreementsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	gpoSignedByFirstName!: string | null;

	@GandalfProperty({ designType: String })
	gpoSignedByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	gpoSignedOnDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	hasSignedGpo!: boolean | null;

}
