// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderPrescriptionResponse } from './order-prescription-response';

/** See com.rev360.pms.api.controller.order.OrderContactLensPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderContactLensPrescriptionResponse extends OrderPrescriptionResponse {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: LocationProductForOrderResponse })
	odLocationProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty({ designType: LocationProductItemForOrderResponse })
	odLocationProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfProperty({ designType: Number })
	odProductId!: number | null;

	@GandalfProperty({ designType: LocationProductForOrderResponse })
	osLocationProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty({ designType: LocationProductItemForOrderResponse })
	osLocationProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfProperty({ designType: Number })
	osProductId!: number | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty({ designType: constants.PrescriptionStatus })
	status!: constants.PrescriptionStatus | null;

}
