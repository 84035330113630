// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.CreateRefundPatientRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRefundPatientRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: true, negativeAllowed: false, message: 'Amount cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money', designType: Number, isRequired: true })
	amount!: number;

	@GandalfLabel('Invoice')
	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfLabel('Location')
	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	locationId!: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfLabel('Payment Transaction')
	@GandalfProperty({ designType: Number })
	paymentTransactionId!: number | null;

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	reason!: string | null;

	@GandalfLabel('Reference Number')
	@GandalfValidator({ sizeString: { message: 'Reference Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfLabel('Refund Date')
	@GandalfValidator({ notNull: { message: 'Refund Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	refundDate!: Date;

	@GandalfConstantDecorator(constants.RefundFrom)
	@GandalfLabel('Method')
	@GandalfValidator({ notNull: { message: 'Method is required' } })
	@GandalfProperty({ designType: constants.RefundFrom, isRequired: true })
	refundFrom!: constants.RefundFrom;

}
