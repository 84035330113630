// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InterviewResponse } from './interview-response';

// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.encounter.interview.InterviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class InterviewHistoryResponse extends InterviewResponse {

	@GandalfProperty({ designType: String })
	appointmentEncounterTemplateName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	appointmentStartDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: String })
	encounterType!: string | null;

	@GandalfProperty({ designType: Number })
	intakeId!: number | null;

	@GandalfProperty({ designType: PatientFileInfoResponse })
	intakePatientFile!: PatientFileInfoResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	interviewDate!: Date | null;

	@GandalfProperty({ designType: Number })
	interviewId!: number | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

}
