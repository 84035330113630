// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.provider.externalprovider.ExternalProviderResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalProviderResponse extends ProviderResponse {

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	altPracticeName!: string | null;

	@GandalfProperty({ designType: String })
	credentials!: string | null;

	@GandalfProperty({ designType: String })
	deaNum!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	directAddress!: string | null;

	@GandalfProperty({ designType: String })
	ein!: string | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: String })
	licenseNum!: string | null;

	@GandalfProperty({ designType: String })
	nickName!: string | null;

	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Number })
	providerDisplayColor!: number | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfArray(ProviderInsuranceIdentifierResponse)
	providerInsuranceIdentifierResponses!: ProviderInsuranceIdentifierResponse[] | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	providerType!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	providerTypeId!: number | null;

	@GandalfProperty({ designType: String })
	registrationNum!: string | null;

	@GandalfConstantDecorator(constants.ProviderStatus)
	@GandalfProperty({ designType: constants.ProviderStatus })
	status!: constants.ProviderStatus | null;

	@GandalfProperty({ designType: String })
	suffix!: string | null;

	@GandalfProperty({ designType: Number })
	titleId!: number | null;

	@GandalfProperty({ designType: String })
	titleName!: string | null;

	@GandalfProperty({ designType: String })
	upin!: string | null;

}
