// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.pharmacy.PharmacyResponse */
/* istanbul ignore next */
@GandalfModel
export class PharmacyResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	faxNumber!: string | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: String })
	mainPhone!: string | null;

	@GandalfProperty({ designType: String })
	ncpdp!: string | null;

	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: Number })
	pharmacyBrandId!: number | null;

	@GandalfProperty({ designType: String })
	pharmacyBrandName!: string | null;

	@GandalfProperty({ designType: Number })
	pharmacyId!: number | null;

	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
