// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MappedTestFieldRequest } from './mapped-test-field-request';

/** See com.rev360.pms.api.controller.patient.encounter.workflow.device.MappedTestRequest */
/* istanbul ignore next */
@GandalfModel
export class MappedTestRequest extends GandalfModelBase {

	@GandalfArray(MappedTestFieldRequest)
	fields!: MappedTestFieldRequest[];

	@GandalfLabel('Workflow Test Master ID')
	@GandalfValidator({ notNull: { message: 'Workflow Test Master ID is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	masterId!: number;

}
