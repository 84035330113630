// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.RefundPatientResponse */
/* istanbul ignore next */
@GandalfModel
export class RefundPatientResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	date!: Date | null;

	@GandalfConstantDecorator(constants.RefundFrom)
	@GandalfProperty({ designType: constants.RefundFrom })
	from!: constants.RefundFrom | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfProperty({ designType: Number })
	paymentTransactionId!: number | null;

	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfProperty({ designType: Number })
	refundId!: number | null;

	@GandalfConstantDecorator(constants.RefundStatus)
	@GandalfProperty({ designType: constants.RefundStatus })
	status!: constants.RefundStatus | null;

}
