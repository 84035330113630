// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.InvoiceDashboardSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class InvoiceDashboardSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.InvoiceSearchAuthorization)
	@GandalfLabel('Approval')
	@GandalfProperty({ designType: constants.InvoiceSearchAuthorization })
	authorized!: constants.InvoiceSearchAuthorization | null;

	@GandalfLabel('Claim Submit Date End')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	claimDateEnd!: Date | null;

	@GandalfLabel('Claim Submit Date Start')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	claimDateStart!: Date | null;

	@GandalfLabel('Claim #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Claim # may not be more than 18 digits' } })
	@GandalfProperty({ designType: Number })
	claimId!: number | null;

	@GandalfConstantDecorator(constants.InvoiceSearchAgeRange)
	@GandalfLabel('Invoice Age')
	@GandalfProperty({ designType: constants.InvoiceSearchAgeRange })
	invoiceAge!: constants.InvoiceSearchAgeRange | null;

	@GandalfLabel('Invoice Age End')
	@GandalfValidator({ max: { max: 9999, message: 'Invoice Age End may not be more than 4 digits' } })
	@GandalfProperty({ designType: Number })
	invoiceAgeCustomEnd!: number | null;

	@GandalfLabel('Invoice Age Start')
	@GandalfValidator({ max: { max: 9999, message: 'Invoice Age Start may not be more than 4 digits' } })
	@GandalfProperty({ designType: Number })
	invoiceAgeCustomStart!: number | null;

	@GandalfLabel('Invoice Date End')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	invoiceDateEnd!: Date | null;

	@GandalfLabel('Invoice Date Start')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	invoiceDateStart!: Date | null;

	@GandalfLabel('Invoice #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice # may not be more than 18 digits' } })
	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfLabel('Location')
	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfConstantDecorator(constants.InvoiceSearchOrigin)
	@GandalfLabel('Origin')
	@GandalfProperty({ designType: constants.InvoiceSearchOrigin })
	origin!: constants.InvoiceSearchOrigin | null;

	@GandalfLabel('Patient #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Patient # may not be more than 18 digits' } })
	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfLabel('Patient Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Patient Name', maxLength: 1000, minLength: 0, message: 'Patient Name must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	patientLastName!: string | null;

	@GandalfLabel('Insurance Type')
	@GandalfProperty({ designType: Number })
	payerInsuranceType!: number | null;

	@GandalfLabel('Payer Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfLabel('Payer Type')
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfLabel('Stmt. Print Date End')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	printDateEnd!: Date | null;

	@GandalfLabel('Stmt. Print Date Start')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	printDateStart!: Date | null;

	@GandalfLabel('Provider')
	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfLabel('Service Date End')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceDateEnd!: Date | null;

	@GandalfLabel('Service Date Start')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceDateStart!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceSearchStatus)
	@GandalfLabel('Status')
	@GandalfProperty({ designType: constants.InvoiceSearchStatus })
	status!: constants.InvoiceSearchStatus | null;

}
