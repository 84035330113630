// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientCommunicationPreferencesResponse } from './patient-communication-preferences-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PatientPortalAuthorizedPatientResponse } from './patient-portal-authorized-patient-response';

// @ts-ignore
import { PatientReferralResponse } from './patient-referral-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PreviousAddressResponse } from './previous-address-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowLogin!: boolean | null;

	@GandalfProperty({ designType: String })
	alternateIdentifier!: string | null;

	@GandalfArray(PatientPortalAuthorizedPatientResponse)
	authorizedPatients!: PatientPortalAuthorizedPatientResponse[] | null;

	@GandalfConstantDecorator(constants.BloodType)
	@GandalfProperty({ designType: constants.BloodType })
	bloodType!: constants.BloodType | null;

	@GandalfProperty({ designType: Date })
	cellPhoneDeactivatedDate!: Date | null;

	@GandalfProperty({ designType: Date })
	cellPhoneStoppedDate!: Date | null;

	@GandalfProperty({ designType: Number })
	citizenshipId!: number | null;

	@GandalfProperty({ designType: PatientCommunicationPreferencesResponse })
	communicationPreferences!: PatientCommunicationPreferencesResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dob!: Date | null;

	@GandalfProperty({ designType: Date })
	dod!: Date | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	dominantEye!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	dominantHand!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	driversLicense!: string | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: Boolean })
	emailDeclined!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	emailValid!: boolean | null;

	@GandalfProperty({ designType: PatientEmploymentResponse })
	employment!: PatientEmploymentResponse | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfArray(ReferenceDataResponse)
	ethnicities!: ReferenceDataResponse[] | null;

	@GandalfProperty({ designType: Boolean })
	ethnicityDeclined!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	fullSsnAvailable!: boolean | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfConstantDecorator(constants.GenderIdentity)
	@GandalfProperty({ designType: constants.GenderIdentity })
	genderIdentity!: constants.GenderIdentity | null;

	@GandalfProperty({ designType: PersonNameResponse })
	guarantor!: PersonNameResponse | null;

	@GandalfProperty({ designType: Boolean })
	hasPassword!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasTemporaryAddress!: boolean | null;

	@GandalfProperty({ designType: AddressResponse })
	homeAddress!: AddressResponse | null;

	@GandalfConstantDecorator(constants.PersonInterpreterAssistance)
	@GandalfProperty({ designType: constants.PersonInterpreterAssistance })
	interpreterAssistance!: constants.PersonInterpreterAssistance | null;

	@GandalfProperty({ designType: String })
	last4ssn!: string | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	maritalStatus!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfArray(PatientNameResponse)
	patientPortalRepresentatives!: PatientNameResponse[] | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Number })
	photoId!: number | null;

	@GandalfProperty({ designType: PreviousAddressResponse })
	previousAddress!: PreviousAddressResponse | null;

	@GandalfProperty({ designType: String })
	previousFirstName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousFirstNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ designType: String })
	previousLastName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousLastNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ designType: String })
	previousMiddleName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty({ designType: constants.PreviousNameType })
	previousMiddleNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	previousNameEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	previousNameStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	primaryLocationId!: number | null;

	@GandalfProperty({ designType: ProviderResponse })
	provider!: ProviderResponse | null;

	@GandalfProperty({ designType: Boolean })
	raceDeclined!: boolean | null;

	@GandalfArray(ReferenceDataResponse)
	races!: ReferenceDataResponse[] | null;

	@GandalfArray(PatientReferralResponse)
	referrals!: PatientReferralResponse[] | null;

	@GandalfProperty({ designType: PatientReferralResponse })
	referrer!: PatientReferralResponse | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	religion!: ReferenceDataResponse | null;

	@GandalfConstantDecorator(constants.SexualOrientation)
	@GandalfProperty({ designType: constants.SexualOrientation })
	sexualOrientation!: constants.SexualOrientation | null;

	@GandalfProperty({ designType: String })
	sin!: string | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	spokenLanguage!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	spokenLanguageDeclined!: boolean | null;

	@GandalfProperty({ designType: AddressResponse })
	temporaryAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	temporaryAddressEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	temporaryAddressStartDate!: Date | null;

	@GandalfProperty({ designType: String })
	userName!: string | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	writtenLanguage!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	writtenLanguageDeclined!: boolean | null;

}
