// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.twelve84.Twelve84SubscriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class Twelve84SubscriptionResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	locationContactEmployeeId!: number | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

}
