// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.PatientDashboardSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDashboardSummaryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowLogin!: boolean | null;

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ designType: Date })
	dateOfBirth!: Date | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	dominantEye!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	dominantHand!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: Boolean })
	emailValid!: boolean | null;

	@GandalfProperty({ designType: PatientEmploymentResponse })
	employment!: PatientEmploymentResponse | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfProperty({ designType: Number })
	guarantorId!: number | null;

	@GandalfProperty({ designType: Boolean })
	hasFullSsn!: boolean | null;

	@GandalfProperty({ designType: AddressResponse })
	homeAddress!: AddressResponse | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isOnWaitList!: boolean | null;

	@GandalfProperty({ designType: String })
	last4ssn!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	name!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Number })
	photoId!: number | null;

	@GandalfProperty({ designType: PracticeLocationSummaryResponse })
	primaryLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty({ designType: ProviderResponse })
	primaryProvider!: ProviderResponse | null;

	@GandalfProperty({ designType: String })
	sin!: string | null;

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty({ designType: constants.PatientStatus })
	status!: constants.PatientStatus | null;

	@GandalfProperty({ designType: AddressResponse })
	tempAddress!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	userName!: string | null;

}
