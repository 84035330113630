// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.prescription.MedicationPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	authorizationDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ designType: ProviderResponse })
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty({ designType: Number })
	bodyLocationId!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	diagnosisId!: number | null;

	@GandalfProperty({ designType: Number })
	dispenseAmount!: number | null;

	@GandalfProperty({ designType: Number })
	dispenseUnitId!: number | null;

	@GandalfProperty({ designType: Boolean })
	doNotSubstitute!: boolean | null;

	@GandalfProperty({ designType: Number })
	dosageAmount!: number | null;

	@GandalfProperty({ designType: Number })
	dosageUnitId!: number | null;

	@GandalfProperty({ designType: Number })
	drugId!: number | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	frequencyId!: number | null;

	@GandalfProperty({ designType: Number })
	generalMedicationId!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	modifiedDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	nonCpoeEntry!: boolean | null;

	@GandalfProperty({ designType: Number })
	numRefillsAllowed!: number | null;

	@GandalfProperty({ designType: Number })
	routeId!: number | null;

	@GandalfProperty({ designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty({ designType: constants.PrescriptionStatus })
	status!: constants.PrescriptionStatus | null;

	@GandalfProperty({ designType: Date })
	stopDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	useProviderSignature!: boolean | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
