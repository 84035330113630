// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodMedicationResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodMedicationResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ designType: PersonNameResponse })
	authorizedBy!: PersonNameResponse | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	bodyLocation!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	dosageAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dosageUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ designType: DurationResponse })
	duration!: DurationResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	frequency!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	medicationPrescriptionId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	modifiedDate!: Date | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	route!: MasterReferenceDataResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty({ designType: constants.PrescriptionCurrentStatus })
	status!: constants.PrescriptionCurrentStatus | null;

	@GandalfConstantDecorator(constants.MedicationPrescriptionType)
	@GandalfProperty({ designType: constants.MedicationPrescriptionType })
	type!: constants.MedicationPrescriptionType | null;

}
