// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.communicationtemplate.AdminPracticeTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeTemplateResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	bottomMargin!: number;

	@GandalfProperty({ designType: String })
	content!: string;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Boolean })
	fulfillsPcpCommunicationCqm!: boolean;

	@GandalfProperty({ designType: Number })
	leftMargin!: number;

	@GandalfProperty({ designType: String })
	name!: string;

	@GandalfProperty({ designType: Number })
	practiceTemplateId!: number;

	@GandalfProperty({ designType: Number })
	rightMargin!: number;

	@GandalfProperty({ designType: Number })
	topMargin!: number;

}
