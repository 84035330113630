// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.AssignFeeScheduleItemRequest */
/* istanbul ignore next */
@GandalfModel
export class AssignFeeScheduleItemRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.FeeScheduleItemType)
	@GandalfValidator({ notNull: { message: 'FeeScheduleItemType is required' } })
	@GandalfProperty({ designType: constants.FeeScheduleItemType, isRequired: true })
	feeScheduleItemType!: constants.FeeScheduleItemType;

	@GandalfProperty({ designType: Number })
	feeScheduleRangeId!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isOtherService!: boolean | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	maximumAllowable!: number | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

}
