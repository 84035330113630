// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ClaimSubmitErrorResponse } from './claim-submit-error-response';

/** See com.rev360.pms.api.controller.accounting.claims.ClaimSubmitResponse */
/* istanbul ignore next */
@GandalfModel
export class ClaimSubmitResponse extends GandalfModelBase {

	@GandalfArray(ClaimSubmitErrorResponse)
	errors!: ClaimSubmitErrorResponse[] | null;

	@GandalfProperty({ designType: Boolean })
	generateRawClaims!: boolean | null;

}
