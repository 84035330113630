// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.family.FamilyAccountMemberDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyAccountMemberDetailResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	credit!: number | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfConstantDecorator(constants.EmergencyContactType)
	@GandalfProperty({ designType: constants.EmergencyContactType })
	emergencyContactType!: constants.EmergencyContactType | null;

	@GandalfProperty({ designType: Boolean })
	headOfHousehold!: boolean | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isEmergencyContact!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lastComprehensiveEncounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	lastComprehensiveEncounterId!: number | null;

	@GandalfProperty({ designType: String })
	lastComprehensiveEncounterType!: string | null;

	@GandalfProperty({ designType: PersonNameResponse })
	name!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: String })
	phone!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	role!: ReferenceDataSummaryResponse | null;

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty({ designType: constants.PatientStatus })
	status!: constants.PatientStatus | null;

}
