// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterBaudRateResponse } from './master-baud-rate-response';

// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

/** See com.rev360.pms.api.controller.shared.device.MasterSerialDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class MasterSerialDeviceResponse extends MasterDeviceResponse {

	@GandalfProperty({ designType: Number })
	dataBits!: number;

	@GandalfProperty({ designType: Boolean })
	dataTerminalReady!: boolean;

	@GandalfProperty({ designType: MasterBaudRateResponse })
	defaultBaudRate!: MasterBaudRateResponse;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.MasterDeviceKey)
	@GandalfProperty({ designType: constants.MasterDeviceKey })
	deviceKey!: constants.MasterDeviceKey;

	@GandalfProperty({ designType: String })
	manufacturer!: string;

	@GandalfProperty({ designType: Number })
	masterDeviceId!: number;

	@GandalfProperty({ designType: String })
	model!: string;

	@GandalfConstantDecorator(constants.Parity)
	@GandalfProperty({ designType: constants.Parity })
	parity!: constants.Parity;

	@GandalfProperty({ designType: Boolean })
	requestToSend!: boolean;

	@GandalfProperty({ designType: Number })
	stopBits!: number;

	@GandalfArray(MasterBaudRateResponse)
	supportedBaudRates!: MasterBaudRateResponse[];

}
