// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.age.BaseAgeReportResponse */
/* istanbul ignore next */
@GandalfModel
export class BaseAgeReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	averageAge!: number | null;

	@GandalfProperty({ designType: Number })
	averageAgeOfStock!: number | null;

	@GandalfProperty({ designType: String })
	brand!: string | null;

	@GandalfProperty({ designType: String })
	category!: string | null;

	@GandalfProperty({ designType: String })
	collection!: string | null;

	@GandalfProperty({ designType: String })
	itemDescription!: string | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	locationProductActualCost!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

	@GandalfProperty({ designType: String })
	manufacturer!: string | null;

	@GandalfProperty({ designType: Number })
	maximumAge!: number | null;

	@GandalfProperty({ designType: Number })
	maximumAgeOfStock!: number | null;

	@GandalfProperty({ designType: Number })
	minimumAge!: number | null;

	@GandalfProperty({ designType: Number })
	minimumAgeOfStock!: number | null;

	@GandalfProperty({ designType: String })
	model!: string | null;

	@GandalfProperty({ designType: String })
	sku!: string | null;

	@GandalfProperty({ designType: Number })
	stock!: number | null;

	@GandalfProperty({ designType: String })
	upc!: string | null;

}
