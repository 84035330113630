// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.diagnosis.CarePlanItemTemplateListResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	carePlanItemTemplateId!: number | null;

	@GandalfConstantDecorator(constants.CarePlanItemCategory)
	@GandalfProperty({ designType: constants.CarePlanItemCategory })
	category!: constants.CarePlanItemCategory | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.CarePlanItemEducationSource)
	@GandalfProperty({ designType: constants.CarePlanItemEducationSource })
	source!: constants.CarePlanItemEducationSource | null;

}
