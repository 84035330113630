// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.CreatePracticeConsentFormRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePracticeConsentFormRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Allow electronic click is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	allowElectronicClick!: boolean;

	@GandalfValidator({ notNull: { message: 'Allow paper is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	allowPaper!: boolean;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormExpirationType)
	@GandalfValidator({ notNull: { message: 'Expiration type is required' } })
	@GandalfProperty({ designType: constants.PracticeConsentFormExpirationType, isRequired: true })
	expirationType!: constants.PracticeConsentFormExpirationType;

	@GandalfProperty({ designType: Number })
	expireDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	expireDurationUnit!: constants.DurationUnit | null;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfConstantDecorator(constants.PracticeConsentFormRequired)
	@GandalfValidator({ notNull: { message: 'Required is required' } })
	@GandalfProperty({ designType: constants.PracticeConsentFormRequired, isRequired: true })
	required!: constants.PracticeConsentFormRequired;

	@GandalfConstantDecorator(constants.PracticeConsentFormType)
	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ designType: constants.PracticeConsentFormType, isRequired: true })
	type!: constants.PracticeConsentFormType;

}
