// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.DeleteClaimsRequest */
/* istanbul ignore next */
@GandalfModel
export class DeleteClaimsRequest extends GandalfModelBase {

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Insurance claims are required' } })
	@GandalfValidator({ notNull: { message: 'Insurance claims are required' } })
	@GandalfArray(Number)
	insuranceClaimIds!: number[];

}
