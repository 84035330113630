// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.contact.PatientContactEmergencyContactStatusRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientContactEmergencyContactStatusRequest extends GandalfModelBase {

	@GandalfLabel('Contact Id')
	@GandalfValidator({ notNull: { message: 'Contact Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	contactId!: number;

	@GandalfLabel('Patient Id')
	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	parentPatientId!: number;

}
