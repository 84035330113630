// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AllergyResponse } from './allergy-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.person.DrugAllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class DrugAllergyResponse extends AllergyResponse {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	deactivationDate!: Date | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	displayName!: string | null;

	@GandalfProperty({ designType: Number })
	drugClassId!: number | null;

	@GandalfProperty({ designType: String })
	drugCode!: string | null;

	@GandalfProperty({ designType: Number })
	drugId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: String })
	externalId!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfArray(Number)
	reactionIds!: number[] | null;

	@GandalfArray(ReferenceDataResponse)
	reactions!: ReferenceDataResponse[] | null;

	@GandalfConstantDecorator(constants.AllergySeverity)
	@GandalfProperty({ designType: constants.AllergySeverity })
	severity!: constants.AllergySeverity | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty({ designType: constants.AllergyStatus })
	status!: constants.AllergyStatus | null;

	@GandalfProperty({ designType: String })
	treatment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	updatedDate!: Date | null;

}
