// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ScheduleItemSidebarResponse } from './schedule-item-sidebar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.sidebar.EmployeeOfficeHoursSidebarResponse */
/* istanbul ignore next */
@GandalfModel
export class EmployeeOfficeHoursSidebarResponse extends ScheduleItemSidebarResponse {

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: RecurringDefinitionResponse })
	recurringDefinition!: RecurringDefinitionResponse | null;

	@GandalfProperty({ designType: Number })
	scheduleItemId!: number | null;

	@GandalfProperty({ designType: String })
	summary!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty({ designType: constants.ScheduleItemType })
	type!: constants.ScheduleItemType | null;

}
