// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.recalls.PatientRecallResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientRecallResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Date })
	appointmentDate!: Date | null;

	@GandalfProperty({ designType: Number })
	appointmentId!: number | null;

	@GandalfProperty({ designType: String })
	appointmentLocation!: string | null;

	@GandalfProperty({ designType: Number })
	appointmentLocationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	recallDate!: Date | null;

	@GandalfProperty({ designType: Number })
	recallId!: number | null;

	@GandalfProperty({ designType: Number })
	recallLocationId!: number | null;

	@GandalfProperty({ designType: String })
	recallLocationName!: string | null;

	@GandalfProperty({ designType: String })
	recallReason!: string | null;

	@GandalfConstantDecorator(constants.RecallStatus)
	@GandalfProperty({ designType: constants.RecallStatus })
	status!: constants.RecallStatus | null;

}
