// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.CreatePatientFileRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientFileRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ notNull: { message: 'Folder Id is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 50, message: '{jakarta.validation.constraints.Size.message}' } })
	@GandalfValidator({ sizeString: { message: 'Folder must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ designType: String, isRequired: true })
	folderId!: string;

	@GandalfValidator({ notNull: { message: 'File Name is required' } })
	@GandalfValidator({ sizeString: { message: 'File Name must be between 1 and 200 characters', minLength: 1, maxLength: 200 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

}
