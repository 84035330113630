// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodOrderSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodOrderSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.OrderType) 
	@GandalfLabel('Order Types')
	@GandalfValidator({ notNull: { message: 'Order Types is required' } })
	@GandalfArray(constants.OrderType)
	orderTypes!: constants.OrderType[];

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

}
