// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.pupillarydistance.PupillaryDistanceHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PupillaryDistanceHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	odMonoDistancePupillaryDistance!: number | null;

	@GandalfProperty({ designType: Number })
	odMonoNearPupillaryDistance!: number | null;

	@GandalfProperty({ designType: Number })
	osMonoDistancePupillaryDistance!: number | null;

	@GandalfProperty({ designType: Number })
	osMonoNearPupillaryDistance!: number | null;

	@GandalfProperty({ designType: Number })
	ouDistancePupillaryDistance!: number | null;

	@GandalfProperty({ designType: Number })
	ouNearPupillaryDistance!: number | null;

	@GandalfConstantDecorator(constants.PupillaryDistanceSource)
	@GandalfProperty({ designType: constants.PupillaryDistanceSource })
	source!: constants.PupillaryDistanceSource | null;

	@GandalfProperty({ designType: Date })
	sourceDate!: Date | null;

	@GandalfProperty({ designType: String })
	sourceName!: string | null;

}
