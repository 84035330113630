// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.WorkflowTemplateStepRequest */
/* istanbul ignore next */
@GandalfModel
export class WorkflowTemplateStepRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Display order is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	displayOrder!: number;

	@GandalfValidator({ notNull: { message: 'Workflow step template is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	workflowStepTemplateId!: number;

	@GandalfProperty({ designType: Number })
	workflowTemplateStepId!: number | null;

}
