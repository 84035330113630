// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.waitinglist.UpdateWaitingListRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateWaitingListRequest extends GandalfModelBase {

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfLabel('End Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfLabel('Location')
	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfConstantDecorator(constants.WaitListPriority)
	@GandalfLabel('Priority')
	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ designType: constants.WaitListPriority, isRequired: true })
	priority!: constants.WaitListPriority;

	@GandalfLabel('Provider')
	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfLabel('Type')
	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	templateId!: number;

	@GandalfLabel('Waiting List')
	@GandalfValidator({ notNull: { message: 'Waiting List is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	waitingListId!: number;

}
