// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.AllergyListResponse */
/* istanbul ignore next */
@GandalfModel
export class AllergyListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	allergyId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	modifiedDate!: Date | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(Number)
	reactionIds!: number[] | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty({ designType: constants.AllergyStatus })
	status!: constants.AllergyStatus | null;

	@GandalfProperty({ designType: String })
	type!: string | null;

}
