// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ongoingcare.CreatePatientTargetIopRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientTargetIopRequest extends GandalfModelBase {

	@GandalfLabel('Comment')
	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfLabel('Date Entered')
	@GandalfValidator({ notNull: { message: 'Date Entered is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	dateEntered!: Date;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfLabel('Target OD')
	@GandalfProperty({ designType: Number })
	targetOd!: number | null;

	@GandalfLabel('Target OS')
	@GandalfProperty({ designType: Number })
	targetOs!: number | null;

}
