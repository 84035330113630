// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.header.TodaysPatientsAppointmentResponse */
/* istanbul ignore next */
@GandalfModel
export class TodaysPatientsAppointmentResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	appointmentConfirmed!: boolean;

	@GandalfProperty({ designType: Number })
	appointmentId!: number;

	@GandalfProperty({ designType: String })
	appointmentSubTypeValue!: string | null;

	@GandalfProperty({ designType: Boolean })
	connectMessageDelivered!: boolean;

	@GandalfProperty({ designType: Boolean })
	connectMessageExists!: boolean;

	@GandalfProperty({ designType: EmployeeNameResponse })
	employeeName!: EmployeeNameResponse | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty({ designType: constants.EncounterStatus })
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty({ designType: Date })
	endDate!: Date;

	@GandalfProperty({ designType: String })
	insuranceComment!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty({ designType: constants.VerificationStatus })
	insuranceVerifiedStatus!: constants.VerificationStatus | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	patientDob!: Date;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse;

	@GandalfProperty({ designType: ProviderResponse })
	provider!: ProviderResponse | null;

	@GandalfProperty({ designType: String })
	roleName!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	scheduleItemStatus!: constants.ScheduleItemStatus;

	@GandalfProperty({ designType: Date })
	startDate!: Date;

	@GandalfProperty({ designType: Number })
	templateDisplayColor!: number;

	@GandalfProperty({ designType: String })
	templateDisplayName!: string;

}
