// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentInvoiceListResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentInvoiceListResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	adjustmentTotal!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ designType: Date })
	invoiceDate!: Date;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType;

	@GandalfProperty({ designType: Number })
	processingPaymentGroupId!: number | null;

	@GandalfProperty({ designType: Date })
	serviceDate!: Date;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	total!: number;

}
