// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TransferItemRequest } from './transfer-item-request';

/** See com.rev360.pms.api.controller.accounting.payment.ReceivePaymentTransferInvoiceItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class ReceivePaymentTransferInvoiceItemsRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Include All Items in Transfer is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	includeAllItems!: boolean;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfProperty({ designType: Number })
	personInsuranceId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	transferAmount!: number | null;

	@GandalfArray(TransferItemRequest)
	transferItems!: TransferItemRequest[] | null;

	@GandalfProperty({ designType: Number })
	transferReferenceId!: number | null;

	@GandalfProperty({ designType: Number })
	transferToExistingInvoiceId!: number | null;

	@GandalfConstantDecorator(constants.TransferType)
	@GandalfValidator({ notNull: { message: 'Transfer Type is required' } })
	@GandalfProperty({ designType: constants.TransferType, isRequired: true })
	transferType!: constants.TransferType;

	@GandalfProperty({ designType: Number })
	writeoffReferenceId!: number | null;

}
