// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.CreatePatientPhoneInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientPhoneInformationRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Cell phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Cell phone number must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	cellPhone!: string | null;

	@GandalfValidator({ sizeString: { message: 'Cell phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty({ designType: String })
	cellPhoneExtension!: string | null;

	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: Boolean })
	emailDeclined!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Home phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Home phone number must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	homePhone!: string | null;

	@GandalfValidator({ sizeString: { message: 'Home phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty({ designType: String })
	homePhoneExtension!: string | null;

	@GandalfValidator({ sizeString: { message: 'Pager cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty({ designType: String })
	pager!: string | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfValidator({ notNull: { message: 'Preferred Phone Type is required' } })
	@GandalfProperty({ designType: constants.PreferredPhoneType, isRequired: true })
	preferredPhoneType!: constants.PreferredPhoneType;

	@GandalfValidator({ sizeString: { message: 'Work phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Work phone number must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	workPhone!: string | null;

	@GandalfValidator({ sizeString: { message: 'Work phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty({ designType: String })
	workPhoneExtension!: string | null;

}
