// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.timer.ProfessionalTimeResponse */
/* istanbul ignore next */
@GandalfModel
export class ProfessionalTimeResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: Boolean })
	hasActiveTimeSessions!: boolean | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: Number })
	professionalTimeId!: number | null;

	@GandalfProperty({ designType: Number })
	totalSeconds!: number | null;

}
