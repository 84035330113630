// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.devicemeasurement.FindUnassignedDeviceMeasurementsRequest */
/* istanbul ignore next */
@GandalfModel
export class FindUnassignedDeviceMeasurementsRequest extends GandalfModelBase {

	@GandalfLabel('End Date')
	@GandalfValidator({ notNull: { message: 'End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	endDate!: Date;

	@GandalfLabel('Start Date')
	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

}
