// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { ContactLensOrderResponse } from './contact-lens-order-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { OrderContactLensPrescriptionResponse } from './order-contact-lens-prescription-response';

// @ts-ignore
import { OrderContactLensTrialResponse } from './order-contact-lens-trial-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.ContactLensTrialOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialOrderResponse extends ContactLensOrderResponse {

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDate!: Date | null;

	@GandalfProperty({ designType: EncounterTableResponse })
	encounter!: EncounterTableResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedDiscounts!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedInsuranceBenefit!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedTaxes!: number | null;

	@GandalfProperty({ designType: String })
	externalSupplierIdentifier!: string | null;

	@GandalfProperty({ designType: String })
	externalSupplierName!: string | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	lensCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	lensRemakeCost!: number | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: String })
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	notificationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	odColorId!: number | null;

	@GandalfProperty({ designType: Number })
	odDispenseQuantity!: number | null;

	@GandalfProperty({ designType: Number })
	odPackagingId!: number | null;

	@GandalfProperty({ designType: Number })
	odQuantity!: number | null;

	@GandalfProperty({ designType: Boolean })
	onHold!: boolean | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfProperty({ designType: Number })
	originalOrderId!: number | null;

	@GandalfConstantDecorator(constants.OriginalOrderReason)
	@GandalfProperty({ designType: constants.OriginalOrderReason })
	originalOrderReason!: constants.OriginalOrderReason | null;

	@GandalfProperty({ designType: Number })
	osColorId!: number | null;

	@GandalfProperty({ designType: Number })
	osDispenseQuantity!: number | null;

	@GandalfProperty({ designType: Number })
	osPackagingId!: number | null;

	@GandalfProperty({ designType: Number })
	osQuantity!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherRemakeCost!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: PatientCommunicationResponse })
	patientCommunication!: PatientCommunicationResponse | null;

	@GandalfProperty({ designType: Boolean })
	patientNotified!: boolean | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: OrderContactLensPrescriptionResponse })
	prescription!: OrderContactLensPrescriptionResponse | null;

	@GandalfProperty({ designType: OrderProcessorConfigResponse })
	processor!: OrderProcessorConfigResponse | null;

	@GandalfProperty({ designType: AddressResponse })
	shipAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty({ designType: constants.OrderShipToType })
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty({ designType: constants.OrderShipmentCompany })
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfProperty({ designType: Number })
	smartflowOrderIdentifier!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	statusCode!: constants.OrderStatusCode | null;

	@GandalfProperty({ designType: Date })
	statusDate!: Date | null;

	@GandalfProperty({ designType: String })
	trackingNumber!: string | null;

	@GandalfProperty({ designType: String })
	tray!: string | null;

	@GandalfProperty({ designType: OrderContactLensTrialResponse })
	trial!: OrderContactLensTrialResponse | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty({ designType: constants.OrderType })
	type!: constants.OrderType | null;

	@GandalfProperty({ designType: String })
	unitOd!: string | null;

	@GandalfProperty({ designType: String })
	unitOs!: string | null;

	@GandalfProperty({ designType: OrderVendorResponse })
	vendor!: OrderVendorResponse | null;

	@GandalfProperty({ designType: String })
	vendorOrderIdentifier!: string | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
