// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.provider.PatientProviderResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientProviderResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	altPracticeName!: string | null;

	@GandalfProperty({ designType: String })
	credentials!: string | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: Number })
	personProviderId!: number | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfProperty({ designType: PersonNameResponse })
	providerName!: PersonNameResponse | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	providerRole!: ReferenceDataResponse | null;

}
