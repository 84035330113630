// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PrescriptionSelectContactLensEyeResponse } from './prescription-select-contact-lens-eye-response';

/** See com.rev360.pms.api.controller.prescription.PrescriptionSelectContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class PrescriptionSelectContactLensResponse extends GandalfModelBase {

	@GandalfProperty({ designType: PersonNameResponse })
	authorizedBy!: PersonNameResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	isExternalProvider!: boolean | null;

	@GandalfProperty({ designType: PrescriptionSelectContactLensEyeResponse })
	odEye!: PrescriptionSelectContactLensEyeResponse | null;

	@GandalfProperty({ designType: PrescriptionSelectContactLensEyeResponse })
	osEye!: PrescriptionSelectContactLensEyeResponse | null;

	@GandalfProperty({ designType: Number })
	prescriptionId!: number | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty({ designType: constants.PrescriptionCurrentStatus })
	status!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty({ designType: Number })
	trialId!: number | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	type!: constants.ContactLensPrescriptionType | null;

}
