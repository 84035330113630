// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.employee.EmployeeNameResponse */
/* istanbul ignore next */
@GandalfModel
export class EmployeeNameResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfConstantDecorator(constants.EmployeeStatus)
	@GandalfProperty({ designType: constants.EmployeeStatus })
	status!: constants.EmployeeStatus | null;

}
