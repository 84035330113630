// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.UpdateScheduleViewPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateScheduleViewPreferencesRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Schedule grouping preference is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	enableGroupingPreference!: boolean;

	@GandalfConstantDecorator(constants.ScheduleIncrementPreferenceValues)
	@GandalfValidator({ notNull: { message: 'Schedule increment preference is required' } })
	@GandalfProperty({ designType: constants.ScheduleIncrementPreferenceValues, isRequired: true })
	incrementPreference!: constants.ScheduleIncrementPreferenceValues;

	@GandalfConstantDecorator(constants.ScheduleLayoutPreferenceValues)
	@GandalfValidator({ notNull: { message: 'Schedule layout preference is required' } })
	@GandalfProperty({ designType: constants.ScheduleLayoutPreferenceValues, isRequired: true })
	layoutPreference!: constants.ScheduleLayoutPreferenceValues;

	@GandalfConstantDecorator(constants.ScheduleView)
	@GandalfProperty({ designType: constants.ScheduleView })
	scheduleView!: constants.ScheduleView | null;

}
