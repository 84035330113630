// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderCountsByStatusResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderCountsByStatusResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	contactLensCount!: number | null;

	@GandalfProperty({ designType: Number })
	count!: number | null;

	@GandalfProperty({ designType: Number })
	eyeglassCount!: number | null;

	@GandalfProperty({ designType: Number })
	framesOnlyCount!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	orderStatusCode!: constants.OrderStatusCode | null;

	@GandalfProperty({ designType: Number })
	overdueCount!: number | null;

}
