// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeLocationSubscriberResponse } from './practice-location-subscriber-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationDistributionCenterResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationDistributionCenterResponse extends GandalfModelBase {

	@GandalfArray(PracticeLocationSubscriberResponse)
	availableSubscriberLocations!: PracticeLocationSubscriberResponse[] | null;

	@GandalfArray(String)
	distributorLocationNames!: string[] | null;

	@GandalfProperty({ designType: Boolean })
	isDistributionCenter!: boolean | null;

	@GandalfArray(Number)
	subscriberLocationIds!: number[] | null;

}
