// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	connectCampaignId!: number | null;

	@GandalfConstantDecorator(constants.ConnectCampaignStatus)
	@GandalfProperty({ designType: constants.ConnectCampaignStatus })
	connectCampaignStatus!: constants.ConnectCampaignStatus | null;

	@GandalfProperty({ designType: PersonNameResponse })
	createdBy!: PersonNameResponse | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: String })
	emailSubject!: string | null;

	@GandalfProperty({ designType: String })
	emailTemplate!: string | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfProperty({ designType: Number })
	queryId!: number | null;

	@GandalfProperty({ designType: Date })
	scheduledDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	usePatientPrimaryLocation!: boolean | null;

}
