// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.PrescriptionSelectContactLensEyeResponse */
/* istanbul ignore next */
@GandalfModel
export class PrescriptionSelectContactLensEyeResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	addDesignation!: string | null;

	@GandalfProperty({ designType: Number })
	addPower!: number | null;

	@GandalfProperty({ designType: Number })
	axis!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurve!: number | null;

	@GandalfProperty({ designType: Number })
	cylinder!: number | null;

	@GandalfProperty({ designType: Number })
	diameter!: number | null;

	@GandalfProperty({ designType: String })
	locationProductName!: string | null;

	@GandalfProperty({ designType: Boolean })
	monovision!: boolean | null;

	@GandalfProperty({ designType: Number })
	sphere!: number | null;

}
