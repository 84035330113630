// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensPrescriptionResponse } from './contact-lens-prescription-response';

// @ts-ignore
import { ContactLensTrialResponse } from './contact-lens-trial-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderContactLensDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderContactLensDetailResponse extends OpticalOrderDetailResponse {

	@GandalfProperty({ designType: Number })
	odDispense!: number | null;

	@GandalfProperty({ designType: String })
	odPackaging!: string | null;

	@GandalfProperty({ designType: String })
	orderComments!: string | null;

	@GandalfProperty({ designType: Number })
	orderDetailId!: number | null;

	@GandalfProperty({ designType: String })
	orderInstructions!: string | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty({ designType: constants.OrderType })
	orderType!: constants.OrderType | null;

	@GandalfProperty({ designType: Number })
	osDispense!: number | null;

	@GandalfProperty({ designType: String })
	osPackaging!: string | null;

	@GandalfProperty({ designType: ContactLensPrescriptionResponse })
	prescription!: ContactLensPrescriptionResponse | null;

	@GandalfProperty({ designType: ContactLensTrialResponse })
	trial!: ContactLensTrialResponse | null;

}
