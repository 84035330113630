// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.file.folder.UpdateFolderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateFolderRequest extends GandalfModelBase {

	@GandalfLabel('Folder')
	@GandalfValidator({ notNull: { message: 'Folder is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 50, message: 'Folder must be between 1 and 50 characters' } })
	@GandalfProperty({ designType: String, isRequired: true })
	folderId!: string;

	@GandalfLabel('Folder name')
	@GandalfValidator({ notNull: { message: 'Folder name is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 50, message: 'Folder name must be between 1 and 50 characters' } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

}
