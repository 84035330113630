// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.UpdateIntakeAllergiesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateIntakeAllergiesRequest extends GandalfModelBase {

	@GandalfLabel('Intake Allergy')
	@GandalfArray(Number)
	intakeAllergyIds!: number[] | null;

	@GandalfLabel('Intake')
	@GandalfValidator({ notNull: { message: 'Intake is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	intakeId!: number;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfLabel('NoKnownDrugAllergiesReconciledStatus')
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noKnownDrugAllergiesReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfLabel('NoLatexSensitivityReconciledStatus')
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noLatexSensitivityReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfLabel('NoOtherKnownAllergiesReconciledStatus')
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noOtherKnownAllergiesReconciledStatus!: constants.IntakeReconciledStatus | null;

}
