// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.employee.detailed.request.AdminEmployeeExternalSystemsRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeExternalSystemsRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.DirectAddressStatus)
	@GandalfValidator({ notNull: { message: 'RevDIRECT Status is required' } })
	@GandalfProperty({ designType: constants.DirectAddressStatus, isRequired: true })
	directAddressStatus!: constants.DirectAddressStatus;

	@GandalfValidator({ sizeString: { message: 'RevDIRECT Username cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	directAddressUsername!: string | null;

	@GandalfValidator({ notNull: { message: 'RXNT Status is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	rxntIsActive!: boolean;

	@GandalfValidator({ sizeString: { message: 'RXNT Password cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	rxntPassword!: string | null;

	@GandalfValidator({ sizeString: { message: 'RXNT Login Name cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	rxntUsername!: string | null;

}
