// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IncorporateDrugAllergyRequest } from './incorporate-drug-allergy-request';

// @ts-ignore
import { IncorporateDrugAllergyUpdateRequest } from './incorporate-drug-allergy-update-request';

// @ts-ignore
import { IncorporateMedicationPrescriptionRequest } from './incorporate-medication-prescription-request';

// @ts-ignore
import { IncorporatePersonDiagnosisRequest } from './incorporate-person-diagnosis-request';

/** See com.rev360.pms.api.controller.patient.incorporate.IncorporateExternalDataRequest */
/* istanbul ignore next */
@GandalfModel
export class IncorporateExternalDataRequest extends GandalfModelBase {

	@GandalfArray(IncorporatePersonDiagnosisRequest)
	diagnosesToAdd!: IncorporatePersonDiagnosisRequest[] | null;

	@GandalfArray(IncorporateDrugAllergyRequest)
	drugAllergiesToAdd!: IncorporateDrugAllergyRequest[] | null;

	@GandalfArray(IncorporateDrugAllergyUpdateRequest)
	drugAllergiesToUpdate!: IncorporateDrugAllergyUpdateRequest[] | null;

	@GandalfArray(Number)
	drugAllergyIdsToRemove!: number[] | null;

	@GandalfArray(Number)
	medicationPrescriptionIdsToRemove!: number[] | null;

	@GandalfArray(IncorporateMedicationPrescriptionRequest)
	medicationPrescriptionsToAdd!: IncorporateMedicationPrescriptionRequest[] | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfArray(Number)
	personDiagnosisIdsToRemove!: number[] | null;

}
