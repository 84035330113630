// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurPatternDailyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurPatternDailyRequest extends GandalfModelBase {

	@GandalfValidator({ max: { max: 365, message: 'Days between recurrence cannot be over 365' } })
	@GandalfValidator({ min: { min: 1, message: 'Days between recurrence cannot be less than 1' } })
	@GandalfProperty({ designType: Number })
	dailyAmount!: number | null;

	@GandalfConstantDecorator(constants.DailyPatternType)
	@GandalfValidator({ notNull: { message: 'Daily Pattern Type must be set.' } })
	@GandalfProperty({ designType: constants.DailyPatternType, isRequired: true })
	dailyPatternType!: constants.DailyPatternType;

}
