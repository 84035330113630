// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.provider.ProviderWithLocationsResponse */
/* istanbul ignore next */
@GandalfModel
export class ProviderWithLocationsResponse extends ProviderResponse {

	@GandalfArray(Number)
	accessiblePracticeLocationIds!: number[] | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: Number })
	providerDisplayColor!: number | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfConstantDecorator(constants.ProviderStatus)
	@GandalfProperty({ designType: constants.ProviderStatus })
	status!: constants.ProviderStatus | null;

}
