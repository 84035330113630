// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

/** See com.rev360.pms.api.controller.shared.person.PersonDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	credentials!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateOfBirth!: Date | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: String })
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfProperty({ designType: String })
	lastName!: string | null;

	@GandalfProperty({ designType: String })
	middleName!: string | null;

	@GandalfProperty({ designType: String })
	nickname!: string | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Number })
	photoId!: number | null;

	@GandalfProperty({ designType: String })
	ssn!: string | null;

	@GandalfProperty({ designType: String })
	suffix!: string | null;

	@GandalfProperty({ designType: Number })
	titleId!: number | null;

}
