// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { WorkflowScreenTestTemplateLayoutRequest } from './workflow-screen-test-template-layout-request';

/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.AdminCreateWorkflowScreenTemplateRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCreateWorkflowScreenTemplateRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Category is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	categoryId!: number;

	@GandalfValidator({ notNull: { message: 'Display Order is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	displayOrder!: number;

	@GandalfLabel('Exclude Pull Forward')
	@GandalfValidator({ notNull: { message: 'Exclude Pull Forward is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	excludePullForward!: boolean;

	@GandalfValidator({ sizeString: { message: 'Label cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfConstantDecorator(constants.WorkflowScreenTemplateLayout)
	@GandalfValidator({ notNull: { message: 'Layout is required' } })
	@GandalfProperty({ designType: constants.WorkflowScreenTemplateLayout, isRequired: true })
	layout!: constants.WorkflowScreenTemplateLayout;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfArray(WorkflowScreenTestTemplateLayoutRequest)
	workflowScreenTestTemplateLayouts!: WorkflowScreenTestTemplateLayoutRequest[];

}
