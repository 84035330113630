// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryFieldCategoryResponse } from './query-field-category-response';

/** See com.rev360.pms.api.controller.query.QueryTemplateCategoryResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryTemplateCategoryResponse extends GandalfModelBase {

	@GandalfArray(QueryFieldCategoryResponse)
	fieldCategories!: QueryFieldCategoryResponse[] | null;

	@GandalfConstantDecorator(constants.QueryTemplateCategory)
	@GandalfProperty({ designType: constants.QueryTemplateCategory })
	templateCategory!: constants.QueryTemplateCategory | null;

}
