// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './field-value-multi-selection-diff-response';

/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsDiffResponse extends GandalfModelBase {

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	cardiovascular!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: String })
	commentsFromIntake!: string | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	constitutional!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	endocrine!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	ent!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	gastrointestinal!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	genitourinary!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: Boolean })
	hasChanges!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasReviewOfSystemsFromIntake!: boolean | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	hematLymphatic!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	immunological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	musculoskeletal!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	neurological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	psychological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	respiratory!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionDiffResponse })
	skin!: FieldValueMultiSelectionDiffResponse | null;

}
