// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FinanceChargePlanPeriodResponse } from './finance-charge-plan-period-response';

/** See com.rev360.pms.api.controller.admin.accounting.FinanceChargePlanResponse */
/* istanbul ignore next */
@GandalfModel
export class FinanceChargePlanResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	minimumBalanceUsdDue!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(FinanceChargePlanPeriodResponse)
	periods!: FinanceChargePlanPeriodResponse[] | null;

	@GandalfConstantDecorator(constants.FinanceChargePlanStatus)
	@GandalfProperty({ designType: constants.FinanceChargePlanStatus })
	status!: constants.FinanceChargePlanStatus | null;

}
