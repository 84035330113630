// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.reporting.accounting.ReceiptsReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ReceiptsReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	amount!: number | null;

	@GandalfConstantDecorator(constants.CreditCardType)
	@GandalfProperty({ designType: constants.CreditCardType })
	cardType!: constants.CreditCardType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	groupPaymentDate!: Date | null;

	@GandalfProperty({ designType: String })
	groupReferenceNum!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	method!: constants.PaymentMethodType | null;

	@GandalfProperty({ designType: String })
	methodDetail!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfProperty({ designType: Number })
	payerId!: number | null;

	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfProperty({ designType: Number })
	paymentGroupId!: number | null;

	@GandalfProperty({ designType: Number })
	paymentId!: number | null;

	@GandalfProperty({ designType: PracticeLocationSummaryResponse })
	practiceLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty({ designType: PersonNameResponse })
	provider!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	quantity!: number | null;

}
