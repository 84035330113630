// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.ConnectMessageForWaitingListRequest */
/* istanbul ignore next */
@GandalfModel
export class ConnectMessageForWaitingListRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ConnectMessageMethod)
	@GandalfLabel('Method')
	@GandalfValidator({ notNull: { message: 'Method is required' } })
	@GandalfProperty({ designType: constants.ConnectMessageMethod, isRequired: true })
	connectMessageMethod!: constants.ConnectMessageMethod;

	@GandalfLabel('Waiting List')
	@GandalfValidator({ notNull: { message: 'Waiting List is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	waitingListId!: number;

}
