import { inject, Injectable } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { ConfirmDialogResult, DialogUtil } from 'morgana';

@Injectable({
	providedIn: 'root',
})
export class PatientHealthInformationService {

	readonly phiAcknowledgementFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ADMIN.GENERAL.PHI_ACKNOWLEDGEMENT));

	constructor() {
	}

	confirmEnablePhiShare() {
		return DialogUtil.confirmAsPromise({
			title: 'PHI Access Acknowledgement',
			content: '<p>By enabling this integration, you authorize the transfer of patient protected health information (PHI) to an external party. ' +
				'Whether or not credentials are directly shared, you are responsible for ensuring that the recipient complies with HIPAA and other applicable laws. ' +
				'Obtain any necessary patient consent and be aware that any sharing or misuse of PHI outside of the designated integration partner is your responsibility.</p>' +
				'<p>Would you like to enable this vendor?</p>',
		});
	}

	async isPhiShareAccepted() {
		if (!this.phiAcknowledgementFeatureOn) {
			return true;
		}
		const modalResult = await this.confirmEnablePhiShare();

		return modalResult === ConfirmDialogResult.YES;
	}
}
