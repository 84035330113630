// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.PatientFileListResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientFileListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	createdByUsername!: string | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: Date })
	fileDate!: Date | null;

	@GandalfProperty({ designType: String })
	fileDescription!: string | null;

	@GandalfProperty({ designType: Number })
	fileId!: number | null;

	@GandalfProperty({ designType: String })
	fileName!: string | null;

	@GandalfProperty({ designType: Number })
	fileSize!: number | null;

	@GandalfProperty({ designType: String })
	folderId!: string | null;

	@GandalfProperty({ designType: String })
	mimeType!: string | null;

	@GandalfProperty({ designType: Number })
	patientFileId!: number | null;

}
