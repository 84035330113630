// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.item.CreateItemQuantityChangeRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateItemQuantityChangeRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	changeReasonId!: number | null;

	@GandalfConstantDecorator(constants.QuantityChangeType)
	@GandalfValidator({ notNull: { message: 'Change type is required' } })
	@GandalfProperty({ designType: constants.QuantityChangeType, isRequired: true })
	changeType!: constants.QuantityChangeType;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfValidator({ notNull: { message: 'Practice location item is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	locationProductItemId!: number;

	@GandalfValidator({ notNull: { message: 'Practice location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceLocationId!: number;

	@GandalfValidator({ notNull: { message: '# Adjusted is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	quantityChange!: number;

}
