// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DailyOfficeHoursAdminResponse } from './daily-office-hours-admin-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationOfficeHoursResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationOfficeHoursResponse extends GandalfModelBase {

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	friday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	monday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	saturday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	sunday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	thursday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	tuesday!: DailyOfficeHoursAdminResponse | null;

	@GandalfProperty({ designType: DailyOfficeHoursAdminResponse })
	wednesday!: DailyOfficeHoursAdminResponse | null;

}
