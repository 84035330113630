// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.messaging.SearchMessageParticipantPersonsRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchMessageParticipantPersonsRequest extends GandalfModelBase {

	@GandalfLabel('Folder')
	@GandalfValidator({ notNull: { message: 'Folder is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	messageFolderId!: number;

	@GandalfConstantDecorator(constants.MessageParticipantStatus)
	@GandalfLabel('Message Participant Status')
	@GandalfProperty({ designType: constants.MessageParticipantStatus })
	messageParticipantStatus!: constants.MessageParticipantStatus | null;

	@GandalfConstantDecorator(constants.MessagePriority)
	@GandalfLabel('Message Priority')
	@GandalfProperty({ designType: constants.MessagePriority })
	messagePriority!: constants.MessagePriority | null;

	@GandalfLabel('Page Number')
	@GandalfValidator({ notNull: { message: 'Page Number is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Number must be at least 1' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	pageNumber!: number;

	@GandalfLabel('Page Size')
	@GandalfValidator({ notNull: { message: 'Page Size is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Size must be between 1 and 1000' } })
	@GandalfValidator({ max: { max: 1000, message: 'Page Size must be between 1 and 1000' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	pageSize!: number;

	@GandalfConstantDecorator(constants.MessageParticipantSortOption)
	@GandalfLabel('Sort Option')
	@GandalfValidator({ notNull: { message: 'Sort Option is required' } })
	@GandalfProperty({ designType: constants.MessageParticipantSortOption, isRequired: true })
	sortOption!: constants.MessageParticipantSortOption;

	@GandalfConstantDecorator(constants.SortOrder)
	@GandalfLabel('Sort Order')
	@GandalfValidator({ notNull: { message: 'Sort Order is required' } })
	@GandalfProperty({ designType: constants.SortOrder, isRequired: true })
	sortOrder!: constants.SortOrder;

	@GandalfLabel('Starred')
	@GandalfProperty({ designType: Boolean })
	starred!: boolean | null;

}
