// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodRecallResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodRecallResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	encounterTemplateName!: string | null;

	@GandalfProperty({ designType: Number })
	patientRecallId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	recallDate!: Date | null;

}
