// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IntakeAllergyListResponse } from './intake-allergy-list-response';

/** See com.rev360.pms.api.controller.person.IntakeAllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeAllergyResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateReported!: Date | null;

	@GandalfArray(IntakeAllergyListResponse)
	intakeAllergies!: IntakeAllergyListResponse[] | null;

	@GandalfProperty({ designType: Number })
	intakeId!: number | null;

	@GandalfProperty({ designType: Boolean })
	noKnownDrugAllergies!: boolean | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noKnownDrugAllergiesReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfProperty({ designType: Boolean })
	noLatexSensitivity!: boolean | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noLatexSensitivityReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfProperty({ designType: Boolean })
	noOtherKnownAllergies!: boolean | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty({ designType: constants.IntakeReconciledStatus })
	noOtherKnownAllergiesReconciledStatus!: constants.IntakeReconciledStatus | null;

}
