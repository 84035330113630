// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextMessageSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextMessageSummaryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	connectConversationMessageId!: number;

	@GandalfProperty({ designType: String })
	content!: string;

	@GandalfProperty({ designType: Date })
	date!: Date;

	@GandalfProperty({ designType: PersonNameResponse })
	personName!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	personPhotoId!: number | null;

	@GandalfConstantDecorator(constants.ConnectConversationSenderType)
	@GandalfProperty({ designType: constants.ConnectConversationSenderType })
	senderType!: constants.ConnectConversationSenderType;

	@GandalfConstantDecorator(constants.ConnectConversationMessageStatus)
	@GandalfProperty({ designType: constants.ConnectConversationMessageStatus })
	status!: constants.ConnectConversationMessageStatus;

}
