// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateEducationRequest } from './create-care-plan-item-template-education-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.UpdateCarePlanItemTemplateEducationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCarePlanItemTemplateEducationRequest extends CreateCarePlanItemTemplateEducationRequest {

	@GandalfValidator({ notNull: { message: 'Education is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	carePlanItemTemplateId!: number;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ notNull: { message: 'Include in PHR is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	includeInPhr!: boolean;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	isCheckoutTask!: boolean;

	@GandalfProperty({ designType: Number })
	practiceFileId!: number | null;

	@GandalfConstantDecorator(constants.CarePlanItemEducationSource)
	@GandalfProperty({ designType: constants.CarePlanItemEducationSource })
	source!: constants.CarePlanItemEducationSource | null;

	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
