import { Validators } from '@angular/forms';
import { moneyConstraintsValidator } from '../forms/validators/money-constraints.validator';
import { validatePhoneNumber } from '../forms/validators/phone-number.validator';
import { requiredValidator } from '../forms/validators/required.validator';
import { validateSin } from '../forms/validators/sin.validator';
import { sizeArrayValidator } from '../forms/validators/sizeArray.validator';
import { validateSocialSecurityNumber } from '../forms/validators/social-security-number.validator';
import { getMetadataPropertyValue, setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key used to store a property's validation data structure
 */
export const GANDALF_VALIDATOR_METADATA_KEY = Symbol('GandalfValidator');

export interface ValidatorDefinition {
	[name: string]: any;
}

export interface PropertyValidators {
	validatorList: Validators[];
	messages: {
		[name: string]: string;
	};
}

/**
 * Function to convert validator messages with replacement tokens into standard strings
 * @param validator - The validator containing a message field to convert.
 */
const formatValidatorMessage = (validator: { message: string }) =>
	// Note: Only want to match {} not ${} as these are special expression that are not handled here.
	validator.message.replace(/(?:^|[^$]){([a-z0-9]+)}/gi, (substring, $1) => substring.replace(/{([a-zA-Z0-9]+)}/, validator[$1]))
;

/**
 * Regex to match valid US or Canadian postal codes.
 *
 * US zip codes are of the format: 5 digits, or 9 digits, or 5 digits hyphen 4 digits
 *
 * CA postal codes are of the format: a group of letter/digit/letter,
 * space, and a group of digit/letter/digit
 */
export const POSTAL_CODE_PATTERN = /(^$|^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
export const DIGITS_ONLY_PATTERN = /^$|^[0-9]+$/;
export const ALPHANUMERIC_PATTERN = /^$|^[A-Za-z0-9]+$/;

/**
 * Decorator to indicate the Gandalf validators that are applied to a Gandalf Model property.
 *
 * The validators are represented in a Gandalf-specific format in the decorator, and then are converted into Angular validator functions.
 *
 * The Angular validators are stored in Gandalf metadata and can be accessed via:
 * modelInstance.$validators.propertyName
 */
export function GandalfValidator(validatorDefinition: ValidatorDefinition) {
	return (target: object, propertyKey: string) => {
		// Validators and messages to be applied based on this GandalfValidator instance
		const newValidators: PropertyValidators = {
			validatorList: [],
			messages: {},
		};

		/* istanbul ignore else */
		if (validatorDefinition !== undefined) {
			const validatorKeys = Object.keys(validatorDefinition);

			// Convert the Gandalf validator representation into Angular validators and messages
			switch (validatorKeys[0]) {
				case 'notNull': // Required validator
					newValidators.messages.required = formatValidatorMessage(validatorDefinition.notNull);
					newValidators.validatorList.push(requiredValidator);
					break;

				case 'sizeString': // Min/Max string length validators
					// size annotation uses min/max while modelconstraint and searching string use minLength/maxLength
					newValidators.messages.minlength = formatValidatorMessage(validatorDefinition.sizeString);
					newValidators.messages.maxlength = formatValidatorMessage(validatorDefinition.sizeString);
					newValidators.validatorList.push(Validators.minLength(validatorDefinition.sizeString.minLength || validatorDefinition.sizeString.min));
					newValidators.validatorList.push(Validators.maxLength(validatorDefinition.sizeString.maxLength || validatorDefinition.sizeString.max));
					break;

				case 'sizeArray': // Min/Max array size validator
					newValidators.messages.sizeArray = formatValidatorMessage(validatorDefinition.sizeArray);
					newValidators.validatorList.push(sizeArrayValidator(validatorDefinition.sizeArray.min, validatorDefinition.sizeArray.max));
					break;

				case 'email': // email validator
					newValidators.messages.email = formatValidatorMessage(validatorDefinition.email);
					newValidators.validatorList.push(Validators.email);
					break;

				case 'min': // Min numeric validator
					newValidators.messages.min = formatValidatorMessage(validatorDefinition.min);
					newValidators.validatorList.push(Validators.min(validatorDefinition.min.min));
					break;

				case 'max': // Max numeric validator
					newValidators.messages.max = formatValidatorMessage(validatorDefinition.max);
					newValidators.validatorList.push(Validators.max(validatorDefinition.max.max));
					break;

				case 'postalCode': // Postal Code validator
					newValidators.messages.pattern = formatValidatorMessage(validatorDefinition.postalCode);
					newValidators.validatorList.push(Validators.pattern(POSTAL_CODE_PATTERN));
					break;

				case 'phoneNumber': // Phone number validator
					newValidators.messages.phoneNumber = formatValidatorMessage(validatorDefinition.phoneNumber);
					newValidators.validatorList.push(validatePhoneNumber);
					break;

				case 'socialSecurityNumber': // Social Security Number validator
					newValidators.messages.socialSecurityNumber = formatValidatorMessage(validatorDefinition.socialSecurityNumber);
					newValidators.validatorList.push(validateSocialSecurityNumber);
					break;

				case 'sin': // SIN/PHN validator
					newValidators.messages.sin = formatValidatorMessage(validatorDefinition.sin);
					newValidators.validatorList.push(validateSin);
					break;

				case 'digitsOnly': // Digits only validator
					newValidators.messages.pattern = formatValidatorMessage(validatorDefinition.digitsOnly);
					newValidators.validatorList.push(Validators.pattern(DIGITS_ONLY_PATTERN));
					break;

				case 'alphanumeric': // Alphanumeric validator
					newValidators.messages.pattern = formatValidatorMessage(validatorDefinition.alphanumeric);
					newValidators.validatorList.push(Validators.pattern(ALPHANUMERIC_PATTERN));
					break;

				case 'moneyConstraints': // Money constraints validator
					newValidators.messages.moneyConstraints = formatValidatorMessage(validatorDefinition.moneyConstraints);
					newValidators.validatorList.push(moneyConstraintsValidator(
						validatorDefinition.moneyConstraints.positiveAllowed,
						validatorDefinition.moneyConstraints.zeroAllowed,
						validatorDefinition.moneyConstraints.negativeAllowed,
					));
					break;

				default:
					throw new Error('GandalfValidator was not passed a known validator: ' + validatorKeys[0]);
			}
		} else {
			throw new Error('GandalfValidator was not passed a valid definition');
		}

		// Merge the new validators with any existing validator metadata
		let existingValidators: PropertyValidators = getMetadataPropertyValue(target, GANDALF_VALIDATOR_METADATA_KEY, propertyKey);
		if (!existingValidators) {
			existingValidators = newValidators;
		} else {
			existingValidators = {...existingValidators};
			existingValidators.messages = {...existingValidators.messages, ...newValidators.messages};
			existingValidators.validatorList = existingValidators.validatorList.concat(newValidators.validatorList);
		}
		setMetadataPropertyValue(target, GANDALF_VALIDATOR_METADATA_KEY, propertyKey, existingValidators);
	};
}
