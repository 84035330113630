// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.pharmacy.CreatePharmacyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePharmacyRequest extends GandalfModelBase {

	@GandalfLabel('Address line 1')
	@GandalfValidator({ sizeString: { message: 'Address line 1 must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'Address line 1 is required' } })
	@GandalfProperty({ designType: String, isRequired: true })
	addressLine1!: string;

	@GandalfLabel('Address line 2')
	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	addressLine2!: string | null;

	@GandalfLabel('City')
	@GandalfValidator({ sizeString: { message: 'City must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'City is required' } })
	@GandalfProperty({ designType: String, isRequired: true })
	city!: string;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfLabel('Fax')
	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	faxNumber!: string | null;

	@GandalfLabel('Location name')
	@GandalfValidator({ sizeString: { message: 'Location name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfLabel('Phone')
	@GandalfValidator({ sizeString: { message: 'Phone must be between 1 and 20 characters', minLength: 1, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone must contain at least 10 digits' } })
	@GandalfValidator({ notNull: { message: 'Phone is required' } })
	@GandalfProperty({ designType: String, isRequired: true })
	mainPhone!: string;

	@GandalfLabel('NCPDP')
	@GandalfValidator({ sizeString: { message: 'NCPDP cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty({ designType: String })
	ncpdp!: string | null;

	@GandalfLabel('Zip code')
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty({ designType: String })
	nonStandardPostalCode!: string | null;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: Number })
	pharmacyBrandId!: number | null;

	@GandalfLabel('Pharmacy brand name')
	@GandalfValidator({ sizeString: { message: 'Pharmacy brand name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'Pharmacy brand name is required' } })
	@GandalfProperty({ designType: String, isRequired: true })
	pharmacyBrandName!: string;

	@GandalfLabel('Zip code')
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty({ designType: String })
	postalCode!: string | null;

	@GandalfLabel('State/Province')
	@GandalfValidator({ sizeString: { message: 'State/Province must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'State/Province is required' } })
	@GandalfProperty({ designType: String, isRequired: true })
	stateProvince!: string;

	@GandalfLabel('Web Site')
	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
