// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	authorizationDate!: Date | null;

	@GandalfProperty({ designType: String })
	authorizationNumber!: string | null;

	@GandalfProperty({ designType: Number })
	coInsurancePercentage!: number | null;

	@GandalfProperty({ designType: Number })
	companyId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	contactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	contactLensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	contactLensFittingBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	contactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	deductible!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyDeductible!: number | null;

	@GandalfProperty({ designType: Number })
	feeScheduleId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	frameBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	generalCoPay!: number | null;

	@GandalfProperty({ designType: String })
	groupNumber!: string | null;

	@GandalfProperty({ designType: Boolean })
	hasContactLensBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasContactLensFittingBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasFrameBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasLensBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasRoutineExamBenefit!: boolean | null;

	@GandalfProperty({ designType: Number })
	insuranceTypeId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	maxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	medicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	minFrameAllowance!: number | null;

	@GandalfProperty({ designType: String })
	patientBenefits!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: Number })
	personInsuranceId!: number | null;

	@GandalfProperty({ designType: String })
	planName!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyContactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyContactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyGeneralCoPay!: number | null;

	@GandalfProperty({ designType: String })
	policyHolderBenefits!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	policyHolderDob!: Date | null;

	@GandalfProperty({ designType: PersonNameResponse })
	policyHolderName!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	policyHolderPersonId!: number | null;

	@GandalfConstantDecorator(constants.PolicyHolderRelationship)
	@GandalfProperty({ designType: constants.PolicyHolderRelationship })
	policyHolderRelationship!: constants.PolicyHolderRelationship | null;

	@GandalfProperty({ designType: Number })
	policyId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyMaterialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyMaxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyMedicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyMinFrameAllowance!: number | null;

	@GandalfProperty({ designType: String })
	policyNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	policyRoutineExamCoPay!: number | null;

	@GandalfProperty({ designType: String })
	policyRxBinNumber!: string | null;

	@GandalfProperty({ designType: String })
	policyRxPcnNumber!: string | null;

	@GandalfProperty({ designType: Number })
	priorityId!: number | null;

	@GandalfProperty({ designType: Boolean })
	referralRequired!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	routineExamBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	routineExamCoPay!: number | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty({ designType: constants.PersonInsuranceStatus })
	status!: constants.PersonInsuranceStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	termedDate!: Date | null;

}
