// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.feature.UpdateFeatureFlagEmployeeRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateFeatureFlagEmployeeRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.FeatureFlagAccess)
	@GandalfValidator({ notNull: { message: 'Access must not be null' } })
	@GandalfProperty({ designType: constants.FeatureFlagAccess, isRequired: true })
	access!: constants.FeatureFlagAccess;

	@GandalfValidator({ notNull: { message: 'Feature Flag Id must not be null' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	featureFlagEmployeeId!: number;

}
