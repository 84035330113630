// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.externaldata.ExternalDataResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalDataResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	externalDataId!: number | null;

	@GandalfConstantDecorator(constants.ExternalDataSource)
	@GandalfProperty({ designType: constants.ExternalDataSource })
	externalDataSource!: constants.ExternalDataSource | null;

	@GandalfProperty({ designType: String })
	externalDataSourceText!: string | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	requestDate!: Date | null;

	@GandalfConstantDecorator(constants.ExternalDataStatus)
	@GandalfProperty({ designType: constants.ExternalDataStatus })
	status!: constants.ExternalDataStatus | null;

	@GandalfProperty({ designType: Date })
	statusChangeDate!: Date | null;

}
