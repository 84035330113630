// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.LocationProductForOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductForOrderResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	brandName!: string | null;

	@GandalfProperty({ designType: String })
	collectionName!: string | null;

	@GandalfConstantDecorator(constants.ContactLensType)
	@GandalfProperty({ designType: constants.ContactLensType })
	contactLensType!: constants.ContactLensType | null;

	@GandalfProperty({ designType: Number })
	eyeGlassLensTypeId!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	invoiceDescription!: string | null;

	@GandalfProperty({ designType: String })
	manufacturerName!: string | null;

	@GandalfConstantDecorator(constants.MasterProductCategory)
	@GandalfProperty({ designType: constants.MasterProductCategory })
	masterProductCategory!: constants.MasterProductCategory | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	price!: number | null;

	@GandalfProperty({ designType: String })
	productCode!: string | null;

	@GandalfProperty({ designType: Number })
	productId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	wholesalePrice!: number | null;

}
