// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.ScheduleItemPrintResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleItemPrintResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	encounterTemplateName!: string | null;

	@GandalfArray(String)
	encounterTemplateNames!: string[] | null;

	@GandalfProperty({ designType: String })
	encounterTemplateSubCategory!: string | null;

	@GandalfProperty({ designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	patientDob!: Date | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	patientPreferredPhone!: string | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfProperty({ designType: constants.PreferredPhoneType })
	patientPreferredPhoneType!: constants.PreferredPhoneType | null;

	@GandalfProperty({ designType: Number })
	relatedScheduleItemId!: number | null;

	@GandalfProperty({ designType: String })
	roleName!: string | null;

	@GandalfProperty({ designType: Number })
	scheduleItemId!: number | null;

	@GandalfProperty({ designType: PersonNameResponse })
	scheduleItemPerson!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	scheduleItemPersonCredentials!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty({ designType: constants.ScheduleItemType })
	scheduleItemType!: constants.ScheduleItemType | null;

	@GandalfProperty({ designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ designType: String })
	summary!: string | null;

}
