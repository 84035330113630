// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.diagnosis.CreatePersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfValidator({ notNull: { message: 'Diagnosis Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	diagnosisDate!: Date;

	@GandalfConstantDecorator(constants.DiagnosisLocation)
	@GandalfProperty({ designType: constants.DiagnosisLocation })
	diagnosisLocation!: constants.DiagnosisLocation | null;

	@GandalfConstantDecorator(constants.DiagnosisLocationQualifier)
	@GandalfProperty({ designType: constants.DiagnosisLocationQualifier })
	diagnosisLocationQualifier!: constants.DiagnosisLocationQualifier | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceDiagnosisId!: number;

	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	reason!: string | null;

}
