// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.SearchFeeScheduleItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchFeeScheduleItemsRequest extends GandalfModelBase {

	@GandalfLabel('Fee Schedule')
	@GandalfProperty({ designType: Number })
	feeScheduleId!: number;

	@GandalfLabel('Fee Schedule Range')
	@GandalfProperty({ designType: Number })
	feeScheduleRangeId!: number;

	@GandalfConstantDecorator(constants.FeeType)
	@GandalfLabel('Fee Type')
	@GandalfProperty({ designType: constants.FeeType })
	feeType!: constants.FeeType;

	@GandalfLabel('Practice Location')
	@GandalfProperty({ designType: Number })
	practiceLocationId!: number;

	@GandalfLabel('Product Category')
	@GandalfProperty({ designType: Number })
	productCategoryId!: number | null;

}
