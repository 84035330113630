// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpResponse extends ContactLensEyeResponse {

	@GandalfProperty({ designType: Number })
	addDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	addPower!: number | null;

	@GandalfProperty({ designType: Number })
	axis!: number | null;

	@GandalfProperty({ designType: Number })
	backOpticalDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurve!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurve2!: number | null;

	@GandalfConstantDecorator(constants.BaseCurveUnit)
	@GandalfProperty({ designType: constants.BaseCurveUnit })
	baseCurveUnit!: constants.BaseCurveUnit | null;

	@GandalfProperty({ designType: Number })
	centerThickness!: number | null;

	@GandalfProperty({ designType: Number })
	cylinder!: number | null;

	@GandalfProperty({ designType: Number })
	diameter!: number | null;

	@GandalfProperty({ designType: Number })
	distanceZone!: number | null;

	@GandalfProperty({ designType: Boolean })
	dot!: boolean | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	edgeLift!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	edgeLiftAmount!: number | null;

	@GandalfProperty({ designType: Boolean })
	fenestration!: boolean | null;

	@GandalfProperty({ designType: Number })
	firstCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	firstCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	fourthCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	fourthCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	intermediateSegment!: number | null;

	@GandalfProperty({ designType: Boolean })
	isMonovision!: boolean | null;

	@GandalfProperty({ designType: Number })
	landingZoneAngle!: number | null;

	@GandalfProperty({ designType: ReferenceDataResponse })
	lensMaterial!: ReferenceDataResponse | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	limbalClearance!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

	@GandalfProperty({ designType: String })
	locationProductName!: string | null;

	@GandalfProperty({ designType: String })
	notes!: string | null;

	@GandalfProperty({ designType: Number })
	opticZone!: number | null;

	@GandalfProperty({ designType: DurationResponse })
	overnightSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: Number })
	peripheralCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	peripheralCurveWidth!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	peripheralEdge!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	plasmaTreatment!: boolean | null;

	@GandalfProperty({ designType: Number })
	productColorId!: number | null;

	@GandalfProperty({ designType: String })
	productColorName!: string | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	profile!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: DurationResponse })
	replacementSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: Number })
	returnZoneDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	sagittalDepth!: number | null;

	@GandalfProperty({ designType: Number })
	secondCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	secondCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	secondaryCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	secondaryCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	segmentHeight!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	specialCharacteristics!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	sphere!: number | null;

	@GandalfProperty({ designType: Number })
	sphere2!: number | null;

	@GandalfProperty({ designType: Number })
	thirdCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	thirdCurveWidth!: number | null;

	@GandalfProperty({ designType: String })
	tint!: string | null;

	@GandalfProperty({ designType: Number })
	truncation!: number | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
