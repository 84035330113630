// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsEyeglassResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	arCoating!: boolean | null;

	@GandalfProperty({ designType: String })
	arCoatingComment!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType })
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ designType: PersonNameResponse })
	authorizedPrescriber!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	distancePd!: number | null;

	@GandalfProperty({ designType: Number })
	eyeglassPrescriptionId!: number | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty({ designType: constants.PrescriptionCurrentStatus })
	eyeglassPrescriptionStatus!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	lensType!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	lensTypeComment!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	material!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	materialComment!: string | null;

	@GandalfProperty({ designType: Number })
	nearPd!: number | null;

	@GandalfProperty({ designType: Number })
	odAxis!: number | null;

	@GandalfProperty({ designType: Number })
	odCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	odHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	odHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	odNearAdd!: number | null;

	@GandalfProperty({ designType: Boolean })
	odPrescriptionBalance!: boolean | null;

	@GandalfProperty({ designType: Number })
	odSphere!: number | null;

	@GandalfProperty({ designType: Number })
	odVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	odVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osAxis!: number | null;

	@GandalfProperty({ designType: Number })
	osCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	osHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	osHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osNearAdd!: number | null;

	@GandalfProperty({ designType: Boolean })
	osPrescriptionBalance!: boolean | null;

	@GandalfProperty({ designType: Number })
	osSphere!: number | null;

	@GandalfProperty({ designType: Number })
	osVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	osVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ designType: Boolean })
	photochromic!: boolean | null;

	@GandalfProperty({ designType: String })
	photochromicComment!: string | null;

	@GandalfProperty({ designType: Boolean })
	polarized!: boolean | null;

	@GandalfProperty({ designType: String })
	polarizedComment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	prescriptionExpireDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	prescriptionStartDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty({ designType: constants.PrescriptionStatus })
	prescriptionStatus!: constants.PrescriptionStatus | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	prescriptionUses!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	tint!: ReferenceDataSummaryResponse | null;

	@GandalfProperty({ designType: String })
	tintComment!: string | null;

	@GandalfProperty({ designType: Boolean })
	uvTreatment!: boolean | null;

	@GandalfProperty({ designType: String })
	uvTreatmentComment!: string | null;

}
