// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldDefinitionResponse } from './field-definition-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FieldDefinitionReviewOfSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class FieldDefinitionReviewOfSystemsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: FieldDefinitionResponse })
	cardiovascularFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	constitutionalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	endocrineFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	entFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	gastrointestinalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	genitourinaryFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	hematLymphaticFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	immunologicalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	musculoSkeletalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	neuroFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	psychologicalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	respiratoryFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty({ designType: FieldDefinitionResponse })
	skinFieldDefinition!: FieldDefinitionResponse | null;

}
