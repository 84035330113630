// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { EyefinityOrderItemRequest } from './eyefinity-order-item-request';

// @ts-ignore
import { UpdateEyefinityOrderRequest } from './update-eyefinity-order-request';

/** See com.rev360.pms.api.controller.order.eyefinity.UpdateEyefinityFrameOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyefinityFrameOrderRequest extends UpdateEyefinityOrderRequest {

	@GandalfLabel('Associated Authorization')
	@GandalfValidator({ sizeString: { message: 'Associated Authorization cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	associatedAuthorization!: string | null;

	@GandalfLabel('Contact Lens Benefit')
	@GandalfProperty({ designType: Boolean })
	benefitContactLens!: boolean | null;

	@GandalfLabel('Exam Benefit')
	@GandalfProperty({ designType: Boolean })
	benefitExam!: boolean | null;

	@GandalfLabel('Frame Benefit')
	@GandalfProperty({ designType: Boolean })
	benefitFrame!: boolean | null;

	@GandalfLabel('Lens Benefit')
	@GandalfProperty({ designType: Boolean })
	benefitLens!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfLabel('Exam Copay')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	copayExam!: number | null;

	@GandalfLabel('Materials Copay')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	copayMaterials!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedDiscounts!: number | null;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	estimatedInsuranceBenefit!: number | null;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	externalSupplierIdentifier!: string | null;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	externalSupplierName!: string | null;

	@GandalfLabel('Fee Schedule')
	@GandalfProperty({ designType: Number })
	eyefinityFeeScheduleId!: number | null;

	@GandalfLabel('Order Items')
	@GandalfArray(EyefinityOrderItemRequest)
	eyefinityOrderItems!: EyefinityOrderItemRequest[] | null;

	@GandalfLabel('Allowance')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameAllowance!: number | null;

	@GandalfLabel('Frame Color')
	@GandalfProperty({ designType: Number })
	frameColorId!: number | null;

	@GandalfLabel('Frame Cost')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameCost!: number | null;

	@GandalfLabel('Frame Product')
	@GandalfProperty({ designType: Number })
	frameId!: number | null;

	@GandalfLabel('Frame Product Item')
	@GandalfProperty({ designType: Number })
	frameItemId!: number | null;

	@GandalfLabel('Frame Remake Cost')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameRemakeCost!: number | null;

	@GandalfLabel('Frame Size')
	@GandalfProperty({ designType: Number })
	frameSizeId!: number | null;

	@GandalfLabel('Wholesale Allowance')
	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameWholesaleAllowance!: number | null;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfLabel('Is Valid')
	@GandalfProperty({ designType: Boolean })
	isValid!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	locationId!: number;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	notificationDate!: Date | null;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfLabel('Frame Source')
	@GandalfProperty({ designType: constants.OrderFrameSource })
	orderFrameSource!: constants.OrderFrameSource | null;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	orderId!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	otherRemakeCost!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	patientNotified!: boolean;

	@GandalfProperty({ designType: Number })
	processorId!: number | null;

	@GandalfLabel('Dispense')
	@GandalfValidator({ notNull: { message: 'Dispense is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	quantity!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	shipRemakeCost!: number | null;

	@GandalfProperty({ designType: AddressOptionalRequest })
	shipToOtherAddress!: AddressOptionalRequest | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ designType: constants.OrderShipToType, isRequired: true })
	shipToType!: constants.OrderShipToType;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty({ designType: constants.OrderShipmentCompany })
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	trackingNumber!: string | null;

	@GandalfLabel('Tray')
	@GandalfValidator({ sizeString: { message: 'Tray cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	tray!: string | null;

	@GandalfProperty({ designType: Number })
	vendorId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	vendorOrderIdentifier!: string | null;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	version!: number;

}
