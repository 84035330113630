// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.PracticeTemplateSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeTemplateSummaryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.TemplateFormat)
	@GandalfProperty({ designType: constants.TemplateFormat })
	format!: constants.TemplateFormat | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfConstantDecorator(constants.PracticeTemplateStatus)
	@GandalfProperty({ designType: constants.PracticeTemplateStatus })
	status!: constants.PracticeTemplateStatus | null;

	@GandalfProperty({ designType: Number })
	templateId!: number | null;

}
