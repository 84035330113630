// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateRecurPatternDailyRequest } from './create-recur-pattern-daily-request';

// @ts-ignore
import { CreateRecurPatternMonthlyRequest } from './create-recur-pattern-monthly-request';

// @ts-ignore
import { CreateRecurPatternWeeklyRequest } from './create-recur-pattern-weekly-request';

// @ts-ignore
import { CreateRecurPatternYearlyRequest } from './create-recur-pattern-yearly-request';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurringDefinitionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurringDefinitionRequest extends GandalfModelBase {

	@GandalfProperty({ designType: CreateRecurPatternDailyRequest })
	dailyRecurPattern!: CreateRecurPatternDailyRequest | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfConstantDecorator(constants.RecurEndType)
	@GandalfValidator({ notNull: { message: 'End Type is required' } })
	@GandalfProperty({ designType: constants.RecurEndType, isRequired: true })
	endType!: constants.RecurEndType;

	@GandalfProperty({ designType: CreateRecurPatternMonthlyRequest })
	monthlyRecurPattern!: CreateRecurPatternMonthlyRequest | null;

	@GandalfValidator({ max: { max: 100, message: 'Number of occurrences cannot exceed 100' } })
	@GandalfValidator({ min: { min: 0, message: 'Number of occurrences must be positive' } })
	@GandalfProperty({ designType: Number })
	numRecurOccurrences!: number | null;

	@GandalfConstantDecorator(constants.RecurPatternType)
	@GandalfProperty({ designType: constants.RecurPatternType })
	recurPatternType!: constants.RecurPatternType | null;

	@GandalfValidator({ notNull: { message: 'Recurrence Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

	@GandalfProperty({ designType: CreateRecurPatternWeeklyRequest })
	weeklyRecurPattern!: CreateRecurPatternWeeklyRequest | null;

	@GandalfProperty({ designType: CreateRecurPatternYearlyRequest })
	yearlyRecurPattern!: CreateRecurPatternYearlyRequest | null;

}
