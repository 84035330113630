// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientRecallSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientRecallSearchRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOnEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOnStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterTypeId!: number | null;

	@GandalfValidator({ notNull: { message: 'End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	endDate!: Date;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfConstantDecorator(constants.RecallSearchType)
	@GandalfValidator({ notNull: { message: 'Search Type is required' } })
	@GandalfProperty({ designType: constants.RecallSearchType, isRequired: true })
	searchType!: constants.RecallSearchType;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

}
