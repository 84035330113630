// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionResponse } from './eyeglass-prescription-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderEyeglassDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderEyeglassDetailResponse extends OpticalOrderDetailResponse {

	@GandalfProperty({ designType: String })
	arCoating!: string | null;

	@GandalfProperty({ designType: String })
	arCoatingSide!: string | null;

	@GandalfProperty({ designType: Number })
	distancePd!: number | null;

	@GandalfProperty({ designType: String })
	edgeTreatment!: string | null;

	@GandalfProperty({ designType: Number })
	frameBridge!: number | null;

	@GandalfProperty({ designType: String })
	frameColor!: string | null;

	@GandalfProperty({ designType: Number })
	frameEye!: number | null;

	@GandalfProperty({ designType: String })
	frameManufacturer!: string | null;

	@GandalfProperty({ designType: String })
	frameProductName!: string | null;

	@GandalfProperty({ designType: Number })
	frameTemple!: number | null;

	@GandalfProperty({ designType: String })
	material!: string | null;

	@GandalfProperty({ designType: String })
	mounting!: string | null;

	@GandalfProperty({ designType: Number })
	nearPd!: number | null;

	@GandalfProperty({ designType: Number })
	odBaseCurve!: number | null;

	@GandalfProperty({ designType: String })
	odBifocalSize!: string | null;

	@GandalfProperty({ designType: Number })
	odBlankSize!: number | null;

	@GandalfProperty({ designType: String })
	odLensType!: string | null;

	@GandalfProperty({ designType: Number })
	odMonocularPdDistance!: number | null;

	@GandalfProperty({ designType: Number })
	odMonocularPdNear!: number | null;

	@GandalfProperty({ designType: Number })
	odOpticalCenter!: number | null;

	@GandalfProperty({ designType: String })
	odPal!: string | null;

	@GandalfProperty({ designType: String })
	odProductCode!: string | null;

	@GandalfProperty({ designType: Number })
	odSegHeight!: number | null;

	@GandalfProperty({ designType: String })
	odTrifocalSize!: string | null;

	@GandalfProperty({ designType: Number })
	odVertexDistance!: number | null;

	@GandalfProperty({ designType: Number })
	orderDetailId!: number | null;

	@GandalfProperty({ designType: Number })
	osBaseCurve!: number | null;

	@GandalfProperty({ designType: String })
	osBifocalSize!: string | null;

	@GandalfProperty({ designType: Number })
	osBlankSize!: number | null;

	@GandalfProperty({ designType: String })
	osLensType!: string | null;

	@GandalfProperty({ designType: Number })
	osMonocularPdDistance!: number | null;

	@GandalfProperty({ designType: Number })
	osMonocularPdNear!: number | null;

	@GandalfProperty({ designType: Number })
	osOpticalCenter!: number | null;

	@GandalfProperty({ designType: String })
	osPal!: string | null;

	@GandalfProperty({ designType: String })
	osProductCode!: string | null;

	@GandalfProperty({ designType: Number })
	osSegHeight!: number | null;

	@GandalfProperty({ designType: String })
	osTrifocalSize!: string | null;

	@GandalfProperty({ designType: Number })
	osVertexDistance!: number | null;

	@GandalfProperty({ designType: Number })
	pantoscopicTilt!: number | null;

	@GandalfProperty({ designType: String })
	photochromic!: string | null;

	@GandalfProperty({ designType: String })
	polarized!: string | null;

	@GandalfProperty({ designType: EyeglassPrescriptionResponse })
	prescription!: EyeglassPrescriptionResponse | null;

	@GandalfProperty({ designType: Number })
	quantity!: number | null;

	@GandalfProperty({ designType: String })
	scratchCoat!: string | null;

	@GandalfProperty({ designType: String })
	shade!: string | null;

	@GandalfProperty({ designType: String })
	specialInstructions!: string | null;

	@GandalfProperty({ designType: String })
	specialOption!: string | null;

	@GandalfProperty({ designType: String })
	thickness!: string | null;

	@GandalfProperty({ designType: String })
	tintFactor!: string | null;

	@GandalfProperty({ designType: String })
	tintType!: string | null;

	@GandalfProperty({ designType: String })
	uvTreatment!: string | null;

	@GandalfProperty({ designType: Number })
	wrapAngle!: number | null;

}
