// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDiagnosisResponse } from './invoice-item-diagnosis-response';

// @ts-ignore
import { InvoiceItemModifierResponse } from './invoice-item-modifier-response';

// @ts-ignore
import { InvoiceItemTaxResponse } from './invoice-item-tax-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceItemListResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemListResponse extends GandalfModelBase {

	@GandalfArray(InvoiceItemDiagnosisResponse)
	additionalDiagnoses!: InvoiceItemDiagnosisResponse[];

	@GandalfArray(InvoiceItemModifierResponse)
	additionalModifiers!: InvoiceItemModifierResponse[];

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	adjustmentTotal!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amountPaid!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number;

	@GandalfProperty({ designType: String })
	code!: string;

	@GandalfProperty({ designType: String })
	description!: string;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	discountTotal!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	extendedPrice!: number;

	@GandalfProperty({ designType: Number })
	invoiceItemId!: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments!: InvoiceItemAdjustmentResponse[];

	@GandalfArray(InvoiceItemTaxResponse)
	itemTaxes!: InvoiceItemTaxResponse[];

	@GandalfProperty({ designType: Number })
	originalItemId!: number | null;

	@GandalfProperty({ designType: Date })
	postedOn!: Date;

	@GandalfProperty({ designType: Number })
	quantity!: number;

	@GandalfProperty({ designType: Boolean })
	split!: boolean | null;

	@GandalfConstantDecorator(constants.InvoiceItemStatus)
	@GandalfProperty({ designType: constants.InvoiceItemStatus })
	status!: constants.InvoiceItemStatus;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	taxAmount!: number;

	@GandalfConstantDecorator(constants.InvoiceItemType)
	@GandalfProperty({ designType: constants.InvoiceItemType })
	type!: constants.InvoiceItemType;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	unitPrice!: number;

	@GandalfProperty({ designType: Boolean })
	wasSplit!: boolean | null;

}
