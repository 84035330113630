// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { PracticeFileInfoResponse } from './practice-file-info-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateEducationResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateEducationResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty({ designType: Number })
	carePlanItemTemplateId!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Boolean })
	includeInPhr!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isCheckoutTask!: boolean | null;

	@GandalfProperty({ designType: PracticeFileInfoResponse })
	practiceFile!: PracticeFileInfoResponse | null;

	@GandalfConstantDecorator(constants.CarePlanItemEducationSource)
	@GandalfProperty({ designType: constants.CarePlanItemEducationSource })
	source!: constants.CarePlanItemEducationSource | null;

	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
