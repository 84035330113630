// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.UpdatePersonGeneralHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePersonGeneralHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Insurance comments cannot be longer than 65535 characters', minLength: 0, maxLength: 65535 } })
	@GandalfProperty({ designType: String })
	insurance!: string | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

}
