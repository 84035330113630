// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextAutomatedMessageResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextAutomatedMessageResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	connectMessageId!: number;

	@GandalfProperty({ designType: String })
	content!: string | null;

	@GandalfProperty({ designType: Date })
	date!: Date;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse;

}
