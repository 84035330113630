// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.PatientPaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number;

	@GandalfConstantDecorator(constants.PaymentMethodCreditCardType)
	@GandalfProperty({ designType: constants.PaymentMethodCreditCardType })
	card!: constants.PaymentMethodCreditCardType | null;

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	paymentDate!: Date;

	@GandalfProperty({ designType: Number })
	paymentGroupId!: number | null;

	@GandalfProperty({ designType: Number })
	paymentId!: number;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfProperty({ designType: String })
	paymentPayorName!: string | null;

	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.PaymentStatus)
	@GandalfProperty({ designType: constants.PaymentStatus })
	status!: constants.PaymentStatus;

}
