// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './appointment-insurance-verification-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ScheduleItemSidebarResponse } from './schedule-item-sidebar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.sidebar.AppointmentSidebarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentSidebarResponse extends ScheduleItemSidebarResponse {

	@GandalfProperty({ designType: Date })
	actualEndDate!: Date | null;

	@GandalfProperty({ designType: Date })
	actualStartDate!: Date | null;

	@GandalfProperty({ designType: AppointmentInsuranceVerificationResponse })
	appointmentInsuranceVerificationResponse!: AppointmentInsuranceVerificationResponse | null;

	@GandalfProperty({ designType: Boolean })
	confirmationRequired!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	confirmed!: boolean | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty({ designType: constants.EncounterStatus })
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty({ designType: PracticeLocationSummaryResponse })
	locationSummary!: PracticeLocationSummaryResponse | null;

	@GandalfProperty({ designType: String })
	patientEmail!: string | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	patientEstablishedStatus!: constants.EstablishedStatus | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	patientGender!: constants.Gender | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: Number })
	patientPersonId!: number | null;

	@GandalfProperty({ designType: PhoneNumbersResponse })
	patientPhoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty({ designType: Boolean })
	patientPhrLogin!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	patientScheduled!: boolean | null;

	@GandalfProperty({ designType: RecurringDefinitionResponse })
	recurringDefinition!: RecurringDefinitionResponse | null;

	@GandalfProperty({ designType: Number })
	relatedAppointmentId!: number | null;

	@GandalfProperty({ designType: Number })
	scheduleItemId!: number | null;

	@GandalfProperty({ designType: Number })
	subTypeId!: number | null;

	@GandalfProperty({ designType: String })
	summary!: string | null;

	@GandalfProperty({ designType: EncounterTemplateResponse })
	template!: EncounterTemplateResponse | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty({ designType: constants.ScheduleItemType })
	type!: constants.ScheduleItemType | null;

}
