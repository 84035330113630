// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemPersonDiagnosisResponse } from './invoice-item-person-diagnosis-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceItemDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemDetailResponse extends GandalfModelBase {

	@GandalfArray(InvoiceItemPersonDiagnosisResponse)
	additionalDiagnoses!: InvoiceItemPersonDiagnosisResponse[];

	@GandalfProperty({ designType: Number })
	additionalModifierId0!: number | null;

	@GandalfProperty({ designType: Number })
	additionalModifierId1!: number | null;

	@GandalfProperty({ designType: Number })
	additionalModifierId2!: number | null;

	@GandalfProperty({ designType: Number })
	additionalModifierId3!: number | null;

	@GandalfProperty({ designType: String })
	claimNote!: string | null;

	@GandalfConstantDecorator(constants.ClaimNoteCode)
	@GandalfProperty({ designType: constants.ClaimNoteCode })
	claimNoteCode!: constants.ClaimNoteCode | null;

	@GandalfProperty({ designType: String })
	code!: string;

	@GandalfProperty({ designType: String })
	description!: string;

	@GandalfProperty({ designType: String })
	dosageAmount!: string | null;

	@GandalfConstantDecorator(constants.DosageUnitCode)
	@GandalfProperty({ designType: constants.DosageUnitCode })
	dosageUnit!: constants.DosageUnitCode | null;

	@GandalfProperty({ designType: String })
	emergencyService!: string | null;

	@GandalfProperty({ designType: String })
	epsdt!: string | null;

	@GandalfProperty({ designType: Number })
	externalProviderId!: number | null;

	@GandalfConstantDecorator(constants.FacilityType)
	@GandalfProperty({ designType: constants.FacilityType })
	facilityType!: constants.FacilityType | null;

	@GandalfProperty({ designType: String })
	formattedExternalProviderName!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceItemId!: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments!: InvoiceItemAdjustmentResponse[];

	@GandalfProperty({ designType: String })
	modifier!: string | null;

	@GandalfProperty({ designType: String })
	nationalDrugCode!: string | null;

	@GandalfProperty({ designType: Number })
	responsiblePersonId!: number | null;

	@GandalfProperty({ designType: String })
	rxNumber!: string | null;

	@GandalfConstantDecorator(constants.RxQualifier)
	@GandalfProperty({ designType: constants.RxQualifier })
	rxQualifier!: constants.RxQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceEndDate!: Date | null;

	@GandalfProperty({ designType: Number })
	serviceLocationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceStartDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceItemStatus)
	@GandalfProperty({ designType: constants.InvoiceItemStatus })
	status!: constants.InvoiceItemStatus;

	@GandalfConstantDecorator(constants.InvoiceItemType)
	@GandalfProperty({ designType: constants.InvoiceItemType })
	type!: constants.InvoiceItemType;

}
