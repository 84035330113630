// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.CustomerAgreementsResponse */
/* istanbul ignore next */
@GandalfModel
export class CustomerAgreementsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	baaComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	baaSignByDate!: Date | null;

	@GandalfProperty({ designType: String })
	baaSignedByFirstName!: string | null;

	@GandalfProperty({ designType: String })
	baaSignedByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	baaSignedOnDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	licenseSignByDate!: Date | null;

	@GandalfProperty({ designType: String })
	licenseSignedByFirstName!: string | null;

	@GandalfProperty({ designType: String })
	licenseSignedByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	licenseSignedOnDate!: Date | null;

}
