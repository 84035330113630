export const FEATURE_FLAGS = {
	MODULES: {
		ACCOUNTING: {
			INVOICES: 'module.accounting.invoices',
			CLAIMS: 'module.accounting.claims',
			PAYMENTS: 'module.accounting.payments',
		},
		CONNECT: 'module.connect',
		HOME: 'module.home',
		ADMIN: 'module.admin',
		INVENTORY: {
			PRODUCTS: 'module.inventory.products',
			SERVICES: 'module.inventory.services',
			TRANSFERS: 'module.inventory.transfers',
			RECONCILIATION: 'module.inventory.reconciliation',
			VSP: 'module.inventory.vsp',
		},
		REPORTING: 'module.reporting',
		MESSAGING: 'module.messaging',
		ORDERS: 'module.orders',
		PATIENTS: 'module.patients',
		TASKS: 'module.tasks',
		FOOTER: 'module.footer',
	},
	FEATURES: {
		MOVE_ADD_DROPDOWN_OPTIONS: 'QOL.move.dropdown.options.from.add.button',
		AUTOFORMAT_DATEPICKER: 'QOL.autoformat.datepicker',
		LOCATION_RX_PREFERENCES: 'REV.locations.rx.preferences',
		LOCATION_LEVEL_EXPIRATION: 'REV.locationLevelExpiration',
		ADMIN: {
			ACCOUNTING_CATEGORIES: {
				REPORTS_CATEGORIES: 'admin.menu.AccountingCategories.ReportsCategories',
			},
			EMPLOYEES_ROLES: {
				EMPLOYEES: {
					AG_GRID: 'AG.admin.employees.agGrid',
				},
			},
			EXTERNAL_CREDENTIALS: {
				RXNT_MULTIPLE_LOCATION_CREDENTIALS: 'PINT.rxnt.multiple.location.credentials',
			},
			GENERAL: {
				PATIENT_ADDRESS_NOT_REQUIRED: 'admin.menu.General.PracticePreferences.patientAddressNotRequired',
				PHI_ACKNOWLEDGEMENT: 'REV.phi.acknowledgement',
			},
			SERVICE_CATEGORIES: {
				SERVICE_CATEGORIES: 'admin.menu.ServiceCategories.ServiceCategories',
			},
			VENDORS_PARTNERS: {
				OPENEDGE: 'admin.menu.VendorsPartners.OpenEdge',
			},
			COMMUNICATION_TEMPLATES: {
				EDITOR: 'REV.admin.communication.templates.editor',
			},
		},
		REPORTING: {
			AGING_REPORT: {
				AG_GRID: 'AG.reports.aging.agGrid',
			},
			ACCOUNTING: {
				AG_GRID: {
					LEDGER_POSTING_REPORT: 'QOL.reports.accounting.ledgerPosting',
				},
			},
			DEPOSIT_SLIP: {
				AG_GRID: 'QOL.reports.depositslips.agGrid',
			},
			BIRTHDAY: {
				AG_GRID: 'AG.reports.birthday.agGrid',
			},
			PATIENT_MINER: {
				AG_GRID: 'AG.reports.miner.agGrid',
			},
			QUERY_BUILDER: {
				AG_GRID: 'QOL.reports.query.builder.table.enhancements',
			},
			RECALL: {
				AG_GRID: 'AG.reports.recall.agGrid',
			},
			SCHEDULE: {
				APPOINTMENTS_SHOW_DETAILS: 'reports.Schedule.Appointments.ShowDetails',
				AG_GRID: 'AG.reports.appointments.agGrid',
			},
		},
		ACCOUNTING: {
			INVOICE: {
				RECEIVE_PAYMENTS: {
					AG_GRID: 'AG.receivePayment.agGrid',
				},
				OPEN_EDGE_VOID_REFUND_PAYMENT: 'openEdge.voidRefundPayment',
				GLOBAL_PAYMENTS_PRINT_SALE_RECEIPT: 'PAYM.globalPayments.print.credit.card.sale.receipt',
				GLOBAL_PAYMENTS_PRINT_VOID_RECEIPT: 'PAYM.globalPayments.print.credit.card.void.receipt',
				GLOBAL_PAYMENTS_PRINT_REFUND_RECEIPT: 'PAYM.globalPayments.print.credit.card.refund.receipt',
				ON_FILE_RETURN: 'QOL.revpayments.refundSavedCard',
				PAYMENT_E_STATEMENT: 'PAYE.receivePayment.disburseInFullPayments',
			},
			PAYMENTS: {
				PAYMENTS_DASHBOARD: {
					AG_GRID: 'AG.paymentsDashboard.agGrid',
				},
				EDIT_APPLIED_PAYMENT: 'EPD.edit.applied.payment.details',
				PRINT_RECEIPTS: 'QOL.payments.print.receipts',
				PERFORMANCE: 'PERF.payments.performance',
			},
			CLAIMS: {
				BATCH_CLAIMS_UPDATE: 'QOL.batch.claims.update',
			},
			AUTO_POSTING: 'APV1.payment.revclear.autoposting',
			AUTO_POSTING_V2: {
				HUMAN_READABLE: 'APV2.autoposting.human.readable',
				ADJUSTMENT_ASSOCIATIONS: 'APV2.autoposting.x12.associations',
				TASK_ASSIGNMENT: 'APV2.autoposting.task.assignment',
				LOCATION_PAYMENT_GROUPING: 'APV2.payment.group.location',
			},
		},
		EMPLOYEES: {
			EMPLOYEE_PREFERENCES: 'QOL.rx.electronic.signature.enhancements.sql',
			EDIT_EMPLOYEE_USERNAME: 'QOL.admin.edit.employee.username',
		},
		ENCOUNTER_PULL_FORWARD: {
			DELETE_HPI: 'PFEF.deleteHpi.encounter',
			DELETE_CHECKOUT_TASKS: 'delete.encounter.checkout.task',
			ENHANCEMENTS: 'PFEF.enhancements',
		},
		ENCOUNTERS: {
			HEADER_SIGN_ENCOUNTER: 'QOL.encounter.signEncounterEnhancements',
			PAST_DIAGNOSES_AND_CARE_PLANS: {
				AG_GRID: 'AG.patientEMR.rfvPastDiagnosisCarePlans.agGrid',
			},
			CARE_PLAN_LIBRARY: {
				AG_GRID: 'AG.patientEMR.assessment.carePlan.agGrid',
			},
			CHECK_OUT: {
				TASKS: {
					AG_GRID: 'AG.patientEMR.checkout.tasks.agGrid',
				},
			},
			BILLING: {
				AG_GRID: 'AG.patientEMR.billing.agGrid',
			},
			CONTACT_LENS: {
				CL_TRIALS: {
					TRIALS: {
						AG_GRID: 'AG.patientEMR.contactLens.agGrid',
					},
				},
			},
			FHIR_API_LAUNCH: 'FHIR.api.launch',
		},
		ENCOUNTER_TIMER: {
			MAIN: 'encounterTimer.main',
			ADJUST_TIME_MODAL: 'encounterTimer.encounter.adjustTimeModal',
			START_STOP: 'encounterTimer.encounter.startStop',
			PRINTOUT: 'encounterTimer.printout',
		},
		ENCOUNTER_RE: {
			CREATE_MULTIPLE_EYEGLASS_RX: 'RE.encounter.createMultipleRx',
		},
		FILE: {
			PATIENT_FILE_WEBSERVICE: 'ARCH.file.s3uplift.patientFile',
			PHOTO: 'TD.file.uplift.photo',
		},
		ORDERS: {
			DASHBOARD: 'orders.Dashboard',
			FRAME_ONLY_ORDER: 'orders.FrameOnlyOrder',
			EYEGLASS_ORDER: 'orders.EyeglassOrder',
			CONTACT_LENS_ORDER: 'orders.ContactLensOrder',
			CONTACT_LENS_TRIAL_ORDER: 'orders.ContactLensTrialOrder',
			CONTACT_LENS_MODAL: 'CLME.contact.lens.modal.enhancements',
			SMARTFLOW_EYEGLASS_ORDER: 'orders.smartflow.EyeglassOrder',
			SMARTFLOW_CONTACT_LENS_ORDER: 'orders.smartflow.ContactLensOrder',
			SMARTFLOW_CONTACT_LENS_TRIAL_ORDER: 'orders.smartflow.ContactLensTrialOrder',
			VISION_WEB_EYEGLASS_ORDER: 'orders.visionweb.EyeglassOrder',
			VISION_WEB_CONTACT_LENS_ORDER: 'orders.visionweb.ContactLensOrder',
			VISION_WEB_CONTACT_LENS_TRIAL_ORDER: 'orders.visionweb.ContactLensTrialOrder',
			TWELVE_84: 'orders.twelve84.EyeglassOrder',
			BILLING: {
				NON_BILLABLE: {
					AG_GRID: 'AG.orders.billing.agGrid',
					VSP: 'VSPE.orders.billing.nonbillable.vsp',
				},
			},
			Medical: {
				TABLE_UPDATE: 'QOL.orders.medical.table.update',
			},
		},
		PATIENTS: {
			EMR_HPI_DX_HISTORY: {
				AG_GRID: 'AG.patientEMR.hpi.dxHistory.agGrid',
			},
			ENCOUNTER: {
				ASSESSMENT_PLAN: {
					AG_GRID: 'AG.encounterAssessments.agGrid',
				},
				CODING: {
					MASTER_DX_AG_GRID: 'QOL.coding.master.dx.table.update',
				},
			},
			RX: {
				MEDICATION: {
					AG_GRID: 'AG.patientRx.medications.agGrid',
				},
				CONTACTLENS: {
					AG_GRID: 'AG.patientRx.contactLens.agGrid',
				},
				EYEGLASS: {
					AG_GRID: 'AG.patientRx.eyeglass.agGrid',
					VIEW_HISTORY_STATUS_COLUMN: 'QOL.eyeglassRx.viewHistoryStatusColumn',
				},
			},
			ACCOUNT: {
				GLOBAL_PAYMENT_MANAGE_CARDS: 'PAYM.globalPayments.patient.account.manageCardOnFile',
				GLOBAL_PAYMENT_RECEIVE_PAYMENT_CARD_ON_FILE: 'PAYM.globalPayments.receivePayment.cardOnFile',
				PATIENT_INVOICES: {
					AG_GRID: 'AG.patientAccount.agGrid',
				},
			},
			FAMILY_MEDICAL_HX: {
				AG_GRID: 'AG.familyMedicalHistory.agGrid',
			},
			FAMILY_OCULAR_HX: {
				AG_GRID: 'AG.familyOcularHistory.agGrid',
			},
			DIAGNOSES: {
				AG_GRID: 'AG.patients.diagnosisHistory.agGrid',
				ICD9_FILTER: 'QOL.diagnosis.icd9.filter',
			},
			ONGOING_CARE: {
				COMMENTS: 'QOL.patient.ongoingCare.comments',
			},
			HEADER: {
				RECORD_SUMMARY: 'module.patients.header.recordSummary',
				COMMUNICATE: 'module.patients.header.communicate',
			},
			REFERRALS: 'module.patients.component.referrals',
			WAIT_LIST: 'UPLG.patient.waitList.enhancements',
			PHARMACY_SCREEN: {
				ACTIVE_PHARMACIES: 'QOL.pharmacy.screen.active.pharmacies.table.update',
			},
			PERFORMANCE: {
				FAMILY_CONTACTS: 'QOL.family.contacts.performance.enhancement',
			},
			AI_INTERVENTIONS: 'REV.patients.ai.interventions',
			LANGUAGE_FIELDS: 'REV.language.fields',
		},
		SCHEDULING: {
			APPOINTMENT_DETAILS: {
				INSURANCE_VERIFICATION: 'QOL.appointment.details.insurance.verification',
			},
			PRINT: {
				DAILY_PRINT_ENHANCEMENTS: 'QOL.schedule.daily.printout',
			},
			FILTER_PROVIDERS_BY_LOCATION: 'REV.filter.schedule.providers',
		},
		REVBILLING: {
			CLAIMS_DASHBOARD_ENHANCEMENTS: 'RCM.claims.dashboard.enhancements',
		},
		REVCONNECT: {
			TWO_WAY_TEXT: 'TWT.revconnect.twoWayText.messaging',
		},
		CERT23: {
			INFO_BLOCKING: 'CERT23.certification.infoblocking.23',
		},
		OPEN_EDGE: 'openEdge.receivePayment',
		ORDER_CREATION_EFFICIENCIES: {
			QUOTE: 'OCE.orders.quote',
			QUOTEDSTATUS: 'OCE.orders.quotedStatus',
			PDS_VIEW_HISTORY: 'OCE.orders.pds.viewHistory',
			RX_MONO_PD: 'OCE.rx.monoPd',
			ON_HOLD: 'OCE.orders.onHold',
		},
		VSP_ESTIMATOR: 'VSPE.Estimator.sql',
		REVCLEAR: 'QOL.revClear',
		EMERGENCY_CONTACT: 'ECON.emergency.contact.information',
		REVINTEGRATOR: 'devices.RevIntegrator',
		LIVE_CHAT_MIGRATION: 'QOL.liveChat.migration',
		REVENGAGE: {
			SSO: 'REV.revengage.sso',
			IFRAME: 'REV.revengage.iframe',
			SEPARATE_TAB: 'REV.revengage.separate.tab',
			USER_ROLES: 'REV.revengage.user.roles',
		},
	},
};
