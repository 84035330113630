// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeePreferencesResponse } from './employee-preferences-response';

// @ts-ignore
import { EmployeeRxntCredentialResponse } from './employee-rxnt-credential-response';

// @ts-ignore
import { PersonDetailsResponse } from './person-details-response';

/** See com.rev360.pms.api.controller.header.EmployeeEditProfileResponse */
/* istanbul ignore next */
@GandalfModel
export class EmployeeEditProfileResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: EmployeePreferencesResponse })
	employeePreferences!: EmployeePreferencesResponse | null;

	@GandalfProperty({ designType: EmployeeRxntCredentialResponse })
	employeeRxntCredential!: EmployeeRxntCredentialResponse | null;

	@GandalfProperty({ designType: PersonDetailsResponse })
	personDetails!: PersonDetailsResponse | null;

}
