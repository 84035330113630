// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderEyefinityFeeLensResponse } from './order-eyefinity-fee-lens-response';

// @ts-ignore
import { OrderEyefinityFeeOptionResponse } from './order-eyefinity-fee-option-response';

// @ts-ignore
import { OrderEyefinityFeeServiceResponse } from './order-eyefinity-fee-service-response';

/** See com.rev360.pms.api.controller.order.eyefinity.OrderEyefinityFeeScheduleResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderEyefinityFeeScheduleResponse extends GandalfModelBase {

	@GandalfArray(OrderEyefinityFeeLensResponse)
	baseLenses!: OrderEyefinityFeeLensResponse[] | null;

	@GandalfProperty({ designType: Number })
	contactLensExamPercentage!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	frameDispensingFee!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfArray(OrderEyefinityFeeOptionResponse)
	lensAddons!: OrderEyefinityFeeOptionResponse[] | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(OrderEyefinityFeeServiceResponse)
	services!: OrderEyefinityFeeServiceResponse[] | null;

}
