// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.patient.PatientSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientSearchResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AddressResponse })
	addressResponse!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateOfBirth!: Date | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty({ designType: constants.Gender })
	gender!: constants.Gender | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientNameResponse!: PatientNameResponse | null;

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty({ designType: constants.PatientStatus })
	patientStatus!: constants.PatientStatus | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: Number })
	photoId!: number | null;

	@GandalfProperty({ designType: String })
	preferredPhoneNumber!: string | null;

	@GandalfProperty({ designType: ProviderResponse })
	providerResponse!: ProviderResponse | null;

}
