// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterReportRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	approvalEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	approvalStartDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	approvalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: Number })
	encounterTypeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Encounter End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	endDate!: Date;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Patient', maxLength: 1000, minLength: 0, message: 'Patient must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	patientName!: string | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfValidator({ notNull: { message: 'Encounter Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

	@GandalfConstantDecorator(constants.EncounterSearchStatus)
	@GandalfProperty({ designType: constants.EncounterSearchStatus })
	status!: constants.EncounterSearchStatus | null;

	@GandalfProperty({ designType: Number })
	subTypeId!: number | null;

}
