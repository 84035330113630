// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.barcode.LocationProductItemForBarcodePrintingResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductItemForBarcodePrintingResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	calculatedRetailPrice!: number | null;

	@GandalfProperty({ designType: Number })
	frameBridge!: number | null;

	@GandalfProperty({ designType: String })
	frameColorName!: string | null;

	@GandalfProperty({ designType: Number })
	frameEye!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	itemSku!: string | null;

	@GandalfProperty({ designType: String })
	itemUpc!: string | null;

	@GandalfProperty({ designType: String })
	locationBarcodeIdentifier!: string | null;

	@GandalfProperty({ designType: String })
	manufacturerName!: string | null;

	@GandalfProperty({ designType: String })
	productName!: string | null;

	@GandalfProperty({ designType: Number })
	quantityInStock!: number | null;

}
