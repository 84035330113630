// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.AlertResponse */
/* istanbul ignore next */
@GandalfModel
export class AlertResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.AlertCategory)
	@GandalfProperty({ designType: constants.AlertCategory })
	category!: constants.AlertCategory | null;

	@GandalfProperty({ designType: String })
	createdBy!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.AlertDisplayType)
	@GandalfProperty({ designType: constants.AlertDisplayType })
	displayType!: constants.AlertDisplayType | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfConstantDecorator(constants.AlertSeverity)
	@GandalfProperty({ designType: constants.AlertSeverity })
	severity!: constants.AlertSeverity | null;

	@GandalfConstantDecorator(constants.AlertStatus)
	@GandalfProperty({ designType: constants.AlertStatus })
	status!: constants.AlertStatus | null;

}
