// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { CreatePatientRequest } from './create-patient-request';

// @ts-ignore
import { PatientAdditionalInformationRequest } from './patient-additional-information-request';

// @ts-ignore
import { PatientBasicInformationRequest } from './patient-basic-information-request';

// @ts-ignore
import { PatientCommunicationPreferencesRequest } from './patient-communication-preferences-request';

// @ts-ignore
import { PatientEmploymentRequest } from './patient-employment-request';

// @ts-ignore
import { PatientOtherInformationRequest } from './patient-other-information-request';

// @ts-ignore
import { PatientPhoneInformationRequest } from './patient-phone-information-request';

// @ts-ignore
import { PatientPortalAccessRequest } from './patient-portal-access-request';

// @ts-ignore
import { PatientReferralRequest } from './patient-referral-request';

/** See com.rev360.pms.api.controller.patient.UpdatePatientRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientRequest extends CreatePatientRequest {

	@GandalfValidator({ notNull: { message: 'Address is Required' } })
	@GandalfProperty({ designType: AddressOptionalRequest, isRequired: true })
	address!: AddressOptionalRequest;

	@GandalfArray(Number)
	authorizedPatientIds!: number[] | null;

	@GandalfValidator({ notNull: { message: 'Additional Information is Required' } })
	@GandalfProperty({ designType: PatientAdditionalInformationRequest, isRequired: true })
	patientAdditionalInformation!: PatientAdditionalInformationRequest;

	@GandalfValidator({ notNull: { message: 'Basic Information is required' } })
	@GandalfProperty({ designType: PatientBasicInformationRequest, isRequired: true })
	patientBasicInformation!: PatientBasicInformationRequest;

	@GandalfValidator({ notNull: { message: 'Communication Preferences is Required' } })
	@GandalfProperty({ designType: PatientCommunicationPreferencesRequest, isRequired: true })
	patientCommunicationPreferences!: PatientCommunicationPreferencesRequest;

	@GandalfProperty({ designType: PatientEmploymentRequest })
	patientEmployment!: PatientEmploymentRequest | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfValidator({ notNull: { message: 'Other Information is Required' } })
	@GandalfProperty({ designType: PatientOtherInformationRequest, isRequired: true })
	patientOtherInformation!: PatientOtherInformationRequest;

	@GandalfValidator({ notNull: { message: 'Phone Information is Required' } })
	@GandalfProperty({ designType: PatientPhoneInformationRequest, isRequired: true })
	patientPhoneInformation!: PatientPhoneInformationRequest;

	@GandalfProperty({ designType: PatientReferralRequest })
	patientReferral!: PatientReferralRequest | null;

	@GandalfProperty({ designType: Number })
	photoId!: number | null;

	@GandalfValidator({ notNull: { message: 'Login Information is required' } })
	@GandalfProperty({ designType: PatientPortalAccessRequest, isRequired: true })
	portalCredentials!: PatientPortalAccessRequest;

	@GandalfProperty({ designType: Boolean })
	updateSsn!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	version!: number;

}
