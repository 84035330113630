// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeOptionsResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeOptionsResponse extends GandalfModelBase {

	@GandalfArray(ReferenceDataResponse)
	addDesignations!: ReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	addZoneSizes!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	designs!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	edgeLifts!: MasterReferenceDataResponse[] | null;

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsHybrid!: ReferenceDataResponse[] | null;

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsRgp!: ReferenceDataResponse[] | null;

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsSoft!: ReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	limbalClearances!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	peripheralEdges!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	profiles!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	segmentSizes!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	skirtCurveRadii!: MasterReferenceDataResponse[] | null;

	@GandalfArray(MasterReferenceDataResponse)
	specialCharacteristics!: MasterReferenceDataResponse[] | null;

}
