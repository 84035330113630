// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminAccessCodeRequest } from './admin-access-code-request';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationPhrRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationPhrRequest extends GandalfModelBase {

	@GandalfSubRequestLabel('PHR Access Code')
	@GandalfValidator({ notNull: { message: 'PHR Access Codes is required' } })
	@GandalfArray(AdminAccessCodeRequest)
	accessCodes!: AdminAccessCodeRequest[];

	@GandalfValidator({ notNull: { message: 'Existing patients can schedule appointments through the PHR is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	allowPhrScheduling!: boolean;

	@GandalfValidator({ notNull: { message: 'Newly registered patients can schedule appointments through the PHR is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	allowRegisterPhrScheduling!: boolean;

	@GandalfArray(Number)
	patientScheduleNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	patientUpdateNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	registrationNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	registrationScheduleNotificationPersonIds!: number[] | null;

}
