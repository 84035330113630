// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.PreferenceResponse */
/* istanbul ignore next */
@GandalfModel
export class PreferenceResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PreferenceName)
	@GandalfProperty({ designType: constants.PreferenceName })
	name!: constants.PreferenceName | null;

	@GandalfProperty({ designType: String })
	value!: string | null;

}
