// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.WorkflowScreenTestTemplateLayoutRequest */
/* istanbul ignore next */
@GandalfModel
export class WorkflowScreenTestTemplateLayoutRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 1, message: 'The height must be greater than zero.' } })
	@GandalfProperty({ designType: Number })
	formHeight!: number | null;

	@GandalfValidator({ min: { min: 1, message: 'The width must be greater than zero.' } })
	@GandalfProperty({ designType: Number })
	formWidth!: number | null;

	@GandalfProperty({ designType: Number })
	leftPosition!: number | null;

	@GandalfProperty({ designType: Number })
	topPosition!: number | null;

	@GandalfValidator({ notNull: { message: 'Workflow Test Template is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	workflowTestTemplateId!: number;

}
