// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.openedge.IsPayOnlineUrlPathValidRequest */
/* istanbul ignore next */
@GandalfModel
export class IsPayOnlineUrlPathValidRequest extends GandalfModelBase {

	@GandalfLabel('Pay Online URL')
	@GandalfValidator({ notNull: { message: 'Pay Online URL is required' } })
	@GandalfValidator({ sizeString: { message: 'Pay Online URL must be between 3 and 30 characters', minLength: 3, maxLength: 30 } })
	@GandalfProperty({ designType: String, isRequired: true })
	payOnlineUrlPath!: string;

}
