// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.selfpolicy.UpdatePatientMaterialBenefitsSelfPolicyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientMaterialBenefitsSelfPolicyRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	authorizationDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty({ designType: String })
	authorizationNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	contactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	contactLensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	frameBenefitResetDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	hasContactLensBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasFrameBenefit!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasLensBenefit!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	maxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	minFrameAllowance!: number | null;

}
