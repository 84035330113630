// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	cardiovascular!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	constitutional!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	endocrine!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	ent!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	gastrointestinal!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	genitourinary!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	hematLymphatic!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	immunological!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	musculoskeletal!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	neurological!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	psychological!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	respiratory!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty({ designType: FieldValueMultiSelectionResponse })
	skin!: FieldValueMultiSelectionResponse | null;

}
