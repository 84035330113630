// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.dynamic.RefractionEyeRequest */
/* istanbul ignore next */
@GandalfModel
export class RefractionEyeRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	axis!: number | null;

	@GandalfProperty({ designType: Boolean })
	balanced!: boolean | null;

	@GandalfProperty({ designType: Number })
	cylinder!: number | null;

	@GandalfProperty({ designType: Number })
	horizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	horizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	intermediateAdd!: number | null;

	@GandalfProperty({ designType: Number })
	nearAdd!: number | null;

	@GandalfProperty({ designType: Number })
	sphere!: number | null;

	@GandalfProperty({ designType: Number })
	verticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	verticalPrismOrientation!: constants.VerticalPrismOrientation | null;

}
