// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.communicationtemplate.GeneratePreviewPdfRequest */
/* istanbul ignore next */
@GandalfModel
export class GeneratePreviewPdfRequest extends GandalfModelBase {

	@GandalfLabel('Bottom Margin')
	@GandalfValidator({ notNull: { message: 'Bottom Margin is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Bottom Margin must be greater than or equal to 0 inches' } })
	@GandalfValidator({ max: { max: 5, message: 'Bottom Margin must be less than or equal to 5 inches' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	bottomMargin!: number;

	@GandalfLabel('Left Margin')
	@GandalfValidator({ notNull: { message: 'Left Margin is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Left Margin must be greater than or equal to 0 inches' } })
	@GandalfValidator({ max: { max: 4, message: 'Left Margin must be less than or equal to 4 inches' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	leftMargin!: number;

	@GandalfLabel('Right Margin')
	@GandalfValidator({ notNull: { message: 'Right Margin is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Right Margin must be greater than or equal to 0 inches' } })
	@GandalfValidator({ max: { max: 4, message: 'Right Margin must be less than or equal to 4 inches' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	rightMargin!: number;

	@GandalfLabel('Sample HTML')
	@GandalfValidator({ notNull: { message: 'Sample HTML is required' } })
	@GandalfValidator({ sizeString: { message: 'Sample HTML must be between 1 and 65535 characters', minLength: 1, maxLength: 65535 } })
	@GandalfProperty({ designType: String, isRequired: true })
	sampleHtml!: string;

	@GandalfLabel('Top Margin')
	@GandalfValidator({ notNull: { message: 'Top Margin is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Top Margin must be greater than or equal to 0 inches' } })
	@GandalfValidator({ max: { max: 5, message: 'Top Margin must be less than or equal to 5 inches' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	topMargin!: number;

}
