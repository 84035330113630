// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

/** See com.rev360.pms.api.controller.drug.PracticeDrugListResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDrugListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	dispensingAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dispensingUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	doNotSubstitute!: boolean | null;

	@GandalfProperty({ designType: Number })
	dosageAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dosageUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	drugId!: number | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	frequency!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ designType: Number })
	numRefillsAllowed!: number | null;

	@GandalfProperty({ designType: Number })
	practiceDrugId!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	route!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	shortName!: string | null;

}
