// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurPatternMonthlyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurPatternMonthlyRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 1, message: 'Number of months cannot be less than 1' } })
	@GandalfValidator({ max: { max: 12, message: 'Number of months cannot be more than 12' } })
	@GandalfProperty({ designType: Number })
	monthlyAmount!: number | null;

	@GandalfValidator({ min: { min: 1, message: 'Number of months cannot be less than 1' } })
	@GandalfValidator({ max: { max: 12, message: 'Number of months cannot be more than 12' } })
	@GandalfProperty({ designType: Number })
	monthlyAmount2!: number | null;

	@GandalfValidator({ min: { min: 1, message: 'Day of month must be at least 1' } })
	@GandalfValidator({ max: { max: 31, message: 'Day of month cannot be more than 31' } })
	@GandalfProperty({ designType: Number })
	monthlyDay!: number | null;

	@GandalfConstantDecorator(constants.DaySelect)
	@GandalfProperty({ designType: constants.DaySelect })
	monthlyDaySelect!: constants.DaySelect | null;

	@GandalfConstantDecorator(constants.MonthlyPatternType)
	@GandalfValidator({ notNull: { message: 'Monthly pattern type must be set.' } })
	@GandalfProperty({ designType: constants.MonthlyPatternType, isRequired: true })
	monthlyPatternType!: constants.MonthlyPatternType;

	@GandalfConstantDecorator(constants.WeekSelect)
	@GandalfProperty({ designType: constants.WeekSelect })
	monthlyWeekSelect!: constants.WeekSelect | null;

}
