// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.allergy.AllergyReviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class AllergyReviewHistoryResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ReviewHistoryCategory)
	@GandalfProperty({ designType: constants.ReviewHistoryCategory })
	category!: constants.ReviewHistoryCategory | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: String })
	drugAllergyComment!: string | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: Boolean })
	noKnownDrugAllergies!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	noLatexSensitivity!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	noOtherKnownAllergies!: boolean | null;

	@GandalfProperty({ designType: String })
	otherAllergyComment!: string | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: Date })
	reviewDate!: Date | null;

	@GandalfProperty({ designType: Number })
	reviewId!: number | null;

	@GandalfProperty({ designType: String })
	reviewedByName!: string | null;

	@GandalfProperty({ designType: Number })
	reviewedByUserId!: number | null;

	@GandalfConstantDecorator(constants.ReviewHistorySubCategory)
	@GandalfProperty({ designType: constants.ReviewHistorySubCategory })
	subCategory!: constants.ReviewHistorySubCategory | null;

}
