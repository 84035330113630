// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.DepositsReportRequest */
/* istanbul ignore next */
@GandalfModel
export class DepositsReportRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateRangeEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateRangeStart!: Date | null;

	@GandalfConstantDecorator(constants.DateRangeType)
	@GandalfValidator({ notNull: { message: 'Date range is required' } })
	@GandalfProperty({ designType: constants.DateRangeType, isRequired: true })
	dateRangeType!: constants.DateRangeType;

	@GandalfConstantDecorator(constants.DepositsReportType)
	@GandalfValidator({ notNull: { message: 'Report type is required' } })
	@GandalfProperty({ designType: constants.DepositsReportType, isRequired: true })
	depositsReportType!: constants.DepositsReportType;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfConstantDecorator(constants.AccountingReportOutputType)
	@GandalfValidator({ notNull: { message: 'Report output is required' } })
	@GandalfProperty({ designType: constants.AccountingReportOutputType, isRequired: true })
	reportOutputType!: constants.AccountingReportOutputType;

}
