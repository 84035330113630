// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.OrdersSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class OrdersSearchResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDate!: Date | null;

	@GandalfProperty({ designType: Number })
	masterProcessorId!: number | null;

	@GandalfProperty({ designType: String })
	masterProcessorName!: string | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	nextWorkflowStatus!: constants.OrderStatusCode | null;

	@GandalfProperty({ designType: String })
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	notificationDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	onHold!: boolean | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty({ designType: constants.OrderStatusCode })
	orderStatusCode!: constants.OrderStatusCode | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty({ designType: constants.OrderType })
	orderType!: constants.OrderType | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty({ designType: constants.OrderShipToType })
	shipToType!: constants.OrderShipToType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	statusDate!: Date | null;

	@GandalfProperty({ designType: Number })
	vendorId!: number | null;

	@GandalfProperty({ designType: String })
	vendorName!: string | null;

	@GandalfProperty({ designType: String })
	vendorWebSite!: string | null;

}
