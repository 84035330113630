// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.admin.CqmReportResponse */
/* istanbul ignore next */
@GandalfModel
export class CqmReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	denominator!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	eMeasure!: string | null;

	@GandalfProperty({ designType: Number })
	exception!: number | null;

	@GandalfProperty({ designType: Number })
	exclusion!: number | null;

	@GandalfProperty({ designType: String })
	nqf!: string | null;

	@GandalfProperty({ designType: Number })
	numerator!: number | null;

	@GandalfProperty({ designType: Number })
	percent!: number | null;

}
