// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreatePatientCommunicationPreferencesRequest } from './create-patient-communication-preferences-request';

/** See com.rev360.pms.api.controller.patient.PatientCommunicationPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientCommunicationPreferencesRequest extends CreatePatientCommunicationPreferencesRequest {

	@GandalfProperty({ designType: Boolean })
	appointmentsCellPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	appointmentsEmailAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	appointmentsHomePhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	appointmentsMailAllowed!: boolean | null;

	@GandalfConstantDecorator(constants.PatientCommunicationOptIn)
	@GandalfProperty({ designType: constants.PatientCommunicationOptIn })
	appointmentsOptIn!: constants.PatientCommunicationOptIn | null;

	@GandalfProperty({ designType: Boolean })
	appointmentsTextAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	appointmentsWorkPhoneAllowed!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Boolean })
	doNotCallCell!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	doNotCallHome!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	doNotCallWork!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	doNotEmail!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	doNotMail!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	doNotText!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	generalCellPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	generalEmailAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	generalHomePhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	generalMailAllowed!: boolean | null;

	@GandalfConstantDecorator(constants.PatientCommunicationOptIn)
	@GandalfProperty({ designType: constants.PatientCommunicationOptIn })
	generalOptIn!: constants.PatientCommunicationOptIn | null;

	@GandalfProperty({ designType: Boolean })
	generalTextAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	generalWorkPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	ordersCellPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	ordersEmailAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	ordersHomePhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	ordersMailAllowed!: boolean | null;

	@GandalfConstantDecorator(constants.PatientCommunicationOptIn)
	@GandalfProperty({ designType: constants.PatientCommunicationOptIn })
	ordersOptIn!: constants.PatientCommunicationOptIn | null;

	@GandalfProperty({ designType: Boolean })
	ordersTextAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	ordersWorkPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	recallsCellPhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	recallsEmailAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	recallsHomePhoneAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	recallsMailAllowed!: boolean | null;

	@GandalfConstantDecorator(constants.PatientCommunicationOptIn)
	@GandalfProperty({ designType: constants.PatientCommunicationOptIn })
	recallsOptIn!: constants.PatientCommunicationOptIn | null;

	@GandalfProperty({ designType: Boolean })
	recallsTextAllowed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	recallsWorkPhoneAllowed!: boolean | null;

}
