// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.CreatePracticeInsuranceCompanyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePracticeInsuranceCompanyRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Abbreviation cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty({ designType: String })
	abbr!: string | null;

	@GandalfValidator({ sizeString: { message: 'Address Line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	addressLine1!: string | null;

	@GandalfValidator({ sizeString: { message: 'Address Line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	addressLine2!: string | null;

	@GandalfProperty({ designType: Boolean })
	allowItemDiscounts!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	chargedTax!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	city!: string | null;

	@GandalfConstantDecorator(constants.ClaimFilingCode)
	@GandalfProperty({ designType: constants.ClaimFilingCode })
	claimFilingCode!: constants.ClaimFilingCode | null;

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfValidator({ notNull: { message: 'Claim Submission is required' } })
	@GandalfProperty({ designType: constants.ClaimSubmitMechanism, isRequired: true })
	claimSubmissionMechanism!: constants.ClaimSubmitMechanism;

	@GandalfProperty({ designType: Number })
	defaultInsuranceTypeReferenceId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	defaultPaymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty({ designType: Number })
	defaultPriorityReferenceId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	fax!: string | null;

	@GandalfValidator({ sizeString: { message: 'Group Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	groupNumber!: string | null;

	@GandalfConstantDecorator(constants.GroupNumberQualifier)
	@GandalfProperty({ designType: constants.GroupNumberQualifier })
	groupNumberQualifier!: constants.GroupNumberQualifier | null;

	@GandalfProperty({ designType: Boolean })
	includePqrsAlert!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Classification is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	insuranceClassMasterReferenceId!: number;

	@GandalfValidator({ sizeString: { message: 'Main Phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfProperty({ designType: String })
	mainPhone!: string | null;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty({ designType: String })
	nonStandardPostalCode!: string | null;

	@GandalfValidator({ sizeString: { message: 'Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	notes!: string | null;

	@GandalfProperty({ designType: Boolean })
	npiOnly!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	participatingPractice!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Payer ID cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	payerId!: string | null;

	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty({ designType: String })
	postalCode!: string | null;

	@GandalfProperty({ designType: Boolean })
	removeMatchingServiceNpi!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	renderingIsReferringProvider!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'State/Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	stateProvince!: string | null;

	@GandalfProperty({ designType: Boolean })
	transferTax!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	webSite!: string | null;

}
