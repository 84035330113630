// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.file.folder.FileFolderResponse */
/* istanbul ignore next */
@GandalfModel
export class FileFolderResponse extends GandalfModelBase {

	@GandalfArray(FileFolderResponse)
	folders!: FileFolderResponse[] | null;

	@GandalfProperty({ designType: String })
	id!: string | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

}
