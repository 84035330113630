// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddDiscountItemRequest } from './add-discount-item-request';

/** See com.rev360.pms.api.controller.accounting.AddDiscountItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class AddDiscountItemsRequest extends GandalfModelBase {

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 200 characters', minLength: 0, maxLength: 200 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfArray(AddDiscountItemRequest)
	discountItems!: AddDiscountItemRequest[];

	@GandalfLabel('Discount Reason')
	@GandalfValidator({ notNull: { message: 'Discount Reason is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	discountReferenceId!: number;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceId!: number;

}
