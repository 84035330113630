// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.RxSelectContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class RxSelectContactLensResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ContactLensType)
	@GandalfProperty({ designType: constants.ContactLensType })
	contactLensType!: constants.ContactLensType | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: String })
	odManufacturerName!: string | null;

	@GandalfProperty({ designType: String })
	odProductName!: string | null;

	@GandalfProperty({ designType: String })
	osManufacturerName!: string | null;

	@GandalfProperty({ designType: String })
	osProductName!: string | null;

	@GandalfConstantDecorator(constants.RxDisplayStatusLegacy)
	@GandalfProperty({ designType: constants.RxDisplayStatusLegacy })
	rxDisplayStatus!: constants.RxDisplayStatusLegacy | null;

	@GandalfProperty({ designType: Number })
	rxId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

}
