// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.UnsignEncounterSsoRequest */
/* istanbul ignore next */
@GandalfModel
export class UnsignEncounterSsoRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	encounterId!: number;

	@GandalfLabel('Reason')
	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 8000 characters', minLength: 0, maxLength: 8000 } })
	@GandalfProperty({ designType: String })
	reason!: string | null;

}
