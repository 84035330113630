// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.smartapplication.GetPracticeLaunchUrlRequest */
/* istanbul ignore next */
@GandalfModel
export class GetPracticeLaunchUrlRequest extends GandalfModelBase {

	@GandalfLabel('Master Smart Application')
	@GandalfValidator({ notNull: { message: 'Master Smart Application is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	masterSmartApplicationId!: number;

	@GandalfLabel('Provider NPI')
	@GandalfValidator({ notNull: { message: 'Provider NPI is required' } })
	@GandalfValidator({ sizeString: { message: 'Provider NPI must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ designType: String, isRequired: true })
	providerNpi!: string;

}
