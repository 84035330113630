// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminAccessTimeResponse } from './admin-access-time-response';

/** See com.rev360.pms.api.controller.admin.employee.detailed.response.AdminEmployeeLoginRestrictionResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeLoginRestrictionResponse extends GandalfModelBase {

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	friday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	monday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	saturday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	sunday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	thursday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	tuesday!: AdminAccessTimeResponse | null;

	@GandalfProperty({ designType: AdminAccessTimeResponse })
	wednesday!: AdminAccessTimeResponse | null;

}
