// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.provider.externalprovider.ExternalProviderListResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalProviderListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	altPracticeName!: string | null;

	@GandalfProperty({ designType: String })
	credentials!: string | null;

	@GandalfProperty({ designType: String })
	directAddress!: string | null;

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: PersonNameResponse })
	name!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	preferredPhone!: string | null;

	@GandalfConstantDecorator(constants.ProviderStatus)
	@GandalfProperty({ designType: constants.ProviderStatus })
	status!: constants.ProviderStatus | null;

}
