// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityOrderItemResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityOrderItemResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	billableItemId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityChargeback!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityChargebackBeforeCopay!: number | null;

	@GandalfProperty({ designType: Number })
	eyefinityFeeOptionId!: number | null;

	@GandalfProperty({ designType: Boolean })
	eyefinityGenerated!: boolean | null;

	@GandalfProperty({ designType: Number })
	eyefinityOrderItemId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityPays!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityReimbursement!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityServiceFee!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	eyefinityTotal!: number | null;

	@GandalfProperty({ designType: String })
	hcpcsCode!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	invoiceCost!: number | null;

	@GandalfProperty({ designType: Boolean })
	itemIsCovered!: boolean | null;

	@GandalfConstantDecorator(constants.EyefinityOrderItemType)
	@GandalfProperty({ designType: constants.EyefinityOrderItemType })
	itemType!: constants.EyefinityOrderItemType | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientCopay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientCopayAmount!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientPortion!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientPortionExceedingAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientPortionForNonCoveredItem!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientPortionFromUnallocatedCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientTotal!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientTransferAmount!: number | null;

	@GandalfProperty({ designType: Number })
	quantity!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	recoupmentAmount!: number | null;

	@GandalfProperty({ designType: Boolean })
	rememberItem!: boolean | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	retailCost!: number | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	unallocatedCost!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	writeoffAmount!: number | null;

}
