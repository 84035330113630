// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsContactLensTrialResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsContactLensTrialResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	contactLensTrialId!: number | null;

	@GandalfProperty({ designType: String })
	odAddDesignation!: string | null;

	@GandalfProperty({ designType: Number })
	odAddPower!: number | null;

	@GandalfProperty({ designType: Number })
	odAxis!: number | null;

	@GandalfProperty({ designType: Number })
	odBaseCurve!: number | null;

	@GandalfProperty({ designType: String })
	odComment!: string | null;

	@GandalfProperty({ designType: Number })
	odCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	odDiameter!: number | null;

	@GandalfProperty({ designType: Boolean })
	odIsDispensed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	odIsMonovision!: boolean | null;

	@GandalfProperty({ designType: String })
	odLocationProductName!: string | null;

	@GandalfProperty({ designType: DurationResponse })
	odOvernightSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: DurationResponse })
	odReplacementSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: Number })
	odSphere!: number | null;

	@GandalfProperty({ designType: String })
	osAddDesignation!: string | null;

	@GandalfProperty({ designType: Number })
	osAddPower!: number | null;

	@GandalfProperty({ designType: Number })
	osAxis!: number | null;

	@GandalfProperty({ designType: Number })
	osBaseCurve!: number | null;

	@GandalfProperty({ designType: String })
	osComment!: string | null;

	@GandalfProperty({ designType: Number })
	osCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	osDiameter!: number | null;

	@GandalfProperty({ designType: Boolean })
	osIsDispensed!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	osIsMonovision!: boolean | null;

	@GandalfProperty({ designType: String })
	osLocationProductName!: string | null;

	@GandalfProperty({ designType: DurationResponse })
	osOvernightSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: DurationResponse })
	osReplacementSchedule!: DurationResponse | null;

	@GandalfProperty({ designType: Number })
	osSphere!: number | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	overallLensType!: constants.ContactLensPrescriptionType | null;

}
