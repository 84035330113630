// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	approvalStatus!: string | null;

	@GandalfProperty({ designType: String })
	employeeFirstName!: string | null;

	@GandalfProperty({ designType: String })
	employeeLastName!: string | null;

	@GandalfProperty({ designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	providerFirstName!: string | null;

	@GandalfProperty({ designType: String })
	providerLastName!: string | null;

	@GandalfProperty({ designType: String })
	status!: string | null;

	@GandalfProperty({ designType: String })
	subTypeValue!: string | null;

	@GandalfProperty({ designType: String })
	templateName!: string | null;

}
