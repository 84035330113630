// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.AdminAccessCodeRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminAccessCodeRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	accessCodeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Code must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	code!: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	name!: string;

	@GandalfConstantDecorator(constants.AccessCodeStatus)
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ designType: constants.AccessCodeStatus, isRequired: true })
	status!: constants.AccessCodeStatus;

}
