// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.snomed.SearchSnomedCodesRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchSnomedCodesRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { fieldLabel: 'Code', maxLength: 1000, minLength: 0, message: 'Code must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Description', maxLength: 1000, minLength: 0, message: 'Description must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.SnomedType)
	@GandalfProperty({ designType: constants.SnomedType })
	type!: constants.SnomedType | null;

}
