// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.common.PostalCodeSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PostalCodeSearchRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Zip code is required' } })
	@GandalfValidator({ sizeString: { fieldLabel: 'Zip code', maxLength: 1000, minLength: 1, message: 'Zip code must be between 1 and 1000 characters' } })
	@GandalfProperty({ designType: String, isRequired: true })
	postalCode!: string;

}
