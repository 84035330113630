// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.pfsh.SocialHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class SocialHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	alcoholAmount!: string | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: String })
	hobbies!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfConstantDecorator(constants.IsDrinker)
	@GandalfProperty({ designType: constants.IsDrinker })
	isDrinker!: constants.IsDrinker | null;

	@GandalfConstantDecorator(constants.IsSmoker)
	@GandalfProperty({ designType: constants.IsSmoker })
	isSmoker!: constants.IsSmoker | null;

	@GandalfProperty({ designType: String })
	smokingAmount!: string | null;

	@GandalfConstantDecorator(constants.SmokingPreference)
	@GandalfProperty({ designType: constants.SmokingPreference })
	smokingPreference!: constants.SmokingPreference | null;

	@GandalfConstantDecorator(constants.SmokingStatus)
	@GandalfProperty({ designType: constants.SmokingStatus })
	smokingStatus!: constants.SmokingStatus | null;

}
