// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.PaymentPreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentPreferencesResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.CreditCardType) 
	@GandalfArray(constants.CreditCardType)
	creditCardTypes!: constants.CreditCardType[] | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType) 
	@GandalfArray(constants.PaymentMethodType)
	paymentMethods!: constants.PaymentMethodType[] | null;

	@GandalfProperty({ designType: Number })
	practiceId!: number | null;

}
