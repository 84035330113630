// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.admin.MeaningfulUseReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MeaningfulUseReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowExclude!: boolean | null;

	@GandalfProperty({ designType: Number })
	denominator!: number | null;

	@GandalfProperty({ designType: Number })
	displayOrder!: number | null;

	@GandalfProperty({ designType: Boolean })
	exclusion!: boolean | null;

	@GandalfProperty({ designType: Number })
	exclusionCount!: number | null;

	@GandalfProperty({ designType: Number })
	exclusionLimit!: number | null;

	@GandalfProperty({ designType: String })
	measureName!: string | null;

	@GandalfConstantDecorator(constants.MeaningfulUseLogMeasureType)
	@GandalfProperty({ designType: constants.MeaningfulUseLogMeasureType })
	measureType!: constants.MeaningfulUseLogMeasureType | null;

	@GandalfProperty({ designType: String })
	measureUrl!: string | null;

	@GandalfProperty({ designType: Number })
	numerator!: number | null;

	@GandalfProperty({ designType: Number })
	percent!: number | null;

	@GandalfProperty({ designType: Number })
	percentRequired!: number | null;

	@GandalfProperty({ designType: Number })
	performanceMultiplier!: number | null;

	@GandalfProperty({ designType: Boolean })
	success!: boolean | null;

}
