// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CollectionsInvoiceTransferSplitRequest } from './collections-invoice-transfer-split-request';

// @ts-ignore
import { InsuranceInvoiceTransferSplitRequest } from './insurance-invoice-transfer-split-request';

// @ts-ignore
import { PatientInvoiceTransferSplitRequest } from './patient-invoice-transfer-split-request';

// @ts-ignore
import { WriteoffInvoiceTransferSplitRequest } from './writeoff-invoice-transfer-split-request';

/** See com.rev360.pms.api.controller.accounting.InvoiceTransferRequest */
/* istanbul ignore next */
@GandalfModel
export class InvoiceTransferRequest extends GandalfModelBase {

	@GandalfProperty({ designType: CollectionsInvoiceTransferSplitRequest })
	collectionsInvoiceTransferSplitRequest!: CollectionsInvoiceTransferSplitRequest | null;

	@GandalfProperty({ designType: InsuranceInvoiceTransferSplitRequest })
	insuranceInvoiceTransferSplitRequest!: InsuranceInvoiceTransferSplitRequest | null;

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceId!: number;

	@GandalfProperty({ designType: PatientInvoiceTransferSplitRequest })
	patientInvoiceTransferSplitRequest!: PatientInvoiceTransferSplitRequest | null;

	@GandalfProperty({ designType: WriteoffInvoiceTransferSplitRequest })
	writeoffInvoiceTransferSplitRequest!: WriteoffInvoiceTransferSplitRequest | null;

}
