// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.twowaytext.UpdateTwoWayTextConversationClassificationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateTwoWayTextConversationClassificationRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ConnectConversationClassification)
	@GandalfLabel('Classification')
	@GandalfValidator({ notNull: { message: 'Classification is required' } })
	@GandalfProperty({ designType: constants.ConnectConversationClassification, isRequired: true })
	classification!: constants.ConnectConversationClassification;

	@GandalfLabel('Connect Conversation')
	@GandalfValidator({ notNull: { message: 'Connect Conversation is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	connectConversationId!: number;

}
