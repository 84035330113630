// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryEncounterHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryEncounterHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: String })
	encounterLocation!: string | null;

	@GandalfProperty({ designType: String })
	encounterType!: string | null;

	@GandalfProperty({ designType: PersonNameResponse })
	provider!: PersonNameResponse | null;

}
