// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentPersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class AssessmentPersonDiagnosisResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.EncounterAutoDiagnosisStatus)
	@GandalfProperty({ designType: constants.EncounterAutoDiagnosisStatus })
	autoDiagnosisStatus!: constants.EncounterAutoDiagnosisStatus | null;

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty({ designType: constants.CodeSet })
	codeSet!: constants.CodeSet | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	diagnosisDate!: Date | null;

	@GandalfConstantDecorator(constants.DiagnosisLocation)
	@GandalfProperty({ designType: constants.DiagnosisLocation })
	diagnosisLocation!: constants.DiagnosisLocation | null;

	@GandalfConstantDecorator(constants.DiagnosisLocationQualifier)
	@GandalfProperty({ designType: constants.DiagnosisLocationQualifier })
	diagnosisLocationQualifier!: constants.DiagnosisLocationQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	disabledDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterAutoDiagnosisId!: number | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: Boolean })
	isPrimary!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isShowDisabledWarning!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	needConversion!: boolean | null;

	@GandalfProperty({ designType: Number })
	originalDiagnosisId!: number | null;

	@GandalfProperty({ designType: Number })
	personDiagnosisId!: number | null;

	@GandalfProperty({ designType: Number })
	personId!: number | null;

	@GandalfProperty({ designType: Number })
	practiceDiagnosisId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	resolutionDate!: Date | null;

	@GandalfProperty({ designType: String })
	shortDescription!: string | null;

	@GandalfConstantDecorator(constants.PersonDiagnosisStatus)
	@GandalfProperty({ designType: constants.PersonDiagnosisStatus })
	status!: constants.PersonDiagnosisStatus | null;

}
