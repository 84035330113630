// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.claimsprocessor.CreateExternalCredentialForPracticeLocationRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateExternalCredentialForPracticeLocationRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ExternalSystem)
	@GandalfValidator({ notNull: { message: 'External System is required' } })
	@GandalfProperty({ designType: constants.ExternalSystem, isRequired: true })
	externalSystem!: constants.ExternalSystem;

	@GandalfValidator({ notNull: { message: 'Password is required' } })
	@GandalfValidator({ sizeString: { message: 'Password must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	password!: string;

	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceLocationId!: number;

	@GandalfValidator({ notNull: { message: 'Username is required' } })
	@GandalfValidator({ sizeString: { message: 'Username must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	userName!: string;

}
