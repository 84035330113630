// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeResponse } from './employee-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.EncounterResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	approvalDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	approvalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty({ designType: EmployeeResponse })
	employee!: EmployeeResponse | null;

	@GandalfProperty({ designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Date })
	firstApprovalDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: ProviderResponse })
	provider!: ProviderResponse | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty({ designType: constants.EncounterStatus })
	status!: constants.EncounterStatus | null;

	@GandalfProperty({ designType: String })
	subTypeName!: string | null;

	@GandalfProperty({ designType: String })
	templateName!: string | null;

	@GandalfProperty({ designType: Boolean })
	wasSigned!: boolean | null;

}
