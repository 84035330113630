// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.header.RxntCredentialRequest */
/* istanbul ignore next */
@GandalfModel
export class RxntCredentialRequest extends GandalfModelBase {

	@GandalfLabel('RXNT enabled')
	@GandalfValidator({ notNull: { message: 'RXNT enabled is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	enabled!: boolean;

	@GandalfLabel('RXNT Password')
	@GandalfValidator({ sizeString: { message: 'RXNT Password cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	password!: string | null;

	@GandalfLabel('RXNT Username')
	@GandalfValidator({ sizeString: { message: 'RXNT Username cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	userName!: string | null;

}
