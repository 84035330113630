// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.FindCampaignsForPracticeRequest */
/* istanbul ignore next */
@GandalfModel
export class FindCampaignsForPracticeRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOnEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOnStart!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	scheduledDateEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	scheduledDateStart!: Date | null;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Search must less than or equal to {max} characters' } })
	@GandalfProperty({ designType: String })
	wildCardSearch!: string | null;

}
