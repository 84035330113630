// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateGoalRequest } from './create-care-plan-item-template-goal-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.UpdateCarePlanItemTemplateGoalRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCarePlanItemTemplateGoalRequest extends CreateCarePlanItemTemplateGoalRequest {

	@GandalfValidator({ notNull: { message: 'Goal is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	carePlanItemTemplateId!: number;

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ designType: String, isRequired: true })
	description!: string;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	isCheckoutTask!: boolean;

	@GandalfValidator({ notNull: { message: 'Snomed Code is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	snomedCodeId!: number;

}
