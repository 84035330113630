// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeHybridResponse } from './contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './contact-lens-eye-soft-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensTrialResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	commentsOd!: string | null;

	@GandalfProperty({ designType: String })
	commentsOs!: string | null;

	@GandalfProperty({ designType: ContactLensEyeHybridResponse })
	contactLensEyeHybridOd!: ContactLensEyeHybridResponse | null;

	@GandalfProperty({ designType: ContactLensEyeHybridResponse })
	contactLensEyeHybridOs!: ContactLensEyeHybridResponse | null;

	@GandalfProperty({ designType: ContactLensEyeRgpResponse })
	contactLensEyeRgpOd!: ContactLensEyeRgpResponse | null;

	@GandalfProperty({ designType: ContactLensEyeRgpResponse })
	contactLensEyeRgpOs!: ContactLensEyeRgpResponse | null;

	@GandalfProperty({ designType: ContactLensEyeSoftResponse })
	contactLensEyeSoftOd!: ContactLensEyeSoftResponse | null;

	@GandalfProperty({ designType: ContactLensEyeSoftResponse })
	contactLensEyeSoftOs!: ContactLensEyeSoftResponse | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isDispensedOd!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	isDispensedOs!: boolean | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType })
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty({ designType: ProductForPrescriptionResponse })
	productOd!: ProductForPrescriptionResponse | null;

	@GandalfProperty({ designType: ProductForPrescriptionResponse })
	productOs!: ProductForPrescriptionResponse | null;

}
