// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.FindOrderProcessorsRequest */
/* istanbul ignore next */
@GandalfModel
export class FindOrderProcessorsRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	locationId!: number;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfValidator({ notNull: { message: 'Order type is required' } })
	@GandalfProperty({ designType: constants.OrderType, isRequired: true })
	orderType!: constants.OrderType;

}
