// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.EncounterMedicalDecisionMakingCodingResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterMedicalDecisionMakingCodingResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.CodingResultMdmComplexityLevel)
	@GandalfProperty({ designType: constants.CodingResultMdmComplexityLevel })
	complexity!: constants.CodingResultMdmComplexityLevel | null;

	@GandalfConstantDecorator(constants.CodingResultMdmDiagnosisLevel)
	@GandalfProperty({ designType: constants.CodingResultMdmDiagnosisLevel })
	diagnosis!: constants.CodingResultMdmDiagnosisLevel | null;

	@GandalfProperty({ designType: String })
	finalCpt!: string | null;

	@GandalfConstantDecorator(constants.CodingResultMdmLevel)
	@GandalfProperty({ designType: constants.CodingResultMdmLevel })
	level!: constants.CodingResultMdmLevel | null;

	@GandalfConstantDecorator(constants.CodingResultMdmRiskLevel)
	@GandalfProperty({ designType: constants.CodingResultMdmRiskLevel })
	risk!: constants.CodingResultMdmRiskLevel | null;

}
