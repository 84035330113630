// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderPerformedServiceResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderPerformedServiceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfProperty({ designType: Number })
	performedServiceId!: number | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	servicePrice!: number | null;

}
