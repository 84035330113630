// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QueryCriteriaFieldRequest */
/* istanbul ignore next */
@GandalfModel
export class QueryCriteriaFieldRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Field ID length must be less than or equal to {max} characters.' } })
	@GandalfValidator({ notNull: { message: 'Field ID is required.' } })
	@GandalfProperty({ designType: String, isRequired: true })
	id!: string;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Label length must be less than or equal to {max} characters.' } })
	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfValidator({ sizeString: { min: 0, max: 5000, message: 'Value 1 length must be less than or equal to {max} characters.' } })
	@GandalfProperty({ designType: String })
	operand1!: string | null;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Operand Label length must be less than or equal to {max} characters.' } })
	@GandalfProperty({ designType: String })
	operand1Label!: string | null;

	@GandalfValidator({ sizeString: { min: 0, max: 5000, message: 'Value 2 length must be less than or equal to {max} characters.' } })
	@GandalfProperty({ designType: String })
	operand2!: string | null;

	@GandalfConstantDecorator(constants.QueryCriteriaOperator)
	@GandalfValidator({ notNull: { message: 'Operator is required.' } })
	@GandalfProperty({ designType: constants.QueryCriteriaOperator, isRequired: true })
	operator!: constants.QueryCriteriaOperator;

	@GandalfConstantDecorator(constants.QueryCriteriaOuterOperator)
	@GandalfProperty({ designType: constants.QueryCriteriaOuterOperator })
	outerOperator!: constants.QueryCriteriaOuterOperator | null;

	@GandalfConstantDecorator(constants.QueryFieldType)
	@GandalfValidator({ notNull: { message: 'Field type is required.' } })
	@GandalfProperty({ designType: constants.QueryFieldType, isRequired: true })
	type!: constants.QueryFieldType;

}
