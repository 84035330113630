export const FROALA_EDITOR_OPTIONS: any = {
	key: 'ERB2zF2D1A6B4A2D3A1rXYf1VPUGRHYZNRJb2JVOOe1HAb2zA3B2A1G2F4E1E1A10A1D6==',
	imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove', '|',
		'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
	toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
		'|', 'paragraphStyle', 'align', 'formatUL', 'outdent', 'indent', 'fontFamily', 'fontSize', 'textColor',
		'|', 'insertHR', 'insertTable',
		'|', 'undo', 'redo', 'selectAll', 'clearFormatting', 'html',
	],
	tableEditButtons: ['tableHeader', 'tableRemove',
		'|', 'tableRows', 'tableColumns',
		'-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign',
	],
	placeholderText: 'Enter content here.',
	tabSpaces: 5,
	paragraphStyles: {
		lineheightpt5: 'Single Spaced Lines',
	},
	paragraphMultipleStyles: false,
	attribution: false,
};
