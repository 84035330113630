// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.header.AskQuestionRequest */
/* istanbul ignore next */
@GandalfModel
export class AskQuestionRequest extends GandalfModelBase {

	@GandalfLabel('Email Address')
	@GandalfValidator({ notNull: { message: 'Email Address is required' } })
	@GandalfValidator({ sizeString: { message: 'Email Address must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty({ designType: String, isRequired: true })
	emailAddress!: string;

	@GandalfLabel('Question')
	@GandalfValidator({ notNull: { message: 'Question is required' } })
	@GandalfValidator({ sizeString: { message: 'Question must be between 1 and 2000 characters', minLength: 1, maxLength: 2000 } })
	@GandalfProperty({ designType: String, isRequired: true })
	question!: string;

}
