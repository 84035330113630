// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodPatientAccountResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodPatientAccountResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	collectionsBalance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyBalance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyCollectionsBalance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyCredit!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	insuranceBalance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientBalance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	patientCredit!: number | null;

}
