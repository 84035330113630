// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderTwelve84Response */
/* istanbul ignore next */
@GandalfModel
export class OrderTwelve84Response extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	lensCoating!: string | null;

	@GandalfProperty({ designType: String })
	lensMaterial!: string | null;

	@GandalfProperty({ designType: String })
	lensPackage!: string | null;

	@GandalfProperty({ designType: String })
	lensPhotoPolar!: string | null;

	@GandalfProperty({ designType: String })
	lensType!: string | null;

}
