// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderTwelve84ConfigurationOptionResponse } from './order-twelve84-configuration-option-response';

// @ts-ignore
import { OrderTwelve84FrameResponse } from './order-twelve84-frame-response';

/** See com.rev360.pms.api.controller.order.OrderTwelve84ConfigurationResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderTwelve84ConfigurationResponse extends GandalfModelBase {

	@GandalfArray(OrderTwelve84FrameResponse)
	frames!: OrderTwelve84FrameResponse[] | null;

	@GandalfArray(OrderTwelve84ConfigurationOptionResponse)
	lensPackages!: OrderTwelve84ConfigurationOptionResponse[] | null;

}
