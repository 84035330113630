import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { _cloneDeep, _isNil } from '@core/lodash/lodash';
import { ObjectUtils } from 'morgana';
import { GandalfConstant } from 'gandalf';

@Component({
	selector: 'pms-enum-select-button',
	templateUrl: './enum-select-button.component.html',
	styles: [],
})
export class EnumSelectButtonComponent implements OnInit, ControlValueAccessor {

	@Input()
	enumList: GandalfConstant<any>[];

	@Input()
	disabled: boolean;

	@Input()
	allOption: boolean;

	@Input()
	placeholder = 'All';

	@Output()
	valueChange: EventEmitter<any> = new EventEmitter();

	@Output()
	optionClick: EventEmitter<any> = new EventEmitter();

	focusedItem: HTMLButtonElement;
	value: any;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@Optional() @Self() public ngControl: NgControl,
	) {
		this.ngControl.valueAccessor = this;
	}

	/**
	 * An optional function to evaluate if the change can be performed.
	 * @param _targetValue The target value being selected
	 * @returns A promise that resolves to a boolean indicating if the change can be performed.
	 */
	@Input()
	canChange: (targetValue) => Promise<boolean> = (_targetValue) => Promise.resolve(true);

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	ngOnInit() {
		this.enumList = _cloneDeep(this.enumList);
		if (this.allOption) {
			this.enumList.unshift(new GandalfConstant(null, this.placeholder));
		}
	}

	writeValue(option: GandalfConstant<any>) {
		if (_isNil(option)) {
			this.value = null;
		} else {
			this.value = option.value;
		}
		this.changeDetectorRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void) {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onModelTouched = fn;
	}

	async onItemClick(event, option: GandalfConstant<any>, index: number) {
		if (this.disabled || !(await this.canChange(option))) {
			return;
		}

		this.optionClick.emit({
			originalEvent: event,
			option,
			index,
		});

		if (this.value !== option.value) {
			this.value = option.value;

			this.onModelChange(option);
			this.valueChange.emit({
				originalEvent: event,
				value: option,
			});
		}
	}

	onFocus(event: Event) {
		this.focusedItem = event.target as HTMLButtonElement;
	}

	onBlur() {
		this.focusedItem = null;
		this.onModelTouched();
	}

	isSelected(option: GandalfConstant<any>) {
		return option.value === this.value;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}

	buildDataTestIdForOption(option: GandalfConstant<any>) {
		if (ObjectUtils.isNilOrEmpty(this.ngControl.name)) {
			return option.value;
		}
		return `${this.ngControl.name}-${option.value}`;
	}

}
