// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.encounter.consent.PatientConsentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientConsentResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	consentDate!: Date | null;

	@GandalfConstantDecorator(constants.PatientConsentMechanism)
	@GandalfProperty({ designType: constants.PatientConsentMechanism })
	consentMechanism!: constants.PatientConsentMechanism | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	practiceConsentFormId!: number | null;

	@GandalfProperty({ designType: String })
	practiceConsentFormName!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormType)
	@GandalfProperty({ designType: constants.PracticeConsentFormType })
	practiceConsentFormType!: constants.PracticeConsentFormType | null;

	@GandalfProperty({ designType: PatientFileInfoResponse })
	signedDocument!: PatientFileInfoResponse | null;

	@GandalfConstantDecorator(constants.PatientConsentStatus)
	@GandalfProperty({ designType: constants.PatientConsentStatus })
	status!: constants.PatientConsentStatus | null;

}
