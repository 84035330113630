// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.encounter.history.contactlens.UpdateContactLensHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateContactLensHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	continuousWearPeriodAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	continuousWearPeriodUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	dailyWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	dailyWearingTimeUnit!: constants.DurationUnit | null;

	@GandalfValidator({ sizeString: { message: 'Drops Used cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	dropsUsed!: string | null;

	@GandalfValidator({ notNull: { message: 'History is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfProperty({ designType: Number })
	lensAgeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	lensAgeUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: ContactLensEyeRequest })
	od!: ContactLensEyeRequest | null;

	@GandalfProperty({ designType: ContactLensEyeRequest })
	os!: ContactLensEyeRequest | null;

	@GandalfValidator({ sizeString: { message: 'Other Supplies cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	otherSupplies!: string | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryType)
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ designType: constants.ContactLensHistoryType, isRequired: true })
	overallLensType!: constants.ContactLensHistoryType;

	@GandalfProperty({ designType: Number })
	replacementScheduleAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	replacementScheduleUnit!: constants.DurationUnit | null;

	@GandalfValidator({ sizeString: { message: 'Solution Used cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	solutionUsed!: string | null;

	@GandalfProperty({ designType: Number })
	supplyRemainingAmount!: number | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryPackaging)
	@GandalfProperty({ designType: constants.ContactLensHistoryPackaging })
	supplyRemainingPacking!: constants.ContactLensHistoryPackaging | null;

	@GandalfProperty({ designType: Number })
	todaysWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	todaysWearingTimeUnit!: constants.DurationUnit | null;

}
