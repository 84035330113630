// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateConnectPracticeRequest } from './create-connect-practice-request';

/** See com.rev360.pms.api.controller.admin.practicepreferences.UpdateConnectPracticeRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateConnectPracticeRequest extends CreateConnectPracticeRequest {

	@GandalfLabel('Legal Business Name of Practice')
	@GandalfValidator({ notNull: { message: 'Legal Business Name of Practice is required' } })
	@GandalfValidator({ sizeString: { message: 'Legal Business Name of Practice must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	businessName!: string;

	@GandalfConstantDecorator(constants.ConnectBusinessType)
	@GandalfLabel('Business Type')
	@GandalfValidator({ notNull: { message: 'Business Type is required' } })
	@GandalfProperty({ designType: constants.ConnectBusinessType, isRequired: true })
	connectBusinessType!: constants.ConnectBusinessType;

	@GandalfConstantDecorator(constants.ConnectCompanyType)
	@GandalfLabel('Company Type')
	@GandalfValidator({ notNull: { message: 'Company Type is required' } })
	@GandalfProperty({ designType: constants.ConnectCompanyType, isRequired: true })
	connectCompanyType!: constants.ConnectCompanyType;

	@GandalfLabel('Practice Legal Business FEIN')
	@GandalfValidator({ notNull: { message: 'Practice Legal Business FEIN is required' } })
	@GandalfValidator({ sizeString: { message: 'Practice Legal Business FEIN must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	connectFein!: string;

	@GandalfLabel('Connect Practice')
	@GandalfValidator({ notNull: { message: 'Connect Practice is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	connectPracticeId!: number;

	@GandalfLabel('Practice Website URL')
	@GandalfValidator({ notNull: { message: 'Practice Website URL is required' } })
	@GandalfValidator({ sizeString: { message: 'Practice Website URL must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	connectWebsite!: string;

	@GandalfConstantDecorator(constants.StockExchange)
	@GandalfLabel('Stock Exchange')
	@GandalfProperty({ designType: constants.StockExchange })
	stockExchange!: constants.StockExchange | null;

	@GandalfLabel('Stock Ticker Symbol')
	@GandalfValidator({ sizeString: { message: 'Stock Ticker Symbol cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	stockTicker!: string | null;

}
