// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.CreateContactLensPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateContactLensPrescriptionRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfValidator({ notNull: { message: 'Authorization Type is required' } })
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType, isRequired: true })
	authorizationType!: constants.PrescriptionAuthorizationType;

	@GandalfValidator({ notNull: { message: 'Authorize Prescription indicator is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	authorizePrescription!: boolean;

	@GandalfProperty({ designType: Number })
	authorizedByProviderId!: number | null;

	@GandalfProperty({ designType: Number })
	deliveryMethodReferenceId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Drop cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	dropsName!: string | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Prescribed Enzymatic Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	enzymaticCleanerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	lensCleanerName!: string | null;

	@GandalfConstantDecorator(constants.ContactLensModality)
	@GandalfProperty({ designType: constants.ContactLensModality })
	modality!: constants.ContactLensModality | null;

	@GandalfSubRequestLabel('OD')
	@GandalfProperty({ designType: ContactLensEyeRequest })
	od!: ContactLensEyeRequest | null;

	@GandalfProperty({ designType: Number })
	odDispenseAmount!: number | null;

	@GandalfProperty({ designType: Number })
	odDispenseUnitId!: number | null;

	@GandalfSubRequestLabel('OS')
	@GandalfProperty({ designType: ContactLensEyeRequest })
	os!: ContactLensEyeRequest | null;

	@GandalfProperty({ designType: Number })
	osDispenseAmount!: number | null;

	@GandalfProperty({ designType: Number })
	osDispenseUnitId!: number | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType, isRequired: true })
	overallLensType!: constants.ContactLensPrescriptionType;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfValidator({ sizeString: { message: 'Prescribed Disinfection Solution cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	solutionName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfValidator({ notNull: { message: 'Use Signature is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	useSignature!: boolean;

}
