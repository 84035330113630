// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.healthconcern.PatientHealthConcernResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientHealthConcernResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	concern!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateReported!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	inactiveDate!: Date | null;

	@GandalfProperty({ designType: Number })
	patientHealthConcernId!: number | null;

	@GandalfConstantDecorator(constants.PatientHealthConcernReporter)
	@GandalfProperty({ designType: constants.PatientHealthConcernReporter })
	reporter!: constants.PatientHealthConcernReporter | null;

	@GandalfConstantDecorator(constants.PatientHealthConcernStatus)
	@GandalfProperty({ designType: constants.PatientHealthConcernStatus })
	status!: constants.PatientHealthConcernStatus | null;

	@GandalfConstantDecorator(constants.PatientHealthConcernType)
	@GandalfProperty({ designType: constants.PatientHealthConcernType })
	type!: constants.PatientHealthConcernType | null;

}
