// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

/** See com.rev360.pms.api.controller.order.OrderVendorResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderVendorResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	accountNumber!: string | null;

	@GandalfProperty({ designType: AddressResponse })
	address!: AddressResponse | null;

	@GandalfProperty({ designType: String })
	externalId!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: BusinessPhonesResponse })
	phones!: BusinessPhonesResponse | null;

	@GandalfProperty({ designType: String })
	smartflowCode!: string | null;

	@GandalfProperty({ designType: Number })
	smartflowMasterSupplierId!: number | null;

	@GandalfConstantDecorator(constants.BusinessStatus)
	@GandalfProperty({ designType: constants.BusinessStatus })
	status!: constants.BusinessStatus | null;

}
