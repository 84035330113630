import 'reflect-metadata';
import { Type } from '@angular/core';
import { setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';
import { GandalfConstant } from '../gandalf.constants';

/**
 * Metadata key indicating the exact type of a GandalfConstant property
 */
export const GANDALF_CONSTANT_METADATA_KEY = Symbol('GandalfConstant');

/**
 * Decorator for a property whose type is a Gandalf Constant.
 *
 * This decorator stores the appropriate Gandalf metadata about the constant, so it can be accessed by other Gandalf utilities.
 */
export function GandalfConstantDecorator(type: Type<GandalfConstant<any>>) {
	return (target: object, propertyKey: string) => {
		// Store the type of constant in metadata
		setMetadataPropertyValue(target, GANDALF_CONSTANT_METADATA_KEY, propertyKey, type);
	};
}
