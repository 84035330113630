// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderDashboardSortRequest } from './order-dashboard-sort-request';

/** See com.rev360.pms.api.controller.order.OrdersAdvancedSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class OrdersAdvancedSearchRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDateEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDateStart!: Date | null;

	@GandalfValidator({ notNull: { message: 'Limit is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Limit must be greater than 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	limit!: number;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: Number })
	masterOrderProcessorId!: number | null;

	@GandalfValidator({ notNull: { message: 'Offset is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Offset must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	offset!: number;

	@GandalfProperty({ designType: Boolean })
	onHold!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	orderDateEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	orderDateStart!: Date | null;

	@GandalfValidator({ min: { min: 1, message: 'Order # may not be less than 1' } })
	@GandalfValidator({ max: { max: 9223372036854775807, message: 'Order # may not be more than 19 digits' } })
	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderSearchStatusCode)
	@GandalfProperty({ designType: constants.OrderSearchStatusCode })
	orderSearchStatusCode!: constants.OrderSearchStatusCode | null;

	@GandalfConstantDecorator(constants.OrderSearchType)
	@GandalfProperty({ designType: constants.OrderSearchType })
	orderSearchType!: constants.OrderSearchType | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: Boolean })
	patientNotNotified!: boolean | null;

	@GandalfArray(OrderDashboardSortRequest)
	sort!: OrderDashboardSortRequest[] | null;

	@GandalfProperty({ designType: Number })
	total!: number | null;

	@GandalfProperty({ designType: Number })
	vendorId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Vendor Order #', maxLength: 1000, minLength: 0, message: 'Vendor Order # must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	vendorOrderId!: string | null;

}
