// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.preferences.PatientSchedulingPreferenceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientSchedulingPreferenceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowPhrScheduling!: boolean | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	friday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfProperty({ designType: String })
	generalNote!: string | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	monday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	saturday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesScheduleRisk)
	@GandalfProperty({ designType: constants.SchedulingPreferencesScheduleRisk })
	scheduleRisk!: constants.SchedulingPreferencesScheduleRisk | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	sunday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	thursday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	tuesday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty({ designType: constants.SchedulingPreferencesTimeOfDay })
	wednesday!: constants.SchedulingPreferencesTimeOfDay | null;

}
