// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.RxSelectEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class RxSelectEyeglassResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	eyeglassPrescriptionId!: number | null;

	@GandalfProperty({ designType: Number })
	odAxis!: number | null;

	@GandalfProperty({ designType: Boolean })
	odBalanced!: boolean | null;

	@GandalfProperty({ designType: Number })
	odCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	odHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	odHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	odNearAdd!: number | null;

	@GandalfProperty({ designType: Number })
	odPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	odPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	odSphere!: number | null;

	@GandalfProperty({ designType: Number })
	osAxis!: number | null;

	@GandalfProperty({ designType: Boolean })
	osBalanced!: boolean | null;

	@GandalfProperty({ designType: Number })
	osCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	osHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	osHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osNearAdd!: number | null;

	@GandalfProperty({ designType: Number })
	osPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	osPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osSphere!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ designType: String })
	usedForReasonName!: string | null;

}
