// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.InvoiceItemPracticeDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemPracticeDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	active!: boolean | null;

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	disabledDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ designType: Number })
	masterCodeSetId!: number;

	@GandalfProperty({ designType: String })
	shortDescription!: string | null;

}
