// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.patient.PatientDuplicateSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDuplicateSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dateOfBirth!: Date | null;

	@GandalfProperty({ designType: String })
	last4Ssn!: string | null;

	@GandalfConstantDecorator(constants.PatientDuplicateMatchType)
	@GandalfProperty({ designType: constants.PatientDuplicateMatchType })
	matchType!: constants.PatientDuplicateMatchType | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientNameResponse!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	ssn!: string | null;

}
