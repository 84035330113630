// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.age.BasicAgeReportRequest */
/* istanbul ignore next */
@GandalfModel
export class BasicAgeReportRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	createdOn!: Date;

	@GandalfValidator({ sizeString: { fieldLabel: 'Filter content', maxLength: 1000, minLength: 0, message: 'Filter content must be less than or equal to 1000 characters' } })
	@GandalfProperty({ designType: String })
	generalFilter!: string | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductCategoryId!: number | null;

}
