// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PhoneNumbersResponse */
/* istanbul ignore next */
@GandalfModel
export class PhoneNumbersResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	cellPhone!: string | null;

	@GandalfProperty({ designType: String })
	cellPhoneExt!: string | null;

	@GandalfProperty({ designType: String })
	fax!: string | null;

	@GandalfProperty({ designType: String })
	homePhone!: string | null;

	@GandalfProperty({ designType: String })
	homePhoneExt!: string | null;

	@GandalfProperty({ designType: String })
	pager!: string | null;

	@GandalfProperty({ designType: String })
	preferredPhoneNumber!: string | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfProperty({ designType: constants.PreferredPhoneType })
	preferredPhoneType!: constants.PreferredPhoneType | null;

	@GandalfProperty({ designType: String })
	workPhone!: string | null;

	@GandalfProperty({ designType: String })
	workPhoneExt!: string | null;

}
