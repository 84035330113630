// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.encounter.appointmenthistory.AppointmentExamHistoryListResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentExamHistoryListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	appointmentId!: number | null;

	@GandalfProperty({ designType: Number })
	employeeId!: number | null;

	@GandalfProperty({ designType: PersonNameResponse })
	employeeName!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfProperty({ designType: PersonNameResponse })
	providerName!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	roleName!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	scheduleItemStatus!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ designType: String })
	subTypeValue!: string | null;

	@GandalfProperty({ designType: String })
	templateName!: string | null;

}
