// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.QueryTriggerSourceResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryTriggerSourceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	interventionBibliographicCitation!: string | null;

	@GandalfProperty({ designType: String })
	interventionDeveloper!: string | null;

	@GandalfProperty({ designType: String })
	interventionDeveloperFunding!: string | null;

	@GandalfProperty({ designType: Date })
	interventionReleaseDate!: Date | null;

	@GandalfProperty({ designType: Date })
	interventionRevisionDate!: Date | null;

	@GandalfProperty({ designType: String })
	useOfDateOfBirth!: string | null;

	@GandalfProperty({ designType: String })
	useOfEthnicity!: string | null;

	@GandalfProperty({ designType: String })
	useOfGenderIdentity!: string | null;

	@GandalfProperty({ designType: String })
	useOfHealthStatusAssessments!: string | null;

	@GandalfProperty({ designType: String })
	useOfLanguage!: string | null;

	@GandalfProperty({ designType: String })
	useOfRace!: string | null;

	@GandalfProperty({ designType: String })
	useOfSex!: string | null;

	@GandalfProperty({ designType: String })
	useOfSexualOrientation!: string | null;

	@GandalfProperty({ designType: String })
	useOfSocialDeterminants!: string | null;

}
