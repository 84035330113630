// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceDashboardResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceDashboardResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	adjustmentTotal!: number;

	@GandalfProperty({ designType: Boolean })
	approval!: boolean;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ designType: Number })
	invoiceAge!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	invoiceDate!: Date;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	payerName!: string;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType;

	@GandalfProperty({ designType: Number })
	processingPaymentGroupId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	statementDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty({ designType: constants.InvoiceStatus })
	status!: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	total!: number;

}
