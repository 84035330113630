// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminDocumentTemplateFieldCategoryResponse } from './admin-document-template-field-category-response';

/** See com.rev360.pms.api.controller.admin.communicationtemplate.AdminDocumentTemplateCategoryResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminDocumentTemplateCategoryResponse extends GandalfModelBase {

	@GandalfArray(AdminDocumentTemplateFieldCategoryResponse)
	fieldCategories!: AdminDocumentTemplateFieldCategoryResponse[];

	@GandalfConstantDecorator(constants.TemplateCategory)
	@GandalfProperty({ designType: constants.TemplateCategory })
	templateCategory!: constants.TemplateCategory;

}
