// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.SalesReportResponse */
/* istanbul ignore next */
@GandalfModel
export class SalesReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	adjustmentId!: number | null;

	@GandalfConstantDecorator(constants.InvoiceItemAdjustmentType)
	@GandalfProperty({ designType: constants.InvoiceItemAdjustmentType })
	adjustmentType!: constants.InvoiceItemAdjustmentType | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	adjustments!: number | null;

	@GandalfProperty({ designType: String })
	categoryId!: string | null;

	@GandalfProperty({ designType: String })
	categoryName!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	discounts!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	gross!: number | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number | null;

	@GandalfProperty({ designType: String })
	itemDescription!: string | null;

	@GandalfProperty({ designType: Number })
	itemId!: number | null;

	@GandalfProperty({ designType: String })
	itemName!: string | null;

	@GandalfProperty({ designType: String })
	itemNameId!: string | null;

	@GandalfProperty({ designType: Number })
	itemTaxId!: number | null;

	@GandalfProperty({ designType: String })
	itemTaxName!: string | null;

	@GandalfConstantDecorator(constants.InvoiceItemType)
	@GandalfProperty({ designType: constants.InvoiceItemType })
	itemType!: constants.InvoiceItemType | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: Number })
	manufacturerId!: number | null;

	@GandalfProperty({ designType: String })
	manufacturerName!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	net!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfProperty({ designType: Number })
	payerId!: number | null;

	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfProperty({ designType: PersonNameResponse })
	provider!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	qtySold!: number | null;

	@GandalfProperty({ designType: String })
	reportCategoryId!: string | null;

	@GandalfProperty({ designType: String })
	reportCategoryName!: string | null;

	@GandalfProperty({ designType: String })
	reportName!: string | null;

	@GandalfProperty({ designType: String })
	reportNameId!: string | null;

	@GandalfProperty({ designType: String })
	reportType!: string | null;

	@GandalfProperty({ designType: String })
	reportTypeString!: string | null;

	@GandalfProperty({ designType: PersonNameResponse })
	responsibleForSale!: PersonNameResponse | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	taxes!: number | null;

}
