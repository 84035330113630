// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.inventory.reconciliation.ReconciliationReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ReconciliationReportResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	countedActualCost!: number | null;

	@GandalfProperty({ designType: Number })
	countedItems!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	countedValue!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	countedWholesalePrice!: number | null;

	@GandalfProperty({ designType: UserSummaryResponse })
	createdBy!: UserSummaryResponse | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: UserSummaryResponse })
	finalizedBy!: UserSummaryResponse | null;

	@GandalfProperty({ designType: Date })
	finalizedOn!: Date | null;

	@GandalfProperty({ designType: Boolean })
	isReconciled!: boolean | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reconciledActualCost!: number | null;

	@GandalfProperty({ designType: UserSummaryResponse })
	reconciledBy!: UserSummaryResponse | null;

	@GandalfProperty({ designType: Number })
	reconciledItems!: number | null;

	@GandalfProperty({ designType: Date })
	reconciledOn!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reconciledValue!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reconciledWholesalePrice!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reportedActualCost!: number | null;

	@GandalfProperty({ designType: Number })
	reportedItems!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reportedValue!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	reportedWholesalePrice!: number | null;

}
