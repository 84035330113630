// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateMedicationPrescriptionRequest } from './create-medication-prescription-request';

/** See com.rev360.pms.api.controller.prescription.UpdateMedicationPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateMedicationPrescriptionRequest extends CreateMedicationPrescriptionRequest {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfValidator({ notNull: { message: 'Authorization Type is required' } })
	@GandalfProperty({ designType: constants.PrescriptionAuthorizationType, isRequired: true })
	authorizationType!: constants.PrescriptionAuthorizationType;

	@GandalfValidator({ notNull: { message: 'Authorize Prescription is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	authorizePrescription!: boolean;

	@GandalfProperty({ designType: Number })
	authorizedByProviderId!: number | null;

	@GandalfProperty({ designType: Number })
	bodyLocationId!: number | null;

	@GandalfProperty({ designType: Number })
	diagnosisId!: number | null;

	@GandalfValidator({ min: { min: 0, message: 'Dispensing Info must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number })
	dispenseAmount!: number | null;

	@GandalfProperty({ designType: Number })
	dispenseUnitId!: number | null;

	@GandalfValidator({ notNull: { message: 'Do Not Substitute is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	doNotSubstitute!: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Dosage Amount must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number })
	dosageAmount!: number | null;

	@GandalfProperty({ designType: Number })
	dosageUnitId!: number | null;

	@GandalfValidator({ notNull: { message: 'Drug is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	drugId!: number;

	@GandalfValidator({ min: { min: 0, message: 'Duration must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	frequencyId!: number | null;

	@GandalfValidator({ notNull: { message: 'ID is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfValidator({ notNull: { message: 'Originated Without CPOE is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	nonCpoeEntry!: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Number of Refills must be greater than or equal to 0' } })
	@GandalfProperty({ designType: Number })
	numRefillsAllowed!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfProperty({ designType: Number })
	routeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	startDate!: Date;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ designType: constants.PrescriptionStatus, isRequired: true })
	status!: constants.PrescriptionStatus;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	stopDate!: Date | null;

	@GandalfValidator({ notNull: { message: 'Use Provider Signature is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	useProviderSignature!: boolean;

}
