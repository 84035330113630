// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.calendar.AppointmentCalendarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentCalendarResponse extends ScheduleItemCalendarResponse {

	@GandalfProperty({ designType: Boolean })
	confirmed!: boolean | null;

	@GandalfProperty({ designType: Number })
	displayColor!: number | null;

	@GandalfProperty({ designType: EmployeeNameResponse })
	employee!: EmployeeNameResponse | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty({ designType: constants.EncounterStatus })
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty({ designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: Number })
	groupingPersonId!: number | null;

	@GandalfProperty({ designType: Boolean })
	hasInterviewTemplate!: boolean | null;

	@GandalfProperty({ designType: String })
	insuranceVerificationComment!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty({ designType: constants.VerificationStatus })
	insuranceVerificationStatus!: constants.VerificationStatus | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	patientDob!: Date | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: ProviderResponse })
	provider!: ProviderResponse | null;

	@GandalfProperty({ designType: Number })
	roleId!: number | null;

	@GandalfProperty({ designType: String })
	roleName!: string | null;

	@GandalfProperty({ designType: Number })
	scheduleItemId!: number | null;

	@GandalfProperty({ designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ designType: String })
	subType!: string | null;

	@GandalfProperty({ designType: String })
	template!: string | null;

	@GandalfProperty({ designType: Number })
	templateDisplayColor!: number | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty({ designType: constants.ScheduleItemType })
	type!: constants.ScheduleItemType | null;

	@GandalfProperty({ designType: Date })
	updatedOn!: Date | null;

}
