// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientPodLastEncounterResponse } from './patient-pod-last-encounter-response';

// @ts-ignore
import { PatientPodNextAppointmentResponse } from './patient-pod-next-appointment-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodLastAndNextEncounterResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodLastAndNextEncounterResponse extends GandalfModelBase {

	@GandalfProperty({ designType: PatientPodLastEncounterResponse })
	lastEncounterResponse!: PatientPodLastEncounterResponse | null;

	@GandalfProperty({ designType: PatientPodNextAppointmentResponse })
	nextAppointmentResponse!: PatientPodNextAppointmentResponse | null;

}
