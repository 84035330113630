// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.shared.patient.order.EyeglassOrderSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassOrderSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfProperty({ designType: Number })
	eyeglassPrescriptionId!: number | null;

	@GandalfProperty({ designType: Number })
	odAxis!: number | null;

	@GandalfProperty({ designType: Boolean })
	odBalance!: boolean | null;

	@GandalfProperty({ designType: Number })
	odCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	odHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	odHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	odNearAdd!: number | null;

	@GandalfProperty({ designType: Number })
	odSphere!: number | null;

	@GandalfProperty({ designType: Number })
	odVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	odVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osAxis!: number | null;

	@GandalfProperty({ designType: Boolean })
	osBalance!: boolean | null;

	@GandalfProperty({ designType: Number })
	osCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	osHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty({ designType: constants.HorizontalPrismOrientation })
	osHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty({ designType: Number })
	osNearAdd!: number | null;

	@GandalfProperty({ designType: Number })
	osSphere!: number | null;

	@GandalfProperty({ designType: Number })
	osVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty({ designType: constants.VerticalPrismOrientation })
	osVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	usedFor!: ReferenceDataSummaryResponse | null;

}
