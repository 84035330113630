// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { EncounterRoleResponse } from './encounter-role-response';

// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

/** See com.rev360.pms.api.controller.admin.employee.AdminEmployeeForEmployeeListResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeForEmployeeListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	address!: string | null;

	@GandalfArray(EncounterRoleResponse)
	encounterRoles!: EncounterRoleResponse[] | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: EmployeeNameResponse })
	name!: EmployeeNameResponse | null;

	@GandalfProperty({ designType: String })
	preferredPhone!: string | null;

	@GandalfProperty({ designType: String })
	primaryLocationName!: string | null;

	@GandalfConstantDecorator(constants.EmployeeStatus)
	@GandalfProperty({ designType: constants.EmployeeStatus })
	status!: constants.EmployeeStatus | null;

	@GandalfArray(SecurityRoleResponse)
	userRoles!: SecurityRoleResponse[] | null;

	@GandalfProperty({ designType: String })
	username!: string | null;

}
