// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.UpdateInvoiceItemRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceItemRequest extends GandalfModelBase {

	@GandalfLabel('Selected Diagnoses')
	@GandalfValidator({ notNull: { message: 'Selected Diagnoses is required' } })
	@GandalfArray(Number)
	additionalDiagnoses!: number[];

	@GandalfLabel('Additional Modifier 1')
	@GandalfProperty({ designType: Number })
	additionalModifierId0!: number | null;

	@GandalfLabel('Additional Modifier 2')
	@GandalfProperty({ designType: Number })
	additionalModifierId1!: number | null;

	@GandalfLabel('Additional Modifier 3')
	@GandalfProperty({ designType: Number })
	additionalModifierId2!: number | null;

	@GandalfLabel('Additional Modifier 4')
	@GandalfProperty({ designType: Number })
	additionalModifierId3!: number | null;

	@GandalfLabel('Claim Note')
	@GandalfValidator({ sizeString: { message: 'Claim Note cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty({ designType: String })
	claimNote!: string | null;

	@GandalfConstantDecorator(constants.ClaimNoteCode)
	@GandalfLabel('Claim Note Code')
	@GandalfProperty({ designType: constants.ClaimNoteCode })
	claimNoteCode!: constants.ClaimNoteCode | null;

	@GandalfLabel('Code')
	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	description!: string;

	@GandalfLabel('Dosage')
	@GandalfValidator({ sizeString: { message: 'Dosage cannot be longer than 17 characters', minLength: 0, maxLength: 17 } })
	@GandalfProperty({ designType: String })
	dosageAmount!: string | null;

	@GandalfConstantDecorator(constants.DosageUnitCode)
	@GandalfLabel('Dosage Unit')
	@GandalfProperty({ designType: constants.DosageUnitCode })
	dosageUnit!: constants.DosageUnitCode | null;

	@GandalfLabel('Emerg. Svc.')
	@GandalfValidator({ sizeString: { message: 'Emerg. Svc. cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty({ designType: String })
	emergencyService!: string | null;

	@GandalfLabel('EPSDT')
	@GandalfValidator({ sizeString: { message: 'EPSDT cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty({ designType: String })
	epsdt!: string | null;

	@GandalfLabel('External Provider')
	@GandalfProperty({ designType: Number })
	externalProviderId!: number | null;

	@GandalfConstantDecorator(constants.FacilityType)
	@GandalfLabel('Facility Type')
	@GandalfProperty({ designType: constants.FacilityType })
	facilityType!: constants.FacilityType | null;

	@GandalfLabel('Invoice Item')
	@GandalfValidator({ notNull: { message: 'Invoice Item is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	invoiceItemId!: number;

	@GandalfLabel('Remove Adjustments')
	@GandalfValidator({ notNull: { message: 'Remove Adjustments is required' } })
	@GandalfArray(Number)
	itemAdjustmentIdsToRemove!: number[];

	@GandalfLabel('NDC')
	@GandalfValidator({ sizeString: { message: 'NDC cannot be longer than 13 characters', minLength: 0, maxLength: 13 } })
	@GandalfProperty({ designType: String })
	nationalDrugCode!: string | null;

	@GandalfLabel('Employee')
	@GandalfProperty({ designType: Number })
	responsiblePersonId!: number | null;

	@GandalfLabel('Rx #')
	@GandalfValidator({ sizeString: { message: 'Rx # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty({ designType: String })
	rxNumber!: string | null;

	@GandalfConstantDecorator(constants.RxQualifier)
	@GandalfLabel('Rx Qualifier')
	@GandalfProperty({ designType: constants.RxQualifier })
	rxQualifier!: constants.RxQualifier | null;

	@GandalfLabel('Service End Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceEndDate!: Date | null;

	@GandalfLabel('Service Location')
	@GandalfProperty({ designType: Number })
	serviceLocationId!: number | null;

	@GandalfLabel('Service Start Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceStartDate!: Date | null;

}
