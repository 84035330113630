// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.contact.ContactListResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	email!: string | null;

	@GandalfConstantDecorator(constants.EmergencyContactType)
	@GandalfProperty({ designType: constants.EmergencyContactType })
	emergencyContactType!: constants.EmergencyContactType | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isEmergencyContact!: boolean | null;

	@GandalfProperty({ designType: PersonNameResponse })
	name!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	phone!: string | null;

	@GandalfProperty({ designType: ReferenceDataSummaryResponse })
	role!: ReferenceDataSummaryResponse | null;

}
