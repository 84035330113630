// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.examtestlibrary.AdminCreateWorkflowTestTemplateSvcTriggerRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCreateWorkflowTestTemplateSvcTriggerRequest extends GandalfModelBase {

	@GandalfArray(Number)
	formFieldIds!: number[] | null;

	@GandalfProperty({ designType: Number })
	serviceId!: number | null;

	@GandalfValidator({ notNull: { message: 'Template is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	templateId!: number;

	@GandalfConstantDecorator(constants.TriggerRuleType)
	@GandalfValidator({ notNull: { message: 'Trigger Rule is required' } })
	@GandalfProperty({ designType: constants.TriggerRuleType, isRequired: true })
	triggerRuleType!: constants.TriggerRuleType;

}
