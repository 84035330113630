// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseInventoryReportResponse } from './base-inventory-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.EyeglassFrameInventoryReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassFrameInventoryReportResponse extends BaseInventoryReportResponse {

	@GandalfProperty({ designType: String })
	brand!: string | null;

	@GandalfProperty({ designType: Number })
	bridge!: number | null;

	@GandalfProperty({ designType: String })
	category!: string | null;

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ designType: String })
	collection!: string | null;

	@GandalfProperty({ designType: String })
	color!: string | null;

	@GandalfProperty({ designType: String })
	colorCode!: string | null;

	@GandalfProperty({ designType: Number })
	eye!: number | null;

	@GandalfProperty({ designType: String })
	itemDescription!: string | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	locationProductActualCost!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

	@GandalfProperty({ designType: String })
	manufacturer!: string | null;

	@GandalfProperty({ designType: String })
	model!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	retail!: number | null;

	@GandalfProperty({ designType: String })
	sku!: string | null;

	@GandalfProperty({ designType: Number })
	stock!: number | null;

	@GandalfProperty({ designType: Number })
	temple!: number | null;

	@GandalfProperty({ designType: String })
	upc!: string | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	wholesale!: number | null;

}
