// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EncounterMedicalDecisionMakingCodingResponse } from './encounter-medical-decision-making-coding-response';

// @ts-ignore
import { EncounterProfessionalTimeCodingResponse } from './encounter-professional-time-coding-response';

/** See com.rev360.pms.api.controller.encounter.EncounterAutoCodePreviewResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterAutoCodePreviewResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	isFinalCptGenerated!: boolean | null;

	@GandalfProperty({ designType: EncounterMedicalDecisionMakingCodingResponse })
	medicalDecisionMaking!: EncounterMedicalDecisionMakingCodingResponse | null;

	@GandalfProperty({ designType: EncounterProfessionalTimeCodingResponse })
	professionalTime!: EncounterProfessionalTimeCodingResponse | null;

}
