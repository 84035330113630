// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.PaymentDashboardResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentDashboardResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number;

	@GandalfProperty({ designType: String })
	payerName!: string;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	paymentDate!: Date;

	@GandalfProperty({ designType: Number })
	paymentGroupId!: number;

	@GandalfConstantDecorator(constants.PaymentGroupSourceTypeSearch)
	@GandalfProperty({ designType: constants.PaymentGroupSourceTypeSearch })
	paymentGroupSourceTypeSearch!: constants.PaymentGroupSourceTypeSearch;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfConstantDecorator(constants.PaymentGroupStatus)
	@GandalfProperty({ designType: constants.PaymentGroupStatus })
	paymentStatus!: constants.PaymentGroupStatus;

	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.PaymentGroupSourceType)
	@GandalfProperty({ designType: constants.PaymentGroupSourceType })
	sourceType!: constants.PaymentGroupSourceType;

}
