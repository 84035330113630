// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.PatientDuplicateSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientDuplicateSearchRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'DOB is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date, isRequired: true })
	dateOfBirth!: Date;

	@GandalfValidator({ notNull: { message: 'First name is required' } })
	@GandalfValidator({ sizeString: { message: 'First name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ designType: String, isRequired: true })
	firstName!: string;

	@GandalfValidator({ sizeString: { message: 'Last 4 SSN cannot be longer than 4 characters', minLength: 0, maxLength: 4 } })
	@GandalfProperty({ designType: String })
	last4Ssn!: string | null;

	@GandalfValidator({ notNull: { message: 'Last name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	lastName!: string;

	@GandalfValidator({ sizeString: { message: 'SIN/PHN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty({ designType: String })
	sin!: string | null;

	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfValidator({ sizeString: { message: 'SSN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfProperty({ designType: String })
	socialSecurityNumber!: string | null;

}
