// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InsuranceClaimStatusResponse } from './insurance-claim-status-response';

/** See com.rev360.pms.api.controller.accounting.claims.InvoiceInsuranceClaimResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceInsuranceClaimResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	claimDate!: Date;

	@GandalfArray(InsuranceClaimStatusResponse)
	claimStatusMessages!: InsuranceClaimStatusResponse[];

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfProperty({ designType: constants.ClaimSubmitMechanism })
	claimSubmissionMechanism!: constants.ClaimSubmitMechanism;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	claimSubmitDate!: Date | null;

	@GandalfProperty({ designType: String })
	externalMessage!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ designType: String })
	priority!: string | null;

	@GandalfConstantDecorator(constants.ClaimProcessingStatus)
	@GandalfProperty({ designType: constants.ClaimProcessingStatus })
	processingStatus!: constants.ClaimProcessingStatus;

	@GandalfConstantDecorator(constants.InsuranceClaimStatus)
	@GandalfProperty({ designType: constants.InsuranceClaimStatus })
	status!: constants.InsuranceClaimStatus;

}
