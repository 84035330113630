// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.SearchActivePracticeDiagnosesForAssessmentRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchActivePracticeDiagnosesForAssessmentRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfValidator({ notNull: { message: 'Code set is required' } })
	@GandalfProperty({ designType: constants.CodeSet, isRequired: true })
	codeSet!: constants.CodeSet;

	@GandalfValidator({ sizeString: { fieldLabel: 'Description', maxLength: 1000, minLength: 3, message: 'Description must be between 3 and 1000 characters' } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfValidator({ notNull: { message: 'Encounter Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	encounterId!: number;

}
