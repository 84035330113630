// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.reporting.accounting.UnassignedServiceResponse */
/* istanbul ignore next */
@GandalfModel
export class UnassignedServiceResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	code!: string | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	encounterDate!: Date | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patient!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	performedServiceId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	price!: number | null;

	@GandalfProperty({ designType: ProviderResponse })
	provider!: ProviderResponse | null;

}
