// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.encounter.dynamic.WorkflowTestRequest */
/* istanbul ignore next */
@GandalfModel
export class WorkflowTestRequest extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfProperty({ designType: Number })
	fileCount!: number | null;

	@GandalfProperty({ designType: Boolean })
	hasTestValuesChanged!: boolean | null;

	@GandalfArray(FieldValueRequest)
	values!: FieldValueRequest[] | null;

	@GandalfLabel('Version')
	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	version!: number;

	@GandalfProperty({ designType: Number })
	workflowTestId!: number | null;

}
