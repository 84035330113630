// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.inventory.reconciliation.ReconciliationBatchResponse */
/* istanbul ignore next */
@GandalfModel
export class ReconciliationBatchResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	countedItems!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	countedValue!: number | null;

	@GandalfProperty({ designType: UserSummaryResponse })
	createdBy!: UserSummaryResponse | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

}
