// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminOpenEdgeCardReaderResponse } from './admin-open-edge-card-reader-response';

/** See com.rev360.pms.api.controller.admin.solutions.openedge.OpenEdgeSettingsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeSettingsResponse extends GandalfModelBase {

	@GandalfArray(AdminOpenEdgeCardReaderResponse)
	cardReaders!: AdminOpenEdgeCardReaderResponse[] | null;

	@GandalfProperty({ designType: Boolean })
	isActive!: boolean | null;

	@GandalfProperty({ designType: String })
	motoAccountAuthKey!: string | null;

	@GandalfProperty({ designType: String })
	motoAccountTerminalId!: string | null;

	@GandalfProperty({ designType: String })
	motoAccountXWebId!: string | null;

	@GandalfProperty({ designType: Number })
	openEdgeSettingsId!: number | null;

	@GandalfProperty({ designType: Boolean })
	payOnlineEnabled!: boolean | null;

	@GandalfProperty({ designType: String })
	payOnlineUrlPath!: string | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfProperty({ designType: String })
	retailAccountAuthKey!: string | null;

	@GandalfProperty({ designType: String })
	retailAccountTerminalId!: string | null;

	@GandalfProperty({ designType: String })
	retailAccountXWebId!: string | null;

	@GandalfProperty({ designType: Boolean })
	statementPayOnlineEnabled!: boolean | null;

}
