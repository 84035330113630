// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.alert.CreateAlertRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateAlertRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.AlertCategory)
	@GandalfLabel('Category')
	@GandalfValidator({ notNull: { message: 'Category is required' } })
	@GandalfProperty({ designType: constants.AlertCategory, isRequired: true })
	category!: constants.AlertCategory;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	description!: string;

	@GandalfConstantDecorator(constants.AlertDisplayType)
	@GandalfLabel('Display Type')
	@GandalfValidator({ notNull: { message: 'Display Type is required' } })
	@GandalfProperty({ designType: constants.AlertDisplayType, isRequired: true })
	displayType!: constants.AlertDisplayType;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfConstantDecorator(constants.AlertSeverity)
	@GandalfLabel('Severity')
	@GandalfValidator({ notNull: { message: 'Severity is required' } })
	@GandalfProperty({ designType: constants.AlertSeverity, isRequired: true })
	severity!: constants.AlertSeverity;

}
