// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FamilyHistoryRequest } from './family-history-request';

// @ts-ignore
import { OcularHistoryRequest } from './ocular-history-request';

// @ts-ignore
import { SocialHistoryRequest } from './social-history-request';

/** See com.rev360.pms.api.controller.encounter.history.pfsh.PfshRequest */
/* istanbul ignore next */
@GandalfModel
export class PfshRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: FamilyHistoryRequest })
	familyHistory!: FamilyHistoryRequest | null;

	@GandalfValidator({ notNull: { message: 'Ocular History is required' } })
	@GandalfProperty({ designType: OcularHistoryRequest, isRequired: true })
	ocularHistory!: OcularHistoryRequest;

	@GandalfValidator({ notNull: { message: 'Social History is required' } })
	@GandalfProperty({ designType: SocialHistoryRequest, isRequired: true })
	socialHistory!: SocialHistoryRequest;

}
