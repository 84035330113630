// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.InsuranceClaimStatusResponse */
/* istanbul ignore next */
@GandalfModel
export class InsuranceClaimStatusResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	claimStatusId!: number;

	@GandalfConstantDecorator(constants.ClaimStatusStatus)
	@GandalfProperty({ designType: constants.ClaimStatusStatus })
	status!: constants.ClaimStatusStatus;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	statusDate!: Date;

	@GandalfProperty({ designType: String })
	statusMessage!: string | null;

}
