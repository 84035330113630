// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpRequest extends ContactLensEyeRequest {

	@GandalfProperty({ designType: Number })
	addDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	addPower!: number | null;

	@GandalfProperty({ designType: Number })
	axis!: number | null;

	@GandalfProperty({ designType: Number })
	backOpticalDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurve!: number | null;

	@GandalfProperty({ designType: Number })
	baseCurve2!: number | null;

	@GandalfConstantDecorator(constants.BaseCurveUnit)
	@GandalfProperty({ designType: constants.BaseCurveUnit })
	baseCurveUnit!: constants.BaseCurveUnit | null;

	@GandalfProperty({ designType: Number })
	centerThickness!: number | null;

	@GandalfProperty({ designType: Number })
	cylinder!: number | null;

	@GandalfProperty({ designType: Number })
	diameter!: number | null;

	@GandalfProperty({ designType: Number })
	distanceZone!: number | null;

	@GandalfProperty({ designType: Boolean })
	dot!: boolean | null;

	@GandalfProperty({ designType: Number })
	edgeLiftAmount!: number | null;

	@GandalfProperty({ designType: Number })
	edgeLiftId!: number | null;

	@GandalfProperty({ designType: Boolean })
	fenestration!: boolean | null;

	@GandalfProperty({ designType: Number })
	firstCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	firstCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	fourthCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	fourthCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	intermediateSegment!: number | null;

	@GandalfProperty({ designType: Boolean })
	isMonovision!: boolean | null;

	@GandalfProperty({ designType: Number })
	landingZoneAngle!: number | null;

	@GandalfProperty({ designType: Number })
	lensMaterialId!: number | null;

	@GandalfProperty({ designType: Number })
	limbalClearanceId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductItemId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Notes cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	notes!: string | null;

	@GandalfProperty({ designType: Number })
	opticZone!: number | null;

	@GandalfProperty({ designType: Number })
	overnightDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	overnightDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	peripheralCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	peripheralCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	peripheralEdgeId!: number | null;

	@GandalfProperty({ designType: Boolean })
	plasmaTreatment!: boolean | null;

	@GandalfProperty({ designType: Number })
	productColorId!: number | null;

	@GandalfProperty({ designType: Number })
	profileId!: number | null;

	@GandalfProperty({ designType: Number })
	replacementDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	replacementDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	returnZoneDiameter!: number | null;

	@GandalfProperty({ designType: Number })
	sagittalDepth!: number | null;

	@GandalfProperty({ designType: Number })
	secondCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	secondCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	secondaryCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	secondaryCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	segmentHeight!: number | null;

	@GandalfProperty({ designType: Number })
	specialCharacteristicsId!: number | null;

	@GandalfProperty({ designType: Number })
	sphere!: number | null;

	@GandalfProperty({ designType: Number })
	sphere2!: number | null;

	@GandalfProperty({ designType: Number })
	thirdCurveRadius!: number | null;

	@GandalfProperty({ designType: Number })
	thirdCurveWidth!: number | null;

	@GandalfProperty({ designType: Number })
	truncation!: number | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
