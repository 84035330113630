// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdatePatientGeneralBenefitsSelfPolicyRequest } from './update-patient-general-benefits-self-policy-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationSelfPolicyRequest } from './update-patient-insurance-basic-information-self-policy-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsSelfPolicyRequest } from './update-patient-material-benefits-self-policy-request';

/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.selfpolicy.UpdatePersonInsuranceAndPolicyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePersonInsuranceAndPolicyRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Patient Benefits cannot be longer than 8000 characters', minLength: 0, maxLength: 8000 } })
	@GandalfProperty({ designType: String })
	patientBenefits!: string | null;

	@GandalfValidator({ notNull: { message: 'General Benefits is required' } })
	@GandalfProperty({ designType: UpdatePatientGeneralBenefitsSelfPolicyRequest, isRequired: true })
	patientGeneralBenefitsRequest!: UpdatePatientGeneralBenefitsSelfPolicyRequest;

	@GandalfValidator({ notNull: { message: 'Basic Information is required' } })
	@GandalfProperty({ designType: UpdatePatientInsuranceBasicInformationSelfPolicyRequest, isRequired: true })
	patientInsuranceBasicInformationRequest!: UpdatePatientInsuranceBasicInformationSelfPolicyRequest;

	@GandalfValidator({ notNull: { message: 'Material Benefits is required' } })
	@GandalfProperty({ designType: UpdatePatientMaterialBenefitsSelfPolicyRequest, isRequired: true })
	patientMaterialBenefitsRequest!: UpdatePatientMaterialBenefitsSelfPolicyRequest;

	@GandalfProperty({ designType: Number })
	personInsuranceId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Policy Benefits cannot be longer than 8000 characters', minLength: 0, maxLength: 8000 } })
	@GandalfProperty({ designType: String })
	policyHolderBenefits!: string | null;

}
