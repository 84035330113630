// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { SnomedCodeResponse } from './snomed-code-response';

/** See com.rev360.pms.api.controller.patient.surgeryhistory.SurgeryHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class SurgeryHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	eye!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: SnomedCodeResponse })
	snomed!: SnomedCodeResponse | null;

	@GandalfProperty({ designType: ProviderResponse })
	surgeon!: ProviderResponse | null;

	@GandalfProperty({ designType: Date })
	surgeryDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	surgeryDateEstimated!: boolean | null;

}
