// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationExternalSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationExternalSystemsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	active!: boolean | null;

	@GandalfConstantDecorator(constants.ExternalSystem)
	@GandalfProperty({ designType: constants.ExternalSystem })
	externalSystem!: constants.ExternalSystem | null;

	@GandalfProperty({ designType: String })
	password!: string | null;

	@GandalfProperty({ designType: String })
	username!: string | null;

}
