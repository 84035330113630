// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { ContactDemographicsRequest } from './contact-demographics-request';

// @ts-ignore
import { ContactInformationRequest } from './contact-information-request';

// @ts-ignore
import { CreateContactRequest } from './create-contact-request';

/** See com.rev360.pms.api.controller.patient.contact.UpdateContactRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateContactRequest extends CreateContactRequest {

	@GandalfValidator({ notNull: { message: 'Address is required' } })
	@GandalfProperty({ designType: AddressOptionalRequest, isRequired: true })
	address!: AddressOptionalRequest;

	@GandalfValidator({ notNull: { message: 'Contact Demographics is required' } })
	@GandalfProperty({ designType: ContactDemographicsRequest, isRequired: true })
	contactDemographics!: ContactDemographicsRequest;

	@GandalfLabel('Contact Information')
	@GandalfProperty({ designType: ContactInformationRequest })
	contactInformation!: ContactInformationRequest | null;

	@GandalfValidator({ notNull: { message: 'Contact is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfLabel('Emergency Contact')
	@GandalfProperty({ designType: Boolean })
	isEmergencyContact!: boolean | null;

	@GandalfLabel('Primary Contact')
	@GandalfProperty({ designType: Boolean })
	isPrimaryEmergencyContact!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	patientId!: number;

	@GandalfValidator({ notNull: { message: 'Role is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	roleId!: number;

}
