// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.billing.BillingInvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class BillingInvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number | null;

	@GandalfConstantDecorator(constants.BusinessStatus)
	@GandalfProperty({ designType: constants.BusinessStatus })
	businessStatus!: constants.BusinessStatus | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Date })
	invoiceDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty({ designType: constants.InvoiceStatus })
	invoiceStatus!: constants.InvoiceStatus | null;

	@GandalfProperty({ designType: Boolean })
	isPreviousPayer!: boolean | null;

	@GandalfProperty({ designType: Number })
	locationId!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfProperty({ designType: Number })
	payerId!: number | null;

	@GandalfProperty({ designType: Number })
	payerPersonInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty({ designType: constants.PersonInsuranceStatus })
	personInsuranceStatus!: constants.PersonInsuranceStatus | null;

	@GandalfConstantDecorator(constants.BillingInvoiceStatus)
	@GandalfProperty({ designType: constants.BillingInvoiceStatus })
	status!: constants.BillingInvoiceStatus | null;

}
