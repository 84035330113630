// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

/** See com.rev360.pms.api.controller.admin.employee.detailed.response.AdminEmployeeProviderDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeProviderDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	apiKey!: string | null;

	@GandalfProperty({ designType: String })
	deaNumber!: string | null;

	@GandalfProperty({ designType: Number })
	displayColor!: number | null;

	@GandalfProperty({ designType: String })
	einNumber!: string | null;

	@GandalfProperty({ designType: Boolean })
	employeeIsProvider!: boolean | null;

	@GandalfProperty({ designType: Number })
	externalPhotoId!: number | null;

	@GandalfArray(ProviderInsuranceIdentifierResponse)
	insuranceIdentifiers!: ProviderInsuranceIdentifierResponse[] | null;

	@GandalfProperty({ designType: String })
	licenseNumber!: string | null;

	@GandalfProperty({ designType: String })
	npi!: string | null;

	@GandalfProperty({ designType: String })
	portalDescription!: string | null;

	@GandalfProperty({ designType: String })
	practitionerNum!: string | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfProperty({ designType: Number })
	providerTypeId!: number | null;

	@GandalfProperty({ designType: String })
	registrationNum!: string | null;

	@GandalfProperty({ designType: Boolean })
	showPublic!: boolean | null;

	@GandalfProperty({ designType: Number })
	signatureId!: number | null;

	@GandalfProperty({ designType: String })
	upin!: string | null;

}
