// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.file.DrugAllergyImportResponse */
/* istanbul ignore next */
@GandalfModel
export class DrugAllergyImportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: String })
	drugCode!: string | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	lastModificationDate!: Date | null;

	@GandalfArray(ReferenceDataResponse)
	reactions!: ReferenceDataResponse[];

	@GandalfProperty({ designType: Number })
	snomedCodeCode!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty({ designType: constants.AllergyStatus })
	status!: constants.AllergyStatus;

}
