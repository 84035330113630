// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateLabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateLabOrderResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty({ designType: Number })
	carePlanItemTemplateId!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfConstantDecorator(constants.CarePlanItemLabOrderDuration)
	@GandalfProperty({ designType: constants.CarePlanItemLabOrderDuration })
	endDateRangeUnit!: constants.CarePlanItemLabOrderDuration | null;

	@GandalfProperty({ designType: Number })
	endDateRangeValue!: number | null;

	@GandalfProperty({ designType: Boolean })
	isCheckoutTask!: boolean | null;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfProperty({ designType: constants.LabOrderLabType })
	labType!: constants.LabOrderLabType | null;

	@GandalfProperty({ designType: LoincCodeResponse })
	loinc!: LoincCodeResponse | null;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfProperty({ designType: constants.LabOrderResultType })
	resultType!: constants.LabOrderResultType | null;

	@GandalfConstantDecorator(constants.CarePlanItemLabOrderDuration)
	@GandalfProperty({ designType: constants.CarePlanItemLabOrderDuration })
	startDateRangeUnit!: constants.CarePlanItemLabOrderDuration | null;

	@GandalfProperty({ designType: Number })
	startDateRangeValue!: number | null;

}
