// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminWorkflowScreenTemplateTestResponse } from './admin-workflow-screen-template-test-response';

/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.AdminWorkflowScreenTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowScreenTemplateResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	canScreenExcludePullForward!: boolean | null;

	@GandalfProperty({ designType: Number })
	categoryId!: number | null;

	@GandalfProperty({ designType: Number })
	displayOrder!: number | null;

	@GandalfProperty({ designType: Boolean })
	excludePullForward!: boolean | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	isStatic!: boolean | null;

	@GandalfProperty({ designType: String })
	label!: string | null;

	@GandalfConstantDecorator(constants.WorkflowScreenTemplateLayout)
	@GandalfProperty({ designType: constants.WorkflowScreenTemplateLayout })
	layout!: constants.WorkflowScreenTemplateLayout | null;

	@GandalfProperty({ designType: Number })
	masterId!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfArray(AdminWorkflowScreenTemplateTestResponse)
	workflowScreenTemplateTests!: AdminWorkflowScreenTemplateTestResponse[] | null;

}
