// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminLocationOrderProcessingVisionwebResponse } from './admin-location-order-processing-visionweb-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminLocationOrderProcessingResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminLocationOrderProcessingResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	arrellioEnabled!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	eyefinityVspEnabled!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	smartflowEnabled!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	visionwebEnabled!: boolean | null;

	@GandalfProperty({ designType: AdminLocationOrderProcessingVisionwebResponse })
	visionwebInfo!: AdminLocationOrderProcessingVisionwebResponse | null;

	@GandalfProperty({ designType: Boolean })
	vspEstimatorEnabled!: boolean | null;

}
