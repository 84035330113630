// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { InvoiceItemResponse } from './invoice-item-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	adjustmentTotalAmount!: number;

	@GandalfProperty({ designType: Number })
	age!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amountPaid!: number;

	@GandalfProperty({ designType: Number })
	appointmentId!: number | null;

	@GandalfProperty({ designType: Boolean })
	approved!: boolean;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	balance!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	discountTotalAmount!: number;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	feeScheduleDate!: Date | null;

	@GandalfProperty({ designType: Number })
	feeScheduleId!: number | null;

	@GandalfProperty({ designType: String })
	feeScheduleName!: string | null;

	@GandalfProperty({ designType: Number })
	financeChargePlanId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	firstStatementPrintDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ designType: String })
	insurancePriorityValue!: string | null;

	@GandalfProperty({ designType: String })
	insuranceTypeValue!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	invoiceDate!: Date;

	@GandalfArray(InvoiceItemResponse)
	items!: InvoiceItemResponse[];

	@GandalfProperty({ designType: Number })
	locationId!: number;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: Number })
	orderId!: number | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: AddressResponse })
	payerAddress!: AddressResponse | null;

	@GandalfProperty({ designType: Boolean })
	payerAllowItemDiscounts!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	payerAllowTransferTax!: boolean | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	payerDefaultPaymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty({ designType: String })
	payerEmail!: string | null;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfProperty({ designType: Number })
	payerId!: number;

	@GandalfProperty({ designType: String })
	payerName!: string | null;

	@GandalfProperty({ designType: String })
	payerPhone!: string | null;

	@GandalfProperty({ designType: Number })
	payerPolicyFeeScheduleId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType;

	@GandalfProperty({ designType: Number })
	personInsuranceId!: number | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfProperty({ designType: String })
	providerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	serviceDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	statementPrintDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty({ designType: constants.InvoiceStatus })
	status!: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	subTotalAmount!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	taxTotalAmount!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	totalAmount!: number;

	@GandalfProperty({ designType: Number })
	transferTaxInvoiceId!: number | null;

}
