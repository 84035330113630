// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.history.contactlens.ContactLensHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensHistoryResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ designType: Number })
	continuousWearPeriodAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	continuousWearPeriodUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	dailyWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	dailyWearingTimeUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: String })
	dropsUsed!: string | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	lensAgeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	lensAgeUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	od!: ContactLensEyeResponse | null;

	@GandalfProperty({ designType: ContactLensEyeResponse })
	os!: ContactLensEyeResponse | null;

	@GandalfProperty({ designType: String })
	otherSupplies!: string | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryType)
	@GandalfProperty({ designType: constants.ContactLensHistoryType })
	overallLensType!: constants.ContactLensHistoryType | null;

	@GandalfProperty({ designType: Number })
	replacementScheduleAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	replacementScheduleUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: String })
	solutionUsed!: string | null;

	@GandalfProperty({ designType: Number })
	supplyRemainingAmount!: number | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryPackaging)
	@GandalfProperty({ designType: constants.ContactLensHistoryPackaging })
	supplyRemainingPacking!: constants.ContactLensHistoryPackaging | null;

	@GandalfProperty({ designType: Number })
	todaysWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	todaysWearingTimeUnit!: constants.DurationUnit | null;

}
