// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeTransactionDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeTransactionDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	amount!: number | null;

	@GandalfProperty({ designType: String })
	cardLast4!: string | null;

	@GandalfConstantDecorator(constants.PaymentMethodCreditCardType)
	@GandalfProperty({ designType: constants.PaymentMethodCreditCardType })
	cardType!: constants.PaymentMethodCreditCardType | null;

	@GandalfProperty({ designType: String })
	customerReceipt!: string | null;

	@GandalfProperty({ designType: String })
	errorMessage!: string | null;

	@GandalfConstantDecorator(constants.OpenEdgeCommonPaymentStatus)
	@GandalfProperty({ designType: constants.OpenEdgeCommonPaymentStatus })
	paymentStatus!: constants.OpenEdgeCommonPaymentStatus | null;

	@GandalfProperty({ designType: Number })
	paymentStoredTokenId!: number | null;

	@GandalfProperty({ designType: Number })
	paymentTransactionId!: number | null;

	@GandalfConstantDecorator(constants.OpenEdgeCommonTransactionStatus)
	@GandalfProperty({ designType: constants.OpenEdgeCommonTransactionStatus })
	status!: constants.OpenEdgeCommonTransactionStatus | null;

	@GandalfProperty({ designType: String })
	transactionId!: string | null;

}
