// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.schedule.waitinglist.WaitingListResponse */
/* istanbul ignore next */
@GandalfModel
export class WaitingListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	createdOnDate!: Date | null;

	@GandalfConstantDecorator(constants.PatientCommunicationEligibility)
	@GandalfProperty({ designType: constants.PatientCommunicationEligibility })
	emailEligibility!: constants.PatientCommunicationEligibility | null;

	@GandalfProperty({ designType: String })
	emailErrorMessage!: string | null;

	@GandalfProperty({ designType: Boolean })
	emailIsValid!: boolean | null;

	@GandalfProperty({ designType: String })
	encounterType!: string | null;

	@GandalfProperty({ designType: Number })
	encounterTypeId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	hasCellPhone!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	hasRevConnectAppointmentReminder!: boolean | null;

	@GandalfProperty({ designType: Date })
	nextAppointmentDate!: Date | null;

	@GandalfProperty({ designType: Number })
	nextAppointmentId!: number | null;

	@GandalfProperty({ designType: String })
	nextAppointmentType!: string | null;

	@GandalfProperty({ designType: Number })
	numberOfDaysOnWaitList!: number | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: Number })
	patientPrimaryLocationId!: number | null;

	@GandalfProperty({ designType: String })
	preferredPhoneNumber!: string | null;

	@GandalfConstantDecorator(constants.WaitListPriority)
	@GandalfProperty({ designType: constants.WaitListPriority })
	priority!: constants.WaitListPriority | null;

	@GandalfProperty({ designType: PersonNameResponse })
	provider!: PersonNameResponse | null;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfConstantDecorator(constants.PatientCommunicationEligibility)
	@GandalfProperty({ designType: constants.PatientCommunicationEligibility })
	textEligibility!: constants.PatientCommunicationEligibility | null;

	@GandalfProperty({ designType: String })
	textErrorMessage!: string | null;

	@GandalfProperty({ designType: Number })
	waitListId!: number | null;

	@GandalfProperty({ designType: Number })
	waitListLocationId!: number | null;

}
