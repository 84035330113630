// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.RecurPatternMonthlyResponse */
/* istanbul ignore next */
@GandalfModel
export class RecurPatternMonthlyResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	monthlyAmount!: number | null;

	@GandalfProperty({ designType: Number })
	monthlyAmount2!: number | null;

	@GandalfProperty({ designType: Number })
	monthlyDay!: number | null;

	@GandalfConstantDecorator(constants.DaySelect)
	@GandalfProperty({ designType: constants.DaySelect })
	monthlyDaySelect!: constants.DaySelect | null;

	@GandalfConstantDecorator(constants.MonthlyPatternType)
	@GandalfProperty({ designType: constants.MonthlyPatternType })
	monthlyPatternType!: constants.MonthlyPatternType | null;

	@GandalfConstantDecorator(constants.WeekSelect)
	@GandalfProperty({ designType: constants.WeekSelect })
	monthlyWeekSelect!: constants.WeekSelect | null;

}
