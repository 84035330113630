// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeFileInfoResponse } from './practice-file-info-response';

/** See com.rev360.pms.api.controller.document.ConsentFormResponse */
/* istanbul ignore next */
@GandalfModel
export class ConsentFormResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	allowElectronicClick!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	allowPaper!: boolean | null;

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormExpirationType)
	@GandalfProperty({ designType: constants.PracticeConsentFormExpirationType })
	expirationType!: constants.PracticeConsentFormExpirationType | null;

	@GandalfProperty({ designType: Number })
	expireDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	expireDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormRequired)
	@GandalfProperty({ designType: constants.PracticeConsentFormRequired })
	required!: constants.PracticeConsentFormRequired | null;

	@GandalfProperty({ designType: PracticeFileInfoResponse })
	template!: PracticeFileInfoResponse | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormType)
	@GandalfProperty({ designType: constants.PracticeConsentFormType })
	type!: constants.PracticeConsentFormType | null;

}
