// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PaymentResponse } from './payment-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentGroupResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentGroupResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	applyFull!: boolean | null;

	@GandalfProperty({ designType: String })
	comment!: string | null;

	@GandalfConstantDecorator(constants.CreditCardType)
	@GandalfProperty({ designType: constants.CreditCardType })
	creditCardType!: constants.CreditCardType | null;

	@GandalfProperty({ designType: Number })
	payerEntityId!: number | null;

	@GandalfProperty({ designType: Number })
	payerId!: number;

	@GandalfProperty({ designType: String })
	payerName!: string;

	@GandalfProperty({ designType: Number })
	payerPersonInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty({ designType: constants.PayerType })
	payerType!: constants.PayerType;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	paymentAmount!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	paymentDate!: Date;

	@GandalfProperty({ designType: Number })
	paymentGroupId!: number;

	@GandalfProperty({ designType: Number })
	paymentLocationId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty({ designType: constants.PaymentMethodType })
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfArray(PaymentResponse)
	payments!: PaymentResponse[];

	@GandalfProperty({ designType: String })
	referenceNumber!: string | null;

	@GandalfProperty({ designType: Number })
	sourcePracticeLocationId!: number | null;

	@GandalfConstantDecorator(constants.PaymentGroupSourceType)
	@GandalfProperty({ designType: constants.PaymentGroupSourceType })
	sourceType!: constants.PaymentGroupSourceType;

	@GandalfConstantDecorator(constants.PaymentGroupStatus)
	@GandalfProperty({ designType: constants.PaymentGroupStatus })
	status!: constants.PaymentGroupStatus;

	@GandalfProperty({ designType: Number })
	version!: number;

}
