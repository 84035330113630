// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateMedicationPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateMedicationPrescriptionResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty({ designType: Number })
	carePlanItemTemplateId!: number | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ designType: Number })
	dispenseAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dispenseUnitId!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	doNotSubstitute!: boolean | null;

	@GandalfProperty({ designType: Number })
	dosageAmount!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	dosageUnitId!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Number })
	drugId!: number | null;

	@GandalfProperty({ designType: String })
	drugName!: string | null;

	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	frequencyId!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: String })
	instructions!: string | null;

	@GandalfProperty({ designType: Boolean })
	isCheckoutTask!: boolean | null;

	@GandalfProperty({ designType: Number })
	numRefillsAllowed!: number | null;

	@GandalfProperty({ designType: MasterReferenceDataResponse })
	routeId!: MasterReferenceDataResponse | null;

	@GandalfProperty({ designType: Boolean })
	staffCanRefill!: boolean | null;

}
