// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.RefillContactLensPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class RefillContactLensPrescriptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Authorize Prescription indicator is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	authorizePrescription!: boolean;

	@GandalfLabel('Contact Lens Prescription')
	@GandalfValidator({ notNull: { message: 'Contact Lens Prescription is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	contactLensPrescriptionId!: number;

	@GandalfLabel('Delivery Method')
	@GandalfProperty({ designType: Number })
	deliveryMethodReferenceId!: number | null;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfLabel('Prescribed Lens Drop')
	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Drop cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	dropsName!: string | null;

	@GandalfLabel('Encounter')
	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfLabel('Prescribed Enzymatic Cleaner')
	@GandalfValidator({ sizeString: { message: 'Prescribed Enzymatic Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	enzymaticCleanerName!: string | null;

	@GandalfLabel('Expiration Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	expirationDate!: Date | null;

	@GandalfLabel('Prescribed Lens Cleaner')
	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	lensCleanerName!: string | null;

	@GandalfConstantDecorator(constants.ContactLensModality)
	@GandalfLabel('Modality')
	@GandalfValidator({ notNull: { message: 'Modality is required' } })
	@GandalfProperty({ designType: constants.ContactLensModality, isRequired: true })
	modality!: constants.ContactLensModality;

	@GandalfSubRequestLabel('OD')
	@GandalfLabel('OD')
	@GandalfProperty({ designType: ContactLensEyeRequest })
	od!: ContactLensEyeRequest | null;

	@GandalfLabel('OD Dispense Amount')
	@GandalfProperty({ designType: Number })
	odDispenseAmount!: number | null;

	@GandalfLabel('OD Dispense Unit')
	@GandalfProperty({ designType: Number })
	odDispenseUnitId!: number | null;

	@GandalfSubRequestLabel('OS')
	@GandalfLabel('OS')
	@GandalfProperty({ designType: ContactLensEyeRequest })
	os!: ContactLensEyeRequest | null;

	@GandalfLabel('OS Dispense Amount')
	@GandalfProperty({ designType: Number })
	osDispenseAmount!: number | null;

	@GandalfLabel('OS Dispense Unit')
	@GandalfProperty({ designType: Number })
	osDispenseUnitId!: number | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfLabel('Lens Type')
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ designType: constants.ContactLensPrescriptionType, isRequired: true })
	overallLensType!: constants.ContactLensPrescriptionType;

	@GandalfLabel('Prescribed Disinfection Solution')
	@GandalfValidator({ sizeString: { message: 'Prescribed Disinfection Solution cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	solutionName!: string | null;

	@GandalfLabel('Start Date')
	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startDate!: Date | null;

	@GandalfLabel('Use Signature')
	@GandalfValidator({ notNull: { message: 'Use Signature is required' } })
	@GandalfProperty({ designType: Boolean, isRequired: true })
	useSignature!: boolean;

}
