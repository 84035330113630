// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Boolean })
	appointmentConfirmationStatus!: boolean | null;

	@GandalfProperty({ designType: Number })
	appointmentId!: number | null;

	@GandalfProperty({ designType: String })
	employeeFirstName!: string | null;

	@GandalfProperty({ designType: String })
	employeeLastName!: string | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty({ designType: constants.EncounterApprovalStatus })
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty({ designType: constants.EstablishedStatus })
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty({ designType: String })
	insuranceVerificationComment!: string | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty({ designType: constants.InterviewStatus })
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty({ designType: String })
	locationName!: string | null;

	@GandalfProperty({ designType: PatientNameResponse })
	patientName!: PatientNameResponse | null;

	@GandalfProperty({ designType: String })
	preferredPhoneNumber!: string | null;

	@GandalfProperty({ designType: String })
	providerFirstName!: string | null;

	@GandalfProperty({ designType: String })
	providerLastName!: string | null;

	@GandalfProperty({ designType: String })
	roleName!: string | null;

	@GandalfProperty({ designType: Date })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty({ designType: constants.ScheduleItemStatus })
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ designType: String })
	subTypeName!: string | null;

	@GandalfProperty({ designType: String })
	templateName!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty({ designType: constants.VerificationStatus })
	verificationStatus!: constants.VerificationStatus | null;

}
