// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { GlaucomaCareMedicationPrescriptionResponse } from './glaucoma-care-medication-prescription-response';

// @ts-ignore
import { GlaucomaCareServiceHistoryResponse } from './glaucoma-care-service-history-response';

// @ts-ignore
import { GlaucomaDataResponse } from './glaucoma-data-response';

// @ts-ignore
import { TargetIopResponse } from './target-iop-response';

/** See com.rev360.pms.api.controller.patient.ongoingcare.GlaucomaCareResponse */
/* istanbul ignore next */
@GandalfModel
export class GlaucomaCareResponse extends GandalfModelBase {

	@GandalfArray(GlaucomaDataResponse)
	iopHistory!: GlaucomaDataResponse[];

	@GandalfArray(GlaucomaCareMedicationPrescriptionResponse)
	medications!: GlaucomaCareMedicationPrescriptionResponse[];

	@GandalfProperty({ designType: Number })
	odIopAdjusted!: number | null;

	@GandalfProperty({ designType: Number })
	odIopInitial!: number | null;

	@GandalfProperty({ designType: Number })
	odIopMaximum!: number | null;

	@GandalfProperty({ designType: Number })
	odIopTarget!: number | null;

	@GandalfProperty({ designType: String })
	odOpticalPachymetry!: string;

	@GandalfProperty({ designType: String })
	odPachymetry!: string;

	@GandalfProperty({ designType: Number })
	osIopAdjusted!: number | null;

	@GandalfProperty({ designType: Number })
	osIopInitial!: number | null;

	@GandalfProperty({ designType: Number })
	osIopMaximum!: number | null;

	@GandalfProperty({ designType: Number })
	osIopTarget!: number | null;

	@GandalfProperty({ designType: String })
	osOpticalPachymetry!: string;

	@GandalfProperty({ designType: String })
	osPachymetry!: string;

	@GandalfArray(GlaucomaCareServiceHistoryResponse)
	serviceHistory!: GlaucomaCareServiceHistoryResponse[];

	@GandalfArray(TargetIopResponse)
	targetIops!: TargetIopResponse[];

}
