// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignSendTestEmailRequest */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignSendTestEmailRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Email is required' } })
	@GandalfValidator({ sizeString: { message: 'Email must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty({ designType: String, isRequired: true })
	email!: string;

	@GandalfValidator({ notNull: { message: 'Message is required' } })
	@GandalfValidator({ sizeString: { message: 'Message must be between 1 and 65535 characters', minLength: 1, maxLength: 65535 } })
	@GandalfProperty({ designType: String, isRequired: true })
	message!: string;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceLocationId!: number;

	@GandalfValidator({ notNull: { message: 'Subject is required' } })
	@GandalfValidator({ sizeString: { message: 'Subject must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ designType: String, isRequired: true })
	subject!: string;

}
