// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.provider.externalprovider.CreateProviderInsuranceIdentifierRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateProviderInsuranceIdentifierRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Insurance Company is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	practiceInsuranceCompanyId!: number;

	@GandalfProperty({ designType: Number })
	providerId!: number | null;

	@GandalfConstantDecorator(constants.GroupNumberQualifier)
	@GandalfProperty({ designType: constants.GroupNumberQualifier })
	qualifier!: constants.GroupNumberQualifier | null;

	@GandalfValidator({ sizeString: { message: 'ID cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	value!: string | null;

}
