// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodTaskResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodTaskResponse extends GandalfModelBase {

	@GandalfProperty({ designType: PersonNameResponse })
	assignee!: PersonNameResponse | null;

	@GandalfProperty({ designType: String })
	categoryName!: string | null;

	@GandalfProperty({ designType: String })
	createdBy!: string | null;

	@GandalfProperty({ designType: Date })
	createdOn!: Date | null;

	@GandalfProperty({ designType: String })
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	dueDate!: Date | null;

	@GandalfConstantDecorator(constants.TaskPriority)
	@GandalfProperty({ designType: constants.TaskPriority })
	priority!: constants.TaskPriority | null;

	@GandalfConstantDecorator(constants.TaskStatus)
	@GandalfProperty({ designType: constants.TaskStatus })
	status!: constants.TaskStatus | null;

	@GandalfProperty({ designType: Number })
	taskId!: number | null;

}
