// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.selfpolicy.UpdatePatientInsuranceBasicInformationSelfPolicyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientInsuranceBasicInformationSelfPolicyRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	coInsurancePercentage!: number | null;

	@GandalfValidator({ notNull: { message: 'Company is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	companyId!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	contactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	contactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	familyDeductible!: number | null;

	@GandalfProperty({ designType: Number })
	feeScheduleId!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	generalCoPay!: number | null;

	@GandalfValidator({ sizeString: { message: 'Group Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	groupNumber!: string | null;

	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	insuranceTypeId!: number;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	maxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	medicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	minFrameAllowance!: number | null;

	@GandalfProperty({ designType: Number })
	personInsurancePolicyId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Plan Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	planName!: string | null;

	@GandalfConstantDecorator(constants.PolicyHolderRelationship)
	@GandalfValidator({ notNull: { message: 'Policyholder Relationship is required' } })
	@GandalfProperty({ designType: constants.PolicyHolderRelationship, isRequired: true })
	policyHolderRelationship!: constants.PolicyHolderRelationship;

	@GandalfValidator({ notNull: { message: 'Policy Number is required' } })
	@GandalfValidator({ sizeString: { message: 'Policy Number must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ designType: String, isRequired: true })
	policyNumber!: string;

	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	priorityId!: number;

	@GandalfProperty({ designType: Boolean })
	referralRequired!: boolean | null;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	routineExamCoPay!: number | null;

	@GandalfValidator({ sizeString: { message: 'RX BIN Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	rxBinNumber!: string | null;

	@GandalfValidator({ sizeString: { message: 'RX PCN Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty({ designType: String })
	rxPcnNumber!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	termedDate!: Date | null;

}
