// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FamilyHealthHistoryResponse } from './family-health-history-response';

// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryReviewSetResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyHealthHistoryReviewSetResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	comments!: string | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryConditionType)
	@GandalfProperty({ designType: constants.FamilyHealthHistoryConditionType })
	conditionType!: constants.FamilyHealthHistoryConditionType | null;

	@GandalfProperty({ designType: Number })
	encounterId!: number | null;

	@GandalfArray(FamilyHealthHistoryResponse)
	familyHealthHistoryItems!: FamilyHealthHistoryResponse[] | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Number })
	patientId!: number | null;

	@GandalfProperty({ designType: Boolean })
	reviewed!: boolean | null;

	@GandalfProperty({ designType: UserSummaryResponse })
	reviewedBy!: UserSummaryResponse | null;

	@GandalfProperty({ designType: Date })
	reviewedOn!: Date | null;

	@GandalfProperty({ designType: Date })
	updatedOn!: Date | null;

	@GandalfProperty({ designType: Number })
	version!: number | null;

}
