// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.EncounterTemplateListResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterTemplateListResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	abbreviation!: string | null;

	@GandalfProperty({ designType: Number })
	displayColor!: number | null;

	@GandalfProperty({ designType: Number })
	id!: number | null;

	@GandalfProperty({ designType: Boolean })
	inClinic!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	includeInMuCalcs!: boolean | null;

	@GandalfProperty({ designType: String })
	interviewTemplateName!: string | null;

	@GandalfProperty({ designType: Boolean })
	isComprehensive!: boolean | null;

	@GandalfProperty({ designType: String })
	name!: string | null;

	@GandalfConstantDecorator(constants.TemplateStatus)
	@GandalfProperty({ designType: constants.TemplateStatus })
	status!: constants.TemplateStatus | null;

	@GandalfProperty({ designType: Number })
	totalDuration!: number | null;

}
