import {AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import { FROALA_EDITOR_OPTIONS } from '@shared/constants/froala-editor.constants';
import {FroalaEditorDirective} from 'angular-froala-wysiwyg';

@Component({
	selector: 'pms-text-editor',
	templateUrl: './text-editor.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements AfterViewInit {

	@Input()
	content: string;

	@ViewChild(FroalaEditorDirective)
	froalaEditor: FroalaEditorDirective;

	options = FROALA_EDITOR_OPTIONS;

	constructor() {
	}

	ngAfterViewInit() {
		// "fix" any invalid HTML by forcing the content to be updated with the real HTML from the editor
		return setTimeout(() => this.froalaEditor['updateModel']());
	}
}
