// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.AdditionalClaimInformationResponse */
/* istanbul ignore next */
@GandalfModel
export class AdditionalClaimInformationResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	alternateServiceLocationId!: number | null;

	@GandalfProperty({ designType: String })
	attachmentControlNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentTransmission)
	@GandalfProperty({ designType: constants.ClaimAttachmentTransmission })
	attachmentTransmission!: constants.ClaimAttachmentTransmission | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentType)
	@GandalfProperty({ designType: constants.ClaimAttachmentType })
	attachmentType!: constants.ClaimAttachmentType | null;

	@GandalfProperty({ designType: String })
	authorizationNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimFrequencyCode)
	@GandalfProperty({ designType: constants.ClaimFrequencyCode })
	claimFrequencyCode!: constants.ClaimFrequencyCode | null;

	@GandalfProperty({ designType: String })
	cliaNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty({ designType: constants.ClaimVisionConditionApply })
	conditionContactsApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty({ designType: Boolean })
	conditionContactsL1!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionContactsL2!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionContactsL3!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionContactsL4!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionContactsL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty({ designType: constants.ClaimVisionConditionApply })
	conditionFramesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty({ designType: Boolean })
	conditionFramesL1!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionFramesL2!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionFramesL3!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionFramesL4!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionFramesL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty({ designType: constants.ClaimVisionConditionApply })
	conditionLensesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty({ designType: Boolean })
	conditionLensesL1!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionLensesL2!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionLensesL3!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionLensesL4!: boolean | null;

	@GandalfProperty({ designType: Boolean })
	conditionLensesL5!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	currentIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimCurrentIllnessDateQualifier)
	@GandalfProperty({ designType: constants.ClaimCurrentIllnessDateQualifier })
	currentIllnessDateQualifier!: constants.ClaimCurrentIllnessDateQualifier | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty({ designType: constants.CodeSet })
	diagnosisCodeSet!: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	endCareDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtApply)
	@GandalfProperty({ designType: constants.ClaimEpsdtApply })
	epsdtApply!: constants.ClaimEpsdtApply | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty({ designType: constants.ClaimEpsdtCode })
	epsdtCode1!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty({ designType: constants.ClaimEpsdtCode })
	epsdtCode2!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty({ designType: constants.ClaimEpsdtCode })
	epsdtCode3!: constants.ClaimEpsdtCode | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	hospitalizationEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	hospitalizationStartDate!: Date | null;

	@GandalfProperty({ designType: Number })
	id!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	initialTreatmentDate!: Date | null;

	@GandalfProperty({ designType: Number })
	invoiceId!: number;

	@GandalfProperty({ designType: String })
	localUse!: string | null;

	@GandalfProperty({ designType: String })
	localUse10d!: string | null;

	@GandalfProperty({ designType: String })
	medicaidResubmitCode!: string | null;

	@GandalfProperty({ designType: Number })
	orderingProviderId!: number | null;

	@GandalfProperty({ designType: String })
	originalReferenceNumber!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	outOfWorkEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	outOfWorkStartDate!: Date | null;

	@GandalfProperty({ designType: Boolean })
	outsideLab!: boolean;

	@GandalfProperty({ propertyType: 'Money', designType: Number })
	outsideLabCharges!: number;

	@GandalfProperty({ designType: String })
	priorAuthorizationNumber!: string | null;

	@GandalfProperty({ designType: String })
	referralNumber!: string | null;

	@GandalfProperty({ designType: Number })
	referringProviderId!: number | null;

	@GandalfProperty({ designType: Number })
	secondaryPayerId!: number | null;

	@GandalfProperty({ designType: Boolean })
	signatureHandwritten!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	similarIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimSimilarIllnessDateQualifier)
	@GandalfProperty({ designType: constants.ClaimSimilarIllnessDateQualifier })
	similarIllnessDateQualifier!: constants.ClaimSimilarIllnessDateQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	startCareDate!: Date | null;

	@GandalfProperty({ designType: Number })
	version!: number;

	@GandalfProperty({ propertyType: 'LocalDate', designType: Date })
	visionPrescriptionDate!: Date | null;

}
