// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.MinMaxInventoryReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MinMaxInventoryReportResponse extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	averageAge!: number | null;

	@GandalfProperty({ designType: Number })
	averageAgeOfSales!: number | null;

	@GandalfProperty({ designType: Number })
	averageAgeOfStock!: number | null;

	@GandalfProperty({ designType: Number })
	costOfSales!: number | null;

	@GandalfProperty({ designType: Number })
	costOfStock!: number | null;

	@GandalfProperty({ designType: String })
	itemContactAddDesignation!: string | null;

	@GandalfProperty({ designType: Number })
	itemContactAddPower!: number | null;

	@GandalfProperty({ designType: Number })
	itemContactAxis!: number | null;

	@GandalfProperty({ designType: Number })
	itemContactBaseCurve!: number | null;

	@GandalfProperty({ designType: Number })
	itemContactCylinder!: number | null;

	@GandalfProperty({ designType: Number })
	itemContactDiameter!: number | null;

	@GandalfProperty({ designType: Boolean })
	itemContactIsTrial!: boolean | null;

	@GandalfProperty({ designType: String })
	itemContactLensColor!: string | null;

	@GandalfProperty({ designType: String })
	itemContactLensColorCode!: string | null;

	@GandalfProperty({ designType: Number })
	itemContactSphere!: number | null;

	@GandalfProperty({ designType: String })
	itemDescription!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameBridge!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameColor!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameColorCode!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameEye!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameTemple!: string | null;

	@GandalfProperty({ designType: String })
	itemFrameTempleUnit!: string | null;

	@GandalfProperty({ designType: Number })
	itemId!: number | null;

	@GandalfProperty({ designType: Boolean })
	itemIsStocked!: boolean | null;

	@GandalfProperty({ designType: Number })
	itemMinStockQty!: number | null;

	@GandalfProperty({ designType: Number })
	itemPrice!: number | null;

	@GandalfProperty({ designType: Number })
	itemQuantityInStock!: number | null;

	@GandalfProperty({ designType: String })
	itemSku!: string | null;

	@GandalfProperty({ designType: String })
	itemUpc!: string | null;

	@GandalfProperty({ designType: String })
	locationProductBrandName!: string | null;

	@GandalfProperty({ designType: String })
	locationProductCategoryName!: string | null;

	@GandalfProperty({ designType: String })
	locationProductCode!: string | null;

	@GandalfProperty({ designType: String })
	locationProductCollectionName!: string | null;

	@GandalfProperty({ designType: Number })
	locationProductCost!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductId!: number | null;

	@GandalfProperty({ designType: String })
	locationProductManufacturerName!: string | null;

	@GandalfProperty({ designType: String })
	locationProductModel!: string | null;

	@GandalfProperty({ designType: Number })
	locationProductPrice!: number | null;

	@GandalfProperty({ designType: Number })
	locationProductWholesale!: number | null;

	@GandalfProperty({ designType: Number })
	maximumAge!: number | null;

	@GandalfProperty({ designType: Number })
	maximumAgeOfStock!: number | null;

	@GandalfProperty({ designType: Number })
	minimumAge!: number | null;

	@GandalfProperty({ designType: Number })
	minimumAgeOfStock!: number | null;

	@GandalfProperty({ designType: Number })
	practiceLocationId!: number | null;

	@GandalfProperty({ designType: String })
	practiceLocationName!: string | null;

	@GandalfProperty({ designType: Number })
	retailOfStock!: number | null;

	@GandalfProperty({ designType: Number })
	retailPrice!: number | null;

	@GandalfProperty({ designType: Number })
	salesAdjustments!: number | null;

	@GandalfProperty({ designType: Number })
	salesDiscountAmount!: number | null;

	@GandalfProperty({ designType: Number })
	salesGrossSales!: number | null;

	@GandalfProperty({ designType: Number })
	salesNetSales!: number | null;

	@GandalfProperty({ designType: Number })
	salesQuantity!: number | null;

	@GandalfProperty({ designType: Number })
	salesTaxAmount!: number | null;

	@GandalfProperty({ designType: Number })
	wholesaleOfSales!: number | null;

	@GandalfProperty({ designType: Number })
	wholesaleOfStock!: number | null;

}
