// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.item.PracticeLocationDefaultLabelPrinterResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeLocationDefaultLabelPrinterResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	printerName!: string | null;

	@GandalfProperty({ designType: String })
	templateDescription!: string | null;

	@GandalfProperty({ designType: String })
	templateKey!: string | null;

	@GandalfProperty({ designType: String })
	templateLayout!: string | null;

}
