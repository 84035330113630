// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.presentillness.UpdatePresentIllnessRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePresentIllnessRequest extends GandalfModelBase {

	@GandalfProperty({ designType: Number })
	contextSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Context Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	contextText!: string | null;

	@GandalfValidator({ sizeString: { message: 'Condition cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	diagnosisText!: string | null;

	@GandalfProperty({ designType: Number })
	durationAmount!: number | null;

	@GandalfValidator({ sizeString: { message: 'Duration Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	durationText!: string | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty({ designType: constants.DurationUnit })
	durationUnit!: constants.DurationUnit | null;

	@GandalfArray(Number)
	factorsSelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Modifying Factors Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	factorsText!: string | null;

	@GandalfValidator({ notNull: { message: 'Present Illness is required' } })
	@GandalfProperty({ designType: Number, isRequired: true })
	id!: number;

	@GandalfProperty({ designType: Number })
	locationSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Location Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	locationText!: string | null;

	@GandalfArray(Number)
	qualitySelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Quality Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	qualityText!: string | null;

	@GandalfProperty({ designType: Number })
	severitySelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Severity Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	severityText!: string | null;

	@GandalfArray(Number)
	signsSelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Assoc S&S Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	signsText!: string | null;

	@GandalfProperty({ designType: Number })
	timingSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Timing Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty({ designType: String })
	timingText!: string | null;

}
