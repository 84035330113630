// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.ProductForPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class ProductForPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty({ designType: String })
	externalId!: string | null;

	@GandalfProperty({ designType: String })
	externalSource!: string | null;

	@GandalfProperty({ designType: Boolean })
	isActive!: boolean | null;

	@GandalfProperty({ designType: String })
	manufacturer!: string | null;

	@GandalfProperty({ designType: String })
	model!: string | null;

	@GandalfProperty({ designType: Number })
	productCategoryId!: number | null;

}
